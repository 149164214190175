import "./press.css";

import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Button, TextField } from "@mui/material";

import { homeSelectors, notifSelectors } from "../../logic/rootSelectors";
import _home from "../../logic/home";
import { LOADING_STATE, LS_ITEM, PAGE_ROUTES } from "../../utils/stateTypes";
import MainContent from "../general/MainContent";
import Breadcrumbs from "../sections/Breadcrumbs";
import HeadBanner from "../sections/HeadBanner";

const styles = {
  pwButton: {
    height: 50,
    marginTop: 10,
  },
  pwInput: {
    backgroundColor: "rgba(255, 255, 255, 0.2)",
  },
};


const PressHome = ({
  pressImages, pressLS,
  loadPress,
}) => {

  const [showContent, setShowContent] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const [password, setPassword] = React.useState("");

  const pressImagesByCategory = React.useMemo(() => {
    return pressImages.reduce((acc, item) => {
      if (!acc[item.category]) {
        acc[item.category] = [];
      }
      acc[item.category].push(item);
      return acc;
    }, {});
  },  [pressImages]);

  return (
    <MainContent loadItems={[{ loadState: pressLS, loadFn: loadPress }]}>
      <Helmet>
        <title>Charles Greig | Media Portal</title>
      </Helmet>

      {
        (pressLS === LOADING_STATE.LOADED) && (
          <React.Fragment>
            <HeadBanner
              imageLg={"/images/press-header.jpg"}
              headPreGreen={"Media"}
              headWhite={"Portal"}
              buttonLabel={"View Media"}
            />

            <Breadcrumbs
              crumbs={[
                { text: "Home", link: "/" },
                { text: "Media Portal", link: "" },
              ]}
            />

            <section className={"about-section standard-width"}>
              <p className={"about-outlined"}>
                Welcome to the Charles Greig Media Portal. Here you will find a collection
                of our latest releases and high resolution images.
                <br />
                For any additional media enquiries, please <Link to={PAGE_ROUTES.CONTACT}>get in touch.</Link>
              </p>
            </section>

            {
              (showContent) ? (
                <div className={"press-cont standard-width"}>
                  {
                    (pressImages.length === 0) && (
                      <p className={"press-no-items"}>No items to display</p>
                    )
                  }

                  {
                    Object.keys(pressImagesByCategory).map((category, index) => (
                      <React.Fragment key={index}>
                        <h2 className={"press-category"}>{category}</h2>
                        {
                          pressImagesByCategory[category].map((item, index) => (
                            <div
                              key={index}
                              className={"press-img standard-box"}
                            >
                              <img src={item.imageSm} />
                              <Button
                                variant={"text"}
                                color={"secondary"}
                                href={item.imageLg}
                                target={"_blank"}
                                fullWidth
                              >
                                Open Full Size
                              </Button>
                            </div>
                          ))
                        }
                      </React.Fragment>
                    ))
                  }
                </div>
              ) : (
                <div className={"content-password standard-width"}>
                  <TextField
                    label={"Media passcode"}
                    variant={"filled"}
                    color={"secondary"}
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      setShowError(false);
                    }}
                    style={styles.pwInput}
                    error={showError}
                    fullWidth
                  />
                  <Button
                    variant={"contained"}
                    color={"secondary"}
                    onClick={() => {
                      if (password === "CG2024") {
                        setShowContent(true);
                      }
                      else {
                        setShowError(true);
                      }
                    }}
                    style={styles.pwButton}
                    fullWidth
                  >
                    Unlock Media
                  </Button>
                </div>
              )
            }
          </React.Fragment>
        )
      }
    </MainContent>
  );
};

const mapStateToProps = (state) => {
  return {
    pressImages: homeSelectors.getPressImages(state),
    pressLS: notifSelectors.getLoadState(state)(LS_ITEM.PRESS_LOAD),
  };
};

const connected = connect(
  mapStateToProps,
  {
    loadPress: _home.actions.loadPress,
  },
)(PressHome);

export default connected;