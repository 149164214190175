import "./category.css";

import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { useParams, useNavigate } from "react-router-dom";

import { homeSelectors, notifSelectors } from "../../logic/rootSelectors";
import _home from "../../logic/home";
import { CATEGORY_TYPE, LOADING_STATE, LS_ITEM, PAGE_ROUTES } from "../../utils/stateTypes";
import MainContent from "../general/MainContent";
import Breadcrumbs from "../sections/Breadcrumbs";
import HeadBanner from "../sections/HeadBanner";
import InfoSection from "../sections/InfoSection";
import ProductBlock from "../product/ProductBlock";
import CustomCategory from "./custom/CustomCategory";


const Category = ({
  categoryDetails, getLoadState,
  getCategoryDetails,
}) => {
  const { categoryUrl } = useParams();
  const navigate = useNavigate();
  const categoryDetailsLS = getLoadState(`${LS_ITEM.CATEGORY_DETAILS_LOAD}${categoryUrl}`);

  const loadCatCB = React.useCallback(() => {
    const navigateTo404 = () => navigate(PAGE_ROUTES.NOT_FOUND);
    getCategoryDetails(categoryUrl, navigateTo404);
  }, [categoryUrl, getCategoryDetails]);

  React.useEffect(() => {
    //Skip load if already loaded and correct category
    if ((categoryDetailsLS === LOADING_STATE.LOADED) && (categoryDetails.url === categoryUrl)) {
      return;
    }
    loadCatCB();
  }, [categoryUrl]);

  return (
    <MainContent loadItems={[{ loadState: categoryDetailsLS, loadFn: () => {} }]}>
      <Helmet>
        <title>Charles Greig | {categoryDetails.name}</title>
      </Helmet>

      {
        ((categoryDetailsLS === LOADING_STATE.LOADED) && (categoryDetails.customPageName === null)) && (
          <React.Fragment>
            <HeadBanner
              imageLg={categoryDetails.headerImage}
              headPreGreen={categoryDetails.headerTextPre}
              headWhite={categoryDetails.headerText}
              headPostGreen={categoryDetails.headerTextPost}
            />

            <Breadcrumbs
              crumbs={[
                { text: "Home", link: "/" },
                { text: (categoryDetails.type === CATEGORY_TYPE.TIMEPIECES) ? "Timepieces" : "Jewellery", link: (categoryDetails.type === CATEGORY_TYPE.TIMEPIECES) ? PAGE_ROUTES.TIMEPIECES : PAGE_ROUTES.JEWELLERY },
                { text: categoryDetails.name, link: "" },
              ]}
            />

            <section className={"categories-section"}>
              {
                categoryDetails.products.map((product) => (
                  <ProductBlock
                    key={product.id}
                    product={product}
                  />
                ))
              }
            </section>

            {
              categoryDetails.infoSections.map((infoSection, index) => (
                <InfoSection
                  key={infoSection.id}
                  info={{
                    image: infoSection.image,
                    headPreGreen: infoSection.headPre,
                    headWhite: infoSection.head,
                    headPostGreen: infoSection.headPost,
                    subHeadHtml: infoSection.body,
                    linkUrl: infoSection.linkUrl,
                    linkLabel: infoSection.linkLabel,
                  }}
                  isVariant={(index % 2 === 0)}
                />
              ))
            }
          </React.Fragment>
        )
      }

      {
        ((categoryDetailsLS === LOADING_STATE.LOADED) && (categoryDetails.customPageName !== null)) && (
          <CustomCategory pageName={categoryDetails.customPageName} />
        )
      }
    </MainContent>
  );
};

const mapStateToProps = (state) => {
  return {
    categoryDetails: homeSelectors.getCategoryDetails(state),
    getLoadState: notifSelectors.getLoadState(state),
  };
};

const connected = connect(
  mapStateToProps,
  {
    getCategoryDetails: _home.actions.getCategoryDetails,
  },
)(Category);

export default connected;