import React from "react";
import { Link, useNavigate  } from "react-router-dom";
import { Button } from "@mui/material";

import { ADMIN_PAGE_ROUTES } from "../../utils/stateTypes";

const styles = {
  menuButton: {
    position: "absolute",
    top: 20,
    right: 15,
    height: 50,
    width: 120,
  },
};


const TopNav = ({ logout }) => {
  const navigate = useNavigate ();

  return (
    <nav className={"admin-nav"}>
      <Link to={ADMIN_PAGE_ROUTES.HOME}>
        <img
          src={"/images/logo-header.png"}
          className={"admin-nav-logo"}
        />
      </Link>

      {
        (location.pathname === "/") ? (
          <Button
            variant={"text"}
            color={"secondary"}
            style={styles.menuButton}
            onClick={logout}
          >
            LOGOUT
          </Button>
        ) : (
          <Button
            variant={"text"}
            color={"secondary"}
            style={styles.menuButton}
            onClick={() => navigate(-1)}
          >
            BACK
          </Button>
        )
      }
    </nav>
  );
};

export default TopNav;

