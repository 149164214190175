import "./rowItem.css";

import React from "react";
import { AccordionSummary, Button, CircularProgress } from "@mui/material";
import { Edit, Delete, ExpandMore, Reorder, FileCopy } from "@mui/icons-material";
import { Link } from "react-router-dom";

import { LOADING_STATE } from "../../utils/stateTypes";
import WithConfirmDialog from "./WithConfirmDialog";


// Material UI styles
const styles = {
  rowActionButton: {
    marginRight: 10,
    width: 90,
  },
  accordianSummary: {
    backgroundColor: "rgba(255, 255, 255, 0.5)",
  },
  accordianSubSummary: {
    borderBottom: "1px solid #eee",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    cursor: "default",
  },
  subRowActionButton: {
    marginRight: 10,
    color: "#592f4e",
    width: 90,
  },
  subRowLoading: {
    color: "#592f4e",
  },
  moveIcon: {
    paddingRight: 5,
    marginRight: 5,
  },
};


const RowItem = ({ item, editLink, deleteLS, deleteCB, moveLS, isSubRow=false, dragHandleProps=null, children }) => {

  //We need to stop accordian row actions from expanding the accordian
  const preventPropagation = React.useCallback((e) => {
    e.stopPropagation();
  }, []);

  return (
    <AccordionSummary
      expandIcon={(!isSubRow) ? <ExpandMore /> : null}
      style={(isSubRow) ? styles.accordianSubSummary : styles.accordianSummary}
    >
      <div className={"acc-row-cont"}>
        <div className={"acc-row-content"}>
          {children}
        </div>
        <div className={"acc-row-actions"}>
          <Link
            to={editLink} 
            onClick={preventPropagation}
          >
            <Button
              variant={"text"}
              color={"primary"}
              startIcon={<Edit />}
              style={(isSubRow) ? styles.subRowActionButton : styles.rowActionButton}
            >
              Edit
            </Button>
          </Link>
          <WithConfirmDialog
            component={
              <Button
                variant={"text"}
                color={"primary"}
                startIcon={
                  (deleteLS !== LOADING_STATE.LOADING) ? (
                    <Delete />
                  ): (
                    <CircularProgress
                      size={20}
                      style={(isSubRow) ? styles.subRowLoading : null}
                    />
                  )}
                style={(isSubRow) ? styles.subRowActionButton : styles.rowActionButton}
                disabled={(deleteLS === LOADING_STATE.LOADING)}
              >
                Remove
              </Button>
            }
            onClick={deleteCB}
            label={"Are you sure you want to delete this item?"}
            outerDivClass={"acc-row-action-cont"}
          />
          {
            (dragHandleProps !== null) && (
              <div
                className={["acc-row-reorder-handle", (isSubRow) ? "sub-row" : ""].join(" ")}
                onClick={preventPropagation}
                {...dragHandleProps}
              >
                {
                  (moveLS === LOADING_STATE.LOADING) ? (
                    <CircularProgress
                      size={20}
                      style={(isSubRow) ? styles.subRowLoading : null}
                    />
                  ) : (
                    <React.Fragment>
                      <Reorder style={styles.moveIcon} />
                      <span>MOVE</span>
                    </React.Fragment>
                  )
                }
              </div>
            )
          }
        </div>
      </div>
    </AccordionSummary>
  );
};

export default RowItem;

