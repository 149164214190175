import "./404.css";

import React from "react";
import { Button } from "@mui/material";
import { WhatsApp } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";


const styles = {
  contactButton: {
    width: "100%",
    maxWidth: "300px",
    height: "50px",
    marginBottom: "20px",
    textTransform: "none",
  },
  homeButton: {
    width: "100%",
    maxWidth: "300px",
    height: "50px",
    textTransform: "none",
  },
};

const Page404 = () => {
  return (
    <div>
      <Helmet>
        <title>Charles Greig | Page not found</title>
        <meta
          name={"robots"}
          content={"noindex"}
        ></meta>
      </Helmet>
    </div>
  );
};

export default Page404;

