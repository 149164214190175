import "./legacy.css";

import React from "react";
import { Helmet } from "react-helmet";

import MainContent from "../general/MainContent";
import BreadCrumbs from "../sections/Breadcrumbs";
import HeadBanner from "../sections/HeadBanner";
import InfoSection from "../sections/InfoSection";


const Legacy = () => {
  return (
    <MainContent>
      <Helmet>
        <title>Charles Greig | Our Legacy</title>
      </Helmet>
      
      <HeadBanner
        imageLg={"/images/about/legacy-banner.jpg"}
        headPreGreen={"Our"}
        headWhite={"Legacy"}
        buttonLabel={"Explore"}
      />

      <BreadCrumbs
        crumbs={[
          { text: "Home", link: "/" },
          { text: "Our Legacy", link: "" },
        ]}
      />

      <section className={"about-section standard-width"}>
        <p className={"about-outlined"}>
          Heirloom pieces are handed down from generation to generation – and so too are the skills of the artisans who create them.
          <br />
          The Charles Greig Jewellers legacy is the story of five generations of a family, a nascent city and an ocean liner.
        </p>
      </section>

      <InfoSection
        info={{
          image: "/images/about/legacy-1.jpg",
          headPreGreen: "Charles Greig",
          headWhite: "The Start of a Legacy",
          subHead: `
            Charles Greig left home in 1894, waving farewell to the sparkling granite skyline of the handsome Scottish city of
            Aberdeen. He sailed south to make his fortune in another city which, at the time, stood on far less solid foundations. Johannesburg
            was a shantytown gripped by gold and diamond fever. Charles Greig arrived exactly on time: a shortage of mining clocks
            meant that his skills were immediately in demand.
          `,
        }}
        containImg={true}
      />

      <InfoSection
        info={{
          image: "/images/about/legacy-2.jpg",
          headPreGreen: "Johannesburg",
          headWhite: "City of Gold",
          subHead: `
            In stark contrast, Johannesburg was a shantytown gripped by the promise of future prosperity. With no architecture
            yet to speak of, it was the world’s richest goldfield-attracting prospectors from near and far. When the 25-year-old
            Greig disembarked to Cape Town (aptly, from the RSM Scot), he too headed north to the diggings to make his fortune.
          `,
        }}
        isVariant={true}
      />
      
      <InfoSection
        info={{
          image: "/images/about/legacy-3.png",
          headPreGreen: "Charles Hector",
          headWhite: "Greig",
          subHeadHtml: `
            <p>
              The only surviving heir to the business was born – like his father – in Aberdeen, as his mother was fearful of losing
              another child in infancy. Young Charles Hector returned to Africa bearing the hopes of his entire family.
            </p>
            <p>
              Charles Hector was a quintessential old-fashioned gentleman, with lovely manners. Everyone remembers him as a wonderful
              dancer at the country club where the colonial set partied on Saturday nights – and all the ladies adored him for the
              twinkle in his eye.
            </p>
          `,
        }}
        containImg={true}
      />
      
      <InfoSection
        info={{
          image: "/images/about/legacy-4.png",
          headPreGreen: "David",
          headWhite: "Greig",
          subHeadHtml: `
            <p>
              The only son of Charles Hector and Elsa, David Greig travelled to Switzerland to learn the secrets of horology and gemmology, before
              returning to join the family firm. His international experience meant that he was ideally placed to bring the glamour and
              style of the ‘50s to a South Africa that – at the time – seemed like a backwater.
            </p>
            <p>
              David Greig inherited Charles Hector’s great sense of humour. Just as importantly, he and his wife, sculptor Italia Burton, had four
              sons, ensuring the continuity of the family business, now based at prestigious new premises in Commissioner Street.
            </p>
          `,
        }}
        containImg={true}
        isVariant={true}
      />

      <InfoSection
        info={{
          image: "/images/about/legacy-5.png",
          headPreGreen: "Christopher, Donald & Richard",
          headWhite: "Greig",
          subHeadHtml: `
            <p>  
            The fourth generation to manage the family firm of Charles Greig Jewellers, each of the three brothers has diverged slightly in their
            interests, but remain bound together by their shared history and a shared commitment to beauty, innovation and customer service.
            </p>
            <p>
            A shared love of art and nature has manifested itself in three distinctive galleries, which has allowed Charles Greig Jewellers to expand its 
            offering to include additional product ranges that reflect each brother’s individual creativity and appreciation of quality workmanship. 
            Donald has crafted an international following for his superbly lifelike wildlife bronzes; Richard, with the expert eye and impeccable taste of a 
            curator has created an emporium of contemporary lifestyle wares from sculpture to leather goods; Christopher has chosen to celebrate various South African 
            sculptors in his gallery – naturally including his brother and mother. Together, the three galleries celebrate the best of South Africa’s creative output, 
            with works of art that alternately amuse, delight and inspire.
            </p>
          `,
        }}
      />
      
      <InfoSection
        info={{
          image: "/images/about/legacy-6.jpg",
          headPreGreen: "Tammy",
          headWhite: "Greig",
          subHeadHtml: `
            <p>
              Donald Greig’s daughter, Tammy, is the first member of the fifth generation of the family to enter the business – and, in a refreshing
              development – the first woman to join it since Elsa Grey flew the length of Africa over 70 years ago. 
            </p>
            <p>
              Tammy was born with gold dust in her veins; in addition, she has been imbued with the knowledge and emotion needed to be a professional
              jeweller from an early age. No-one could have predicted, back in 1889, that the name of Charles Greig would one day be known
              throughout South Africa; foreseeing a glowing future for the business is a much easier task now that this latest generation is
              bringing its own energy and ideas to this venerable institution.
            </p>
            <p>
              Currently based in London, Tammy represents the Charles Greig brand in the United Kingdom and Europe.
            <p>
          `,
        }}
        isVariant={true}
      />
    </MainContent>
  );
};

export default Legacy;