import "./press.css";

import React from "react";
import { connect } from "react-redux";

import { notifSelectors, adminPressSelectors } from "../../logic/rootSelectors";
import _adminPress from "../../logic/adminPress";
import { LS_ITEM_ADMIN, FORM_PROP_TYPE } from "../../utils/stateTypes";
import MainContent from "../adminGeneral/MainContent";
import EditForm from "../adminGeneral/EditForm";


const ProductEdit = ({
  pressEditing, pressLS, pressEditLS,
  editPressProp, savePress, loadPress,
}) => {

  return (
    <MainContent loadItems={[{ loadState: pressLS, loadFn: loadPress }]}>
      <h1 className={"page-head"}>MEDIA PORTAL</h1>
      <div className={"admin-divider"} />

      {/* ====================================== FORM ====================================== */}
      {
        (pressEditing) && (
          <div className={"standard-box admin-standard-width edit-box"}>
            <div className={"edit-box-half with-border"}>
              <div className={"edit-box-half-inner space-for-button"}>
                <EditForm
                  heading={"Edit Media Images"}
                  item={pressEditing}
                  loadingState={pressEditLS}
                  propsToEdit={[
                    { label: "Images", prop: "images", type: FORM_PROP_TYPE.SUBLIST, subProps: [
                      { label: "Image Full", prop: "imageLg", type: FORM_PROP_TYPE.IMAGE, maxWidth: 6000 },
                      { label: "Image Thumbnail", prop: "imageSm", type: FORM_PROP_TYPE.IMAGE, maxWidth: 800 },
                      { label: "Category", prop: "category", type: FORM_PROP_TYPE.SELECT, selectOptions: [{ value: "Campaign", label: "Campaign" }, { value: "Still Life", label: "Still Life" }] },
                    ] },
                  ]}
                  updatePropBase={editPressProp}
                  savePropEdit={savePress}
                />
              </div>
            </div>

            {/* ====================================== PREVIEW ====================================== */}
            <div className={"edit-box-half with-border"}>
              <div className={"edit-box-half-inner centered"}>
                <h3 className={"with-margin-bottom"}>Preview</h3>
                <div className={"press-preview"}>
                  {
                    (pressEditing.images) && (
                      pressEditing.images.map((img, i) => (
                        <img
                          key={img.id}
                          src={img.imageSm}
                          className={"standard-box"}
                          alt={`Press image ${i + 1}`}
                        />
                      ))
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        )
      }
    </MainContent>
  );
};

const mapStateToProps = (state) => {
  return {
    pressEditing: adminPressSelectors.getPressEditing(state),
    pressLS: notifSelectors.getLoadState(state)(LS_ITEM_ADMIN.PRESS_LOAD),
    pressEditLS: notifSelectors.getLoadState(state)(LS_ITEM_ADMIN.PRESS_SAVE),
  };
};

const connected = connect(
  mapStateToProps,
  {
    editPressProp: _adminPress.actions.editPressProp,
    savePress: _adminPress.actions.savePress,
    loadPress: _adminPress.actions.loadPress,
  },
)(ProductEdit);

export default connected;

