import React from "react";
import { connect } from "react-redux";
import { Button, TextField, Select, FormControl, InputLabel, MenuItem, CircularProgress } from "@mui/material";

import { homeSelectors, notifSelectors } from "../../logic/rootSelectors";
import _home from "../../logic/home";
import { ENQUIRY_TYPE, LS_ITEM, LOADING_STATE } from "../../utils/stateTypes";

const styles = {
  input: {
    margin: "0 auto 20px auto",
    textTransform: "none",
    width: "100%",
    maxWidth: 500,
    textAlign: "left",
  },
  submitButton: {
    width: 200,
    padding: "10px 30px",
  },
};

const ContactForm = ({
  contactFields, contactLS,
  updateContactField, sendMessage,
}) => {

  let submitLabel = "Send";
  if (contactLS === LOADING_STATE.LOADING) {
    submitLabel = <CircularProgress size={25} />;
  }
  else if (contactLS === LOADING_STATE.LOADED) {
    submitLabel = "Sent!";
  }
  else if (contactLS === LOADING_STATE.ERROR) {
    submitLabel = "Retry";
  }

  return (
    <form
      className={"message-form standard-width"}
      onSubmit={(e) => {
        e.preventDefault();
        sendMessage();
      }}
    >
      <h3>Message us</h3>
      <div className={"message-form-inner"}>
        <TextField
          style={styles.input}
          variant={"outlined"}
          size={"small"}
          color={"secondary"}
          label={"Name"}
          value={contactFields.name}
          onChange={(e) => updateContactField("name", e.target.value)}
          required
        />
        <br />
      
        <TextField
          style={styles.input}
          variant={"outlined"}
          size={"small"}
          color={"secondary"}
          label={"Email"}
          type={"email"}
          value={contactFields.email}
          onChange={(e) => updateContactField("email", e.target.value)}
          required
        />
        <br />
      
        <TextField
          style={styles.input}
          variant={"outlined"}
          size={"small"}
          color={"secondary"}
          label={"Cell"}
          type={"tel"}
          value={contactFields.cell}
          onChange={(e) => updateContactField("cell", e.target.value)}
        />
        <br />

        <FormControl
          color={"secondary"}
          size={"small"}
          style={styles.input}
        >
          <InputLabel>Store</InputLabel>
          <Select
            label={"Store"}
            value={contactFields.store}
            onChange={(e) => updateContactField("store", e.target.value)}
            color={"secondary"}
          >
            <MenuItem value={"Hyde Park"}>Hyde Park</MenuItem>
            <MenuItem value={"V&A Waterfront"}>V&A Waterfront</MenuItem>
          </Select>
        </FormControl>
        <br />

        <FormControl
          color={"secondary"}
          size={"small"}
          required={true}
          style={styles.input}
        >
          <InputLabel>Topic</InputLabel>
          <Select
            label={"Topic"}
            value={contactFields.type}
            onChange={(e) => updateContactField("type", e.target.value)}
            color={"secondary"}
          >
            {
              Object.values(ENQUIRY_TYPE).map((type) => (
                <MenuItem
                  value={type}
                  key={type}
                >
                  {type}
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>
        <br />
       
        <TextField
          style={styles.input}
          variant={"outlined"}
          size={"small"}
          color={"secondary"}
          label={"Message"}
          multiline
          minRows={4}
          value={contactFields.message}
          onChange={(e) => updateContactField("message", e.target.value)}
          required
        />
        <br />

        <Button
          variant={"contained"}
          style={styles.submitButton}
          color={"secondary"}
          type={"submit"}
          disabled={(contactLS === LOADING_STATE.LOADING) || (contactLS === LOADING_STATE.LOADED)}
        >
          {submitLabel}
        </Button>
      </div>
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    contactFields: homeSelectors.getContactFields(state),
    contactLS: notifSelectors.getLoadState(state)(LS_ITEM.CONTACT_SEND),
  };
};

const connected = connect(
  mapStateToProps,
  {
    updateContactField: _home.actions.updateContactField,
    sendMessage: _home.actions.sendMessage,
  },
)(ContactForm);

export default connected;