import "./partners.css";

import React from "react";
import { Helmet } from "react-helmet";

import MainContent from "../general/MainContent";
import BreadCrumbs from "../sections/Breadcrumbs";
import HeadBanner from "../sections/HeadBanner";
import InfoSection from "../sections/InfoSection";


const Partners = () => {
  return (
    <MainContent>
      <Helmet>
        <title>Charles Greig | The Charles Greig Gallery</title>
      </Helmet>
      
      <HeadBanner
        imageLg={"/images/about/partners-banner.jpg"}
        headPreGreen={"Our"}
        headWhite={"Galleries"}
        buttonLabel={"Explore"}
      />

      <BreadCrumbs
        crumbs={[
          { text: "Home", link: "/" },
          { text: "The Charles Greig Gallery", link: "" },
        ]}
      />

      <InfoSection
        info={{
          image: "/images/about/partner-1.jpg",
          headPreGreen: "The Charles Greig",
          headWhite: "Gallery",
          subHead: `
            In Johannesburg, Christopher Greig saw the opportunity to showcase the works of the family’s two talented
            sculptors, his mother Italia, and brother, Donald Greig. The Charles Greig Gallery in Hyde Park came into
            being as a contemporary urban space to display pieces inspired by the free spirit of Africa, and carefully
            curated bronzes which talk to the resurgence of this ancient art form in today’s Rainbow Nation.
          `,
          linkUrl: "https://www.thecharlesgreiggallery.co.za/",
          linkLabel: "Visit site",
        }}
      />

      <InfoSection
        info={{
          image: "/images/about/partner-2.jpg",
          headPreGreen: "The Donald Greig",
          headWhite: "Foundary",
          subHead: `
            Donald Greig is a talented, prolific and collectible sculptor of African animal forms. His foundary in
            Cape Town’s V & A Waterfront displays a dazzling menagerie of animal and bird sculptures both large and
            small, and, as befits a gallery at a major maritime crossroads, boasts an international clientele. Donald’s limited
            edition ‘lost wax’ bronze pieces can be ordered through any Charles Greig Jewellers store.
          `,
          linkUrl: "https://www.donaldgreig.co.za/",
          linkLabel: "Visit site",
        }}
        isVariant={true}
      />
    </MainContent>
  );
};

export default Partners;