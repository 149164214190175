import * as actionType from "./actionTypes";
import { updateObject, createReducer, setItemEditing, updateItem, editItemProp, deleteItem, editItemPropByID } from "../../utils/reducerUtils";
import { CATEGORY_TYPE } from "../../utils/stateTypes";

/* State shape same as initial state
*/
const initialState = {
  activeType: CATEGORY_TYPE.TIMEPIECES,
  category: {
    byID: {},
    allIDs: [],
  },
  categoryEditing: null,
};

const setActiveType = (state, action) => (
  updateObject(state, {
    activeType: action.activeType,
  })
);

const receiveCategories = (state, action) => (
  updateObject(state, {
    category: action.category,
  })
);

const reorderCategories = (state, action) => (
  updateObject(state, {
    category: updateObject(state.category, {
      allIDs: action.newOrder,
    }),
  })
);

const reducer = createReducer(initialState, {
  [actionType.SET_ACTIVE_TYPE]: setActiveType,

  [actionType.RECEIVE_CATEGORIES]: receiveCategories,

  [actionType.SET_CATEGORY_EDITING]: setItemEditing("category"),
  [actionType.EDIT_CATEGORY_PROP]: editItemProp("category"),
  [actionType.EDIT_CATEGORY_BY_ID_PROP]: editItemPropByID("category"),
  [actionType.UPDATE_CATEGORY]: updateItem("category"),
  [actionType.DELETE_CATEGORY]: deleteItem("category"),
  [actionType.REORDER_CATEGORIES]: reorderCategories,
});

export default reducer;

// SELECTORS
export const getActiveType = (state) => (
  state.activeType
);

export const getCategories = (state) => (
  state.category.allIDs.map((id) => state.category.byID[id])
);

export const getCategoryEditing = (state) => (
  state.categoryEditing
);