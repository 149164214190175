import React from "react";
import { CircularProgress } from "@mui/material";

const LoadingFull = () => {
  return (
    <div className={"login-main"}>
      <div className={"inner-cont"}>
        <p className={"loading-text"}>Loading</p>
        <CircularProgress size={30} />
      </div>
    </div>
  );
};

export default LoadingFull;

