import React from "react";
import { connect } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

import { authSelectors } from "../logic/rootSelectors";
import _auth from "../logic/auth";
import { ADMIN_PAGE_ROUTES } from "../utils/stateTypes";
import Notification from "./notification/Notification";
import Login from "./auth/Login";
import LoadingFull from "./auth/LoadingFull";
import TopNav from "./home/TopNav";
import HomeNav from "./home/HomeNav";
import BannersHome from "./banner/BannersHome";
import BannerEdit from "./banner/BannerEdit";
import CategoryHome from "./category/CategoryHome";
import CategoryEdit from "./category/CategoryEdit";
import ProductEdit from "./product/ProductEdit";
import PressEdit from "./press/PressEdit";


const App = ({
  isAuthed, hasCheckedAuth,
  initAuth, logout,
}) => {
  let location = useLocation();

  React.useEffect(() => {
    initAuth();
  }, []);
  
  if (!hasCheckedAuth) {
    return (
      <LoadingFull />
    );
  }
  if (!isAuthed) {
    return (
      <main>
        <Helmet>
          <title>Charles Greig | Admin</title>
          <meta
            name={"robots"}
            content={"noindex"}
          />
        </Helmet>
        <Notification />
        <TopNav logout={logout} />
        <Login />
      </main>
    );
  }
  return (
    <main>
      <Helmet>
        <title>Charles Greig | Admin</title>
        <meta
          name={"robots"}
          content={"noindex"}
        />
      </Helmet>
      <Notification />
      <TopNav logout={logout} />
      <Routes location={location}>
        <Route
          exact
          path={ADMIN_PAGE_ROUTES.HOME}
          element={<HomeNav />}
        />
        <Route
          exact
          path={ADMIN_PAGE_ROUTES.BANNERS}
          element={<BannersHome />}
        />
        <Route
          exact
          path={ADMIN_PAGE_ROUTES.BANNER_EDIT}
          element={<BannerEdit />}
        />
        <Route
          exact
          path={ADMIN_PAGE_ROUTES.CATEGORIES}
          element={<CategoryHome />}
        />
        <Route
          exact
          path={ADMIN_PAGE_ROUTES.CATEGORY_EDIT}
          element={<CategoryEdit />}
        />
        <Route
          exact
          path={ADMIN_PAGE_ROUTES.PRODUCT_EDIT}
          element={<ProductEdit />}
        />
        <Route
          exact
          path={ADMIN_PAGE_ROUTES.PRESS_EDIT}
          element={<PressEdit />}
        />
      </Routes>
    </main>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthed: (authSelectors.getAuthToken(state) !== null),
    hasCheckedAuth: authSelectors.getHasCheckedAuth(state),
  };
};

const connectedApp = connect(
  mapStateToProps,
  {
    initAuth: _auth.actions.initAuth,
    logout: _auth.actions.logout,
  },
)(App);

export default connectedApp;

