/* eslint-disable react-hooks/exhaustive-deps */
import "./uploadFileDialog.css";

import React, { useState, useCallback } from "react";
import { Button, CircularProgress, Dialog, DialogContent } from "@mui/material";
import Cropper from "react-easy-crop";

import { readFile, submitCroppedImg, submitFileUnprocessed } from "../../utils/imageUtils";

const styles = {
  diagContent: {
    textAlign: "center",
    paddingBottom: 15,
  },
  selectImgButton: {
    width: 200,
    height: 70,
    marginBottom: 20,
  },
  cropButton: {
    margin: 5,
    width: 150,
  },
};

const UploadFileDialog = ({ open, closeDiag, editFileProp, aspect=null, droppedFile=null, fileAccept="*/*" }) => {

  const [clickedUpload, setClickedUpload] = useState(false);
  const [fileSrc, setFileSrc] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [step, setStep] = useState(0);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  //Set up file upload function
  const onFileChange = (e) => {
    setClickedUpload(true);
    if (e.target.files && (e.target.files.length > 0)) {
      const file = e.target.files[0];
      readFile(file).then((fileDataUrl) => {
        setClickedUpload(false);
        if (aspect !== null) {
          setFileSrc(fileDataUrl);
          setFileName(file.name);
          setStep(1);
        }
        else {
          onFileSubmit(fileDataUrl, file.name);
        }
      });
    }
    e.target.value = null;
  };

  //Called once the user has moved/zoomed the crop area
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  //We call this when the submit button is clicked
  const onFileSubmit = useCallback((fileOverride=null, fileNameOverride=null) => {
    setStep(2);
    const fileProcessSuccessCB = () => {
      closeDiag();
      setTimeout(() => {  //Timeout so that the form reset isn't visible during modal fade out
        setStep(0);
      }, 500);
      
    };
    if (aspect !== null) {
      submitCroppedImg(fileSrc, fileName, croppedAreaPixels, editFileProp, fileProcessSuccessCB);
    }
    else {
      submitFileUnprocessed(fileOverride, fileNameOverride, editFileProp, fileProcessSuccessCB);
    }
    
  }, [fileSrc, croppedAreaPixels, editFileProp, closeDiag, aspect]);

  //When toggling to open, check if there is a drag and dropped file so we can skip straight to the crop step, or upload step if it doesnt need cropping
  React.useEffect(() => {
    if (open && (droppedFile !== null)) {
      setClickedUpload(true);
      readFile(droppedFile).then((fileDataUrl) => {
        setClickedUpload(false);
        if (aspect !== null) {
          setFileSrc(fileDataUrl);
          setFileName(droppedFile.name);
          setStep(1);
        }
        else {
          onFileSubmit(fileDataUrl, droppedFile.name);
        }
      });
    }
    else if (open) {
      setStep(0);
    }
  }, [open, droppedFile, aspect]);

  let innerContent;

  if (step === 0) {
    //Select a file from the filesystem
    innerContent = (
      <React.Fragment>
        <input
          id={"upload-hidden"}
          type={"file"}
          onChange={onFileChange}
          accept={fileAccept}
          // accept={(isVideo) ? "video/*" : "image/*"}
          hidden
        />
        <label
          htmlFor={"upload-hidden"}
          style={{ pointerEvents: clickedUpload ? "none" : "auto" }}
        >
          <Button
            variant={"contained"}
            color={"primary"}
            style={styles.selectImgButton}
            component={"span"}
            disabled={clickedUpload}
          >
            {clickedUpload ? "Uploading..." : "Select File"}
          </Button>
        </label>
        <br />
        <Button
          variant={"contained"}
          color={"secondary"}
          onClick={closeDiag}
        >
          Cancel
        </Button>
      </React.Fragment>
    );
  }
  else if (step === 1) {
    //Crop the image
    innerContent = (
      <React.Fragment>
        <div className={"cropper-cont standard-box"}>
          <Cropper
            image={fileSrc}
            crop={crop}
            rotation={0}
            zoom={zoom}
            aspect={aspect}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </div>

        <div className={"cropper-buttons"}>
          <Button
            variant={"contained"}
            color={"primary"}
            style={styles.cropButton}
            onClick={onFileSubmit}
          >
            Select Crop
          </Button>

          <br />

          <Button
            variant={"contained"}
            color={"secondary"}
            onClick={() => setStep(0)}
            style={styles.cropButton}
          >
            Back
          </Button>
        </div>
      </React.Fragment>
    );
  }
  else if (step === 2) {
    innerContent = (
      <React.Fragment>
        <CircularProgress
          color={"primary"}
          size={30}
        />
        <p>Processing file...</p>
      </React.Fragment>
    );
  }

  return (
    <Dialog
      onClose={closeDiag}
      open={open}
      disableScrollLock={true}
    >
      <DialogContent style={styles.diagContent}>
        {innerContent}
      </DialogContent>
    </Dialog>
  );
};


export default UploadFileDialog;