import React from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Button } from "@mui/material";

import { notifSelectors, adminCategorySelectors } from "../../logic/rootSelectors";
import _adminCategory from "../../logic/adminCategory";
import { LOADING_STATE, LS_ITEM_ADMIN, FORM_PROP_TYPE } from "../../utils/stateTypes";
import MainContent from "../adminGeneral/MainContent";
import EditForm from "../adminGeneral/EditForm";
import SectionHead from "../../components/general/SectionHead";
import ProductList from "../product/ProductList";

const styles = {
  actionButton: {
    marginTop: 8,
    padding: "5px 15px",
    fontSize: "0.7em",
  },
};


const CategoryEdit = ({
  categoryEditing, categoriesLS, categoryEditLS,
  setCategoryEditing, editCategoryProp, saveCategory, loadCategories,
}) => {
  let { categoryID } = useParams();
  categoryID = parseInt(categoryID);

  //Set item editing when loaded
  React.useEffect(() => {
    if (categoriesLS === LOADING_STATE.LOADED) {
      setCategoryEditing(categoryID);
    }
  }, [categoryID, categoriesLS]);


  return (
    <MainContent loadItems={[{ loadState: categoriesLS, loadFn: loadCategories }]}>
      <h1 className={"page-head"}>EDIT CATEGORY</h1>
      <div className={"admin-divider"} />

      {/* ====================================== FORM ====================================== */}
      {
        (categoryEditing) && (
          <React.Fragment>
            <div className={"standard-box admin-standard-width edit-box"}>
              <div className={"edit-box-half with-border"}>
                <div className={"edit-box-half-inner space-for-button"}>
                  <EditForm
                    heading={"Edit Category"}
                    item={categoryEditing}
                    loadingState={categoryEditLS}
                    propsToEdit={[
                      { label: "Is Active", prop: "isActive", type: FORM_PROP_TYPE.CHECKBOX },
                      { label: "Name", prop: "name", type: FORM_PROP_TYPE.TEXT },
                      { label: "URL", prop: "url", type: FORM_PROP_TYPE.TEXT },
                      { label: "Preview Image", prop: "previewImage", type: FORM_PROP_TYPE.IMAGE, maxWidth: 1200, isOptional: true },
                      { label: "Preview Video", prop: "previewVideo", type: FORM_PROP_TYPE.VIDEO, isOptional: true },
                      { label: "Preview Logo", prop: "previewLogo", type: FORM_PROP_TYPE.IMAGE, isOptional: true },
                      { label: "Header Image", prop: "headerImage", type: FORM_PROP_TYPE.IMAGE, maxWidth: 2500 },
                      { label: "Header Title (Pre)", prop: "headerTextPre", type: FORM_PROP_TYPE.TEXT, isOptional: true },
                      { label: "Header Title", prop: "headerText", type: FORM_PROP_TYPE.TEXT },
                      { label: "Header Title (Post)", prop: "headerTextPost", type: FORM_PROP_TYPE.TEXT, isOptional: true },
                      { label: "Info Sections", prop: "infoSections", type: FORM_PROP_TYPE.SUBLIST, isOptional: true, subProps: [
                        { label: "Head (Pre)", prop: "headPre", type: FORM_PROP_TYPE.TEXT, isOptional: true },
                        { label: "Head", prop: "head", type: FORM_PROP_TYPE.TEXT },
                        { label: "Head (Post)", prop: "headPost", type: FORM_PROP_TYPE.TEXT, isOptional: true },
                        { label: "Body", prop: "body", type: FORM_PROP_TYPE.MARKDOWN },
                        { label: "Link URL", prop: "linkUrl", type: FORM_PROP_TYPE.TEXT, isOptional: true },
                        { label: "Link Label", prop: "linkLabel", type: FORM_PROP_TYPE.TEXT, isOptional: true },
                        { label: "Image", prop: "image", type: FORM_PROP_TYPE.IMAGE, maxWidth: 1500 },
                      ] },
                      { label: "Custom Page Name", prop: "customPageName", type: FORM_PROP_TYPE.TEXT, isOptional: true },
                    ]}
                    updatePropBase={editCategoryProp}
                    savePropEdit={saveCategory}
                  />
                </div>
              </div>

              {/* ====================================== PREVIEW ====================================== */}
              <div className={"edit-box-half with-border"}>
                <div className={"edit-box-half-inner centered"}>
                  <h3 className={"with-margin-bottom"}>Preview</h3>
                  <div className={"category-block-preview standard-box"}>
                    {
                      (categoryEditing.previewVideo) ? (
                        <video
                          src={categoryEditing.previewVideo}
                          className={"category-block-preview-bg"}
                          autoPlay
                          loop
                          muted
                        />
                      ) : (
                        <img
                          src={categoryEditing.previewImage ? categoryEditing.previewImage : "/images/admin/placeholder-image.png"}
                          className={"category-block-preview-bg"}
                        />
                      )
                    }
                    <div className={"category-block-preview-overlay"}>
                      {
                        (categoryEditing.previewLogo) ? (
                          <img
                            src={categoryEditing.previewLogo}
                            className={"category-block-preview-logo"}
                          />
                        ) : (
                          <p className={"category-block-preview-name"}>{categoryEditing.name}</p>
                        )
                      }
                    
                      <Button
                        variant={"contained"}
                        color={"primary"}
                        style={styles.actionButton}
                      >
                        Explore
                      </Button>
                    </div>
                  </div>

                  <div className={"category-page-preview standard-box"}>
                    <div className={"banner-preview-nav"}>
                      <img src={"/images/logo-header.png"} />
                    </div>
                    <div className={"category-page-preview-header"}>
                      <img src={categoryEditing.headerImage ? categoryEditing.headerImage : "/images/admin/placeholder-image.png"} />
                      <div className={"category-page-preview-cont"}>
                        <SectionHead
                          className={"category-page-preview-text"}
                          preGreenText={categoryEditing.headerTextPre}
                          whiteText={categoryEditing.headerText}
                          postGreenText={categoryEditing.headerTextPost}
                        />
                        <Button
                          variant={"contained"}
                          style={styles.actionButton}
                          color={"secondary"}
                        >
                          Start now
                        </Button>
                      </div>
                    </div>
                    <div className={"banner-preview-next-section"}>
                      <p><i>Next section</i></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <ProductList />
          </React.Fragment>
        )
      }
    </MainContent>
  );
};

const mapStateToProps = (state) => {
  return {
    categoryEditing: adminCategorySelectors.getCategoryEditing(state),
    categoriesLS: notifSelectors.getLoadState(state)(LS_ITEM_ADMIN.CATEGORIES_LOAD),
    categoryEditLS: notifSelectors.getLoadState(state)(LS_ITEM_ADMIN.CATEGORY_SAVE),
  };
};

const connected = connect(
  mapStateToProps,
  {
    setCategoryEditing: _adminCategory.actions.setCategoryEditing,
    editCategoryProp: _adminCategory.actions.editCategoryProp,
    saveCategory: _adminCategory.actions.saveCategory,
    loadCategories: _adminCategory.actions.loadCategories,
  },
)(CategoryEdit);

export default connected;

