import "./search.css";

import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { useNavigate, useParams, Link } from "react-router-dom";
import { Button } from "@mui/material";

import { homeSelectors, notifSelectors } from "../../logic/rootSelectors";
import _home from "../../logic/home";
import { LS_ITEM, PAGE_ROUTES, SEARCH_RESULT_TYPE } from "../../utils/stateTypes";
import MainContent from "../general/MainContent";
import SectionHead from "../general/SectionHead";
import NoResults from "../general/NoResults";

const styles = {
  linkButton: {
    width: 150,
  },
};


const Search = ({
  searchResults, searchLS,
  getSearchResults,
}) => {
  let { searchQuery } = useParams();
  const navigate = useNavigate();
  searchQuery = searchQuery.trim().toLowerCase();

  React.useEffect(() => {
    if (searchQuery === "rolex") {
      navigate(PAGE_ROUTES.CATEGORY.replace(":categoryUrl", "rolex"));
      return;
    }
    getSearchResults(searchQuery);
  }, [searchQuery]);

  return (
    <MainContent loadItems={[{ loadState: searchLS, loadFn: () => {} }]}>
      <Helmet>
        <title>Charles Greig | Search for '{searchQuery}'</title>
      </Helmet>

      <section className={"search-results"}>
        <SectionHead
          preGreenText={"Search results for"}
          whiteText={searchQuery}
          className={"section-head-standard"}
        />
        {
          (searchResults.length === 0) && (
            <NoResults />
          )
        }
        {
          searchResults.map((result, index) => (
            <div
              key={index}
              className={"search-result-block"}
            >
              <img src={result.image} />
              <div className={"search-result-info"}>
                <h3 className={"search-result-name"}>{result.name}</h3>
                <Link to={(result.type === SEARCH_RESULT_TYPE.CATEGORY) ? PAGE_ROUTES.CATEGORY.replace(":categoryUrl", result.url) : PAGE_ROUTES.PRODUCT.replace(":productUrl", result.url)}>
                  <Button
                    variant={"contained"}
                    color={"secondary"}
                    style={styles.linkButton}
                  >
                    {(result.type === SEARCH_RESULT_TYPE.CATEGORY) ? "Go to section" : "Go to product"}
                  </Button>
                </Link>
              </div>
            </div>
          ))
        }
      </section>
    </MainContent>
  );
};

const mapStateToProps = (state) => {
  return {
    searchResults: homeSelectors.getSearchResults(state),
    searchLS: notifSelectors.getLoadState(state)(LS_ITEM.SEARCH),
  };
};

const connected = connect(
  mapStateToProps,
  {
    getSearchResults: _home.actions.getSearchResults,
  },
)(Search);

export default connected;