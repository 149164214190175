import * as actionType from "./actionTypes";
import { getRequestAuthed, postRequestAuthed, processError, prepPostData } from "../../utils/apiHandlers";
import { LOADING_STATE, LS_ITEM_ADMIN, NOTIF_TYPE } from "../../utils/stateTypes";
import { editItemProp } from "../../utils/actionUtils";
import { adminPressSelectors } from "../rootSelectors";
import _notif from "../notification";


export const loadPress = () => (
  (dispatch) => {
    const lsItem = LS_ITEM_ADMIN.PRESS_LOAD;
    dispatch(_notif.actions.setLoadState(lsItem, LOADING_STATE.LOADING));
    getRequestAuthed("/AdminPress/GetPress",
      (press) => {
        dispatch({
          type: actionType.RECEIVE_PRESS,
          press,
        });
        dispatch(_notif.actions.setLoadState(lsItem, LOADING_STATE.LOADED));
      },
      processError(dispatch, _notif, lsItem),
    );
  }
);

export const editPressProp = editItemProp(actionType.EDIT_PRESS_PROP);

export const savePress = () => (
  (dispatch, getState) => {
    const pressEditing = adminPressSelectors.getPressEditing(getState());

    let postData = prepPostData(pressEditing);

    //Separate the files
    let extraFiles = [];
    if (pressEditing.images) {
      let newImages = [];
      for (let item of pressEditing.images) {
        if ((!item.imageLg) && (!item.imageSm)) {
          continue;
        }
        const processedItem = prepPostData(item, ["imageLg", "imageSm"]);
        newImages.push(processedItem.item);
        if ((processedItem.item.imageSm === "new") && (processedItem.item.imageLg === "new")) {
          processedItem.newFiles[0].propName = `${item.id.toString()}-lg`;
          processedItem.newFiles[1].propName = `${item.id.toString()}-sm`;
        }
        else if (processedItem.item.imageSm === "new") {
          processedItem.newFiles[0].propName = `${item.id.toString()}-sm`;
        }
        else if (processedItem.item.imageLg === "new") {
          processedItem.newFiles[0].propName = `${item.id.toString()}-lg`;
        }
        extraFiles = [
          ...extraFiles,
          ...processedItem.newFiles,
        ];
      }
      postData.item.images = newImages;
    }

    let lsItem = LS_ITEM_ADMIN.PRESS_SAVE;
    dispatch(_notif.actions.setLoadState(lsItem, LOADING_STATE.LOADING));
    postRequestAuthed("/AdminPress/SavePress",
      {
        item: postData.item,
        newFiles: postData.newFiles,
        extraFiles,
      },
      (press) => {
        dispatch({
          type: actionType.RECEIVE_PRESS,
          press,
        });

        dispatch(_notif.actions.queueNotification("Media portal updated", NOTIF_TYPE.SUCCESS));
        dispatch(_notif.actions.setLoadState(lsItem, LOADING_STATE.LOADED));
      },
      processError(dispatch, _notif, lsItem),
    );
  }
);