import "./breadcrumbs.css";

import React from "react";
import { ChevronRight } from "@mui/icons-material";
import { Link } from "react-router-dom";

const Breadcrumbs = ({ crumbs }) => {

  return (
    <div className={"breadcrumbs-cont"}>
      {
        crumbs.map((crumb, index) => (
          <React.Fragment key={index}>
            {
              (index < crumbs.length - 1) ? (
                <React.Fragment>
                  <Link to={crumb.link}>
                    <span className={"breadcrumb-text"}>{crumb.text}</span>
                  </Link>
                  <span className={"breadcrumb-separator"}><ChevronRight /></span>
                </React.Fragment>
              ) : (
                <span className={"breadcrumb-text"}>{crumb.text}</span>
              )
            }
          </React.Fragment>
        ))
      }
    </div>
  );
};

export default Breadcrumbs;