
import { uniqueID } from "../utils/generalUtils";

const localStorage = window.localStorage;

export const storeTokens = async (token, refreshToken) => {
  await localStorage.setItem("jwt", token);
  await localStorage.setItem("refresh", refreshToken);
};

export const clearTokens = async () => {
  await localStorage.removeItem("jwt");
  await localStorage.removeItem("refresh");
};

export const getToken = async () => {
  const value = await localStorage.getItem("jwt");
  return value;
};

export const getRefreshToken = async () => {
  const value = await localStorage.getItem("refresh");
  return value;
};

/* ------------------------ LOCAL STORAGE - CONSENT ------------------------ */
const lsConsent = "cookieConsent";
const lsConsentTS = "consentTS";
const lsConsentVName = "consentVersion";
export const lsConsentVersion = "1.0";
const lsConsentSessionIDs = "consentSessions";

//This function loads the saved consent preferences from localstorage
export const loadSavedConsent = () => {
  if (localStorage.getItem(lsConsent) !== null) {
    //Previous consent exists, check if it has expired or if there is a version mismatch
    const prevSessionTS = localStorage.getItem(lsConsentTS);
    const currentTS = Date.now();
    if (prevSessionTS !== null) {
      if (currentTS - prevSessionTS > 1.577e10) {  //See if its older than 6 months
        //Its expired, delete the prev session
        localStorage.removeItem(lsConsent);
        localStorage.removeItem(lsConsentTS);
        localStorage.removeItem(lsConsentVName);
        return null;
      }
    }
    const previousVersion = localStorage.getItem(lsConsentVName);
    if (previousVersion !== lsConsentVersion) {
      //Version mismatch, delete the prev session
      localStorage.removeItem(lsConsent);
      localStorage.removeItem(lsConsentTS);
      localStorage.removeItem(lsConsentVName);
      return null;
    }

    return JSON.parse(localStorage.getItem(lsConsent));
  }
  return null;
};

//Save the provided consent preferences to localStorage
export const saveConsentPreferences = (savedConsent) => {
  let sessionIDs = [];
  //Get the previous session ID array if it exists
  if (localStorage.getItem(lsConsentSessionIDs) !== null) {
    sessionIDs = JSON.parse(localStorage.getItem(lsConsentSessionIDs));
  }
  //Add a new ID
  const newSession = uniqueID();
  sessionIDs.push(newSession);

  localStorage.setItem(lsConsent, JSON.stringify(savedConsent));
  localStorage.setItem(lsConsentTS, Date.now());
  localStorage.setItem(lsConsentVName, lsConsentVersion);
  localStorage.setItem(lsConsentSessionIDs, JSON.stringify(sessionIDs));

  return newSession;
};

export const enableRlxCookies = () => {
  //Create a cookie called rlx-consent and rlx to allow the rlx cookies
  document.cookie = "rlx-consent=true; max-age=31536000; path=/;";
  document.cookie = "rlx=allow; max-age=31536000; path=/;";

  let rlxCorner = document.getElementById("rlxCorner");
  if (rlxCorner && rlxCorner.contentWindow && rlxCorner.contentWindow.postMessage) {
    rlxCorner.contentWindow.postMessage("consentTrue", "https://cornersv7.rolex.com");
    rlxCorner.contentWindow.postMessage("consentValidation", "https://cornersv7.rolex.com");
  }
};

export const disableRlxCookies = () => {
  document.cookie = "rlx-consent=false; max-age=31536000; path=/;";
  document.cookie = "rlx=deny; max-age=31536000; path=/;";
};