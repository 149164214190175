import "./product.css";

import React from "react";
import { connect } from "react-redux";
import { Button, CircularProgress } from "@mui/material";
import { Add } from "@mui/icons-material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useParams } from "react-router-dom";

import { adminProductSelectors, notifSelectors } from "../../logic/rootSelectors";
import _adminProduct from "../../logic/adminProduct";
import { ADMIN_PAGE_ROUTES, LS_ITEM_ADMIN, LOADING_STATE } from "../../utils/stateTypes";
import RowItem from "../adminGeneral/RowItem";

const styles = {
  addButton: {
    margin: "20px 0 10px 0",
    width: "100%",
    maxWidth: 200,
    height: 40,
  },
  selected: {
    backgroundColor: "#1a2b16",
    color: "#fff",
  },
};


const ProductList = ({
  products, productsLS, loadedCategoryID, addProductLS, getLoadState,
  loadProducts, addProduct, deleteProduct, reorderProducts,
}) => {
  let { categoryID } = useParams();
  categoryID = parseInt(categoryID);

  React.useEffect(() => {
    if ((productsLS === LOADING_STATE.NOT_LOADING) || (loadedCategoryID !== categoryID)) {
      loadProducts(categoryID);
    }
  }, [categoryID]);
  
  const onDragEnd = React.useCallback((result) => {
    if (result.destination !== null) {
      if (result.source.index !== result.destination.index) {
        reorderProducts(result.source.index, result.destination.index, categoryID);
      }
    }
  }, [categoryID]);

  if (productsLS === LOADING_STATE.LOADING) {
    return (
      <div className={"admin-products-cont"}>
        <h1 className={"admin-page-head"}>PRODUCTS</h1>
        <div className={"admin-divider"} />
        <CircularProgress size={50} />
      </div>
    );
  }

  return (
    <div className={"admin-products-cont"}>
      <h1 className={"admin-page-head"}>PRODUCTS</h1>
      <div className={"admin-divider"} />
    
      <DragDropContext onDragEnd={onDragEnd}>
        <div className={"admin-standard-width accordian-cont"}>
          <Droppable
            droppableId={"itemDrop"}
            direction={"vertical"}
          >
            {(dropProps) => (
              <div
                ref={dropProps.innerRef}
                {...dropProps.droppableProps}
                className={"draggable-list-cont"}
              >
                <div className={"admin-standard-box"}>
                  {
                    products.map((product, productIndex) => (
                      <Draggable
                        key={product.id}
                        draggableId={`${product.id}`}
                        index={productIndex}
                      >
                        {(dragProps) => (
                          <div
                            ref={dragProps.innerRef}
                            {...dragProps.draggableProps}
                            className={"draggable-item-cont"}
                          >
                            <RowItem
                              item={product}
                              deleteLS={getLoadState(`${LS_ITEM_ADMIN.PRODUCT_DELETE}${product.id}`)}
                              deleteCB={() => deleteProduct(product.id)}
                              editLink={ADMIN_PAGE_ROUTES.PRODUCT_EDIT.replace(":productID", product.id).replace(":categoryID", categoryID)}
                              isSubRow={true}
                              moveLS={getLoadState(`${LS_ITEM_ADMIN.PRODUCT_REORDER}${product.id}`)}
                              dragHandleProps={dragProps.dragHandleProps}
                            >
                              <div className={["admin-cat-row", product.isActive ? "" : "not-active"].join(" ")}>
                                <img src={product.previewImage ? product.previewImage : "/images/admin/placeholder-image.png"} />
                                <div>
                                  {
                                    (product.isActive) ? <span>ACTIVE</span> : <span>INACTIVE</span>
                                  }
                                  <h3>{product.name ? product.name : <i>No name</i>}</h3>
                                  <p>{product.model ? product.model : <i>No model</i>}</p>
                                </div>
                              </div>
                            </RowItem>
                          </div>
                        )}
                      </Draggable>
                    ))
                  }
                  {dropProps.placeholder}
                </div>
              </div>
            )}
          </Droppable>

          <Button
            variant={"contained"}
            color={"primary"}
            style={styles.addButton}
            startIcon={(addProductLS !== LOADING_STATE.LOADING) ? <Add /> : <CircularProgress size={20} />}
            disabled={(addProductLS === LOADING_STATE.LOADING)}
            onClick={() => addProduct(categoryID)}
          >
            Add Product
          </Button>
        </div>
      </DragDropContext>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    products: adminProductSelectors.getProducts(state),
    productsLS: notifSelectors.getLoadState(state)(LS_ITEM_ADMIN.PRODUCTS_LOAD),
    loadedCategoryID: adminProductSelectors.getLoadedCategoryID(state),
    addProductLS: notifSelectors.getLoadState(state)(LS_ITEM_ADMIN.PRODUCT_ADD),
    getLoadState: notifSelectors.getLoadState(state),
  };
};

const connected = connect(
  mapStateToProps,
  {
    loadProducts: _adminProduct.actions.loadProducts,
    addProduct: _adminProduct.actions.addProduct,
    deleteProduct: _adminProduct.actions.deleteProduct,
    reorderProducts: _adminProduct.actions.reorderProducts,
  },
)(ProductList);

export default connected;

