import "./mainContent.css";

import React from "react";
import { Button, CircularProgress }  from "@mui/material";

import { LOADING_STATE } from "../../utils/stateTypes";
import { getGroupedLoadState } from "../../utils/generalUtils";


/* ======================================== COMPONENT ======================================== */

const MainContent = ({ children, loadErrorLabel="Unable to fetch data. Please check your internet connection and try again", loadErrorButtonLabel="TRY AGAIN",  loadItems=[] }) => {
  React.useEffect(() => {
    for (let loadItem of loadItems) {
      if (loadItem.loadState === LOADING_STATE.NOT_LOADING) {
        loadItem.loadFn();
      }
    }
  }, [loadItems]);

  const reload = React.useCallback(() => {
    for (let loadItem of loadItems) {
      if (loadItem.loadState === LOADING_STATE.ERROR) {
        loadItem.loadFn();
      }
    }
  }, [loadItems]);

  const loadingState = getGroupedLoadState(loadItems.map((loadItem) => loadItem.loadState));

  let innerContent;

  if ((loadingState === LOADING_STATE.NOT_LOADING) || (loadingState === LOADING_STATE.LOADING)) {
    innerContent = (
      <div className={"loading-cont"}>
        <CircularProgress
          size={80}
          color={"secondary"}
        />
        <p>Loading</p>
      </div>
    );
  }
  else if (loadingState === LOADING_STATE.ERROR) {
    innerContent = (
      <React.Fragment>
        <img
          src={"/images/alert.png"}
          className={"load-error-img"}
        />
        <p>{loadErrorLabel}</p>
        <Button
          variant={"contained"}
          color={"primary"}
          disabled={(loadingState === LOADING_STATE.LOADING)}
          onClick={reload}
        >
          {loadErrorButtonLabel}
        </Button>
      </React.Fragment>
    );
  }
  else {
    innerContent = children;
  }

  return (
    <div className={"admin-main-content"}>
      {innerContent}
    </div>
  );
};

export default MainContent;