import { combineReducers } from "redux";

import * as authActionTypes from "./auth/actionTypes";
import _notif from "./notification";
import _home from "./home";
import _auth from "./auth";
import _adminBanner from "./adminBanner";
import _adminCategory from "./adminCategory";
import _adminProduct from "./adminProduct";
import _adminPress from "./adminPress";

const appReducer = combineReducers({
  notif: _notif.reducer,
  home: _home.reducer,

  auth: _auth.reducer,
  adminBanner: _adminBanner.reducer,
  adminCategory: _adminCategory.reducer,
  adminProduct: _adminProduct.reducer,
  adminPress: _adminPress.reducer,
});

const rootReducer = (state, action) => {
  //Reset the entire state on logout
  if (action.type === authActionTypes.LOGOUT) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;