import * as actionType from "./actionTypes";
import { getRequestAuthed, postRequestAuthed, processError, prepPostData } from "../../utils/apiHandlers";
import { LOADING_STATE, LS_ITEM_ADMIN, NOTIF_TYPE } from "../../utils/stateTypes";
import { deleteItemLocal, editItemProp, editItemPropByID, setItemEditing, updateItemLocal } from "../../utils/actionUtils";
import { adminCategorySelectors } from "../rootSelectors";
import _notif from "../notification";
import { removeFromArr } from "../../utils/generalUtils";


export const setActiveType = (type) => (
  {
    type: actionType.SET_ACTIVE_TYPE,
    activeType: type,
  }
);

export const loadCategories = () => (
  (dispatch) => {
    const lsItem = LS_ITEM_ADMIN.CATEGORIES_LOAD;
    dispatch(_notif.actions.setLoadState(lsItem, LOADING_STATE.LOADING));
    getRequestAuthed("/AdminCategory/GetCategories",
      (categories) => {
        const formattedCategory = {
          byID: {},
          allIDs: [],
        };
        
        for (let cat of categories) {
          formattedCategory.byID[cat.id] = cat;
          formattedCategory.allIDs.push(cat.id);
        }

        dispatch({
          type: actionType.RECEIVE_CATEGORIES,
          category: formattedCategory,
        });
        dispatch(_notif.actions.setLoadState(lsItem, LOADING_STATE.LOADED));
      },
      processError(dispatch, _notif, lsItem),
    );
  }
);

export const setCategoryEditing = setItemEditing(actionType.SET_CATEGORY_EDITING);
export const editCategoryProp = editItemProp(actionType.EDIT_CATEGORY_PROP);
export const editCategoryByIdProp = editItemPropByID(actionType.EDIT_CATEGORY_BY_ID_PROP);
const updateCategoryLocal = updateItemLocal(actionType.UPDATE_CATEGORY, "category");
const deleteCategoryLocal = deleteItemLocal(actionType.DELETE_CATEGORY);

export const addCategory = (type) => (
  (dispatch) => {
    let lsItem = LS_ITEM_ADMIN.CATEGORY_ADD;
    dispatch(_notif.actions.setLoadState(lsItem, LOADING_STATE.LOADING));
    postRequestAuthed("/AdminCategory/AddCategory",
      type,
      (cat) => {
        dispatch(updateCategoryLocal(cat, true));
        dispatch(_notif.actions.queueNotification("Category added", NOTIF_TYPE.SUCCESS));
        dispatch(_notif.actions.setLoadState(lsItem, LOADING_STATE.LOADED));
      },
      processError(dispatch, _notif, lsItem),
    );
  }
);

export const saveCategory = () => (
  (dispatch, getState) => {
    const cat = adminCategorySelectors.getCategoryEditing(getState());

    let postData = prepPostData(cat, ["previewImage", "previewVideo", "previewLogo", "headerImage"]);

    //Separate the info section files
    let extraFiles = [];
    if (cat.infoSections) {
      let newInfoSections = [];
      for (let item of cat.infoSections) {
        if (!item.image || !item.body) {
          continue;
        }
        const processedItem = prepPostData(item, ["image"]);
        newInfoSections.push(processedItem.item);
        if (processedItem.newFiles.length > 0) {
          processedItem.newFiles[0].propName = item.id.toString();
        }
        extraFiles = [
          ...extraFiles,
          ...processedItem.newFiles,
        ];
      }
      postData.item.infoSections = newInfoSections;
    }

    let lsItem = LS_ITEM_ADMIN.CATEGORY_SAVE;
    dispatch(_notif.actions.setLoadState(lsItem, LOADING_STATE.LOADING));
    postRequestAuthed("/AdminCategory/SaveCategory",
      {
        item: postData.item,
        newFiles: postData.newFiles,
        extraFiles,
      },
      (cat) => {
        dispatch(updateCategoryLocal(cat));
        dispatch(setCategoryEditing(cat.id));

        dispatch(_notif.actions.queueNotification("Category updated", NOTIF_TYPE.SUCCESS));
        dispatch(_notif.actions.setLoadState(lsItem, LOADING_STATE.LOADED));
      },
      processError(dispatch, _notif, lsItem),
    );
  }
);

export const deleteCategory = (id, onSuccess=null) => (
  (dispatch) => {
    let lsItem = `${LS_ITEM_ADMIN.CATEGORY_DELETE}${id}`;
    dispatch(_notif.actions.setLoadState(lsItem, LOADING_STATE.LOADING));
    postRequestAuthed("/AdminCategory/DeleteCategory",
      id,
      () => {
        dispatch(deleteCategoryLocal(id));

        dispatch(_notif.actions.queueNotification("Category deleted", NOTIF_TYPE.SUCCESS));
        dispatch(_notif.actions.setLoadState(lsItem, LOADING_STATE.LOADED));
        if (onSuccess) {
          onSuccess();
        }
      },
      processError(dispatch, _notif, lsItem),
    );
  }
);

export const reorderCategories = (startIndex, endIndex, type) => (
  (dispatch, getState) => {
    //Get the currently sorted items
    const allIDs = adminCategorySelectors.getCategories(getState()).map((item) => item.id);
    const sortedIDs = adminCategorySelectors.getCategories(getState()).filter((item) => item.type === type).map((item) => item.id);
    let newOrder = [...sortedIDs];
    //Move from the start index to the end index (and get the moved itemID in the process)
    const [itemID] = newOrder.splice(startIndex, 1);
    newOrder.splice(endIndex, 0, itemID);

    //We remove all the catIDs of the current type, and then append the new order (other type's order is unaffected)
    let sortedAllIDs = [...allIDs];
    newOrder.forEach((catID) => {
      sortedAllIDs = removeFromArr(sortedAllIDs, catID);
    });
    sortedAllIDs.push(...newOrder);

    //Save the order locally
    dispatch({
      type: actionType.REORDER_CATEGORIES,
      newOrder: sortedAllIDs,
    });

    const lsItem = `${LS_ITEM_ADMIN.CATEGORY_REORDER}${itemID}`;
    dispatch(_notif.actions.setLoadState(lsItem, LOADING_STATE.LOADING));
    postRequestAuthed("/AdminCategory/ReorderCategories",
      {
        startIndex,
        endIndex,
        parentID: type,
      },
      () => {
        dispatch(_notif.actions.queueNotification("Re-ordered successfully", NOTIF_TYPE.SUCCESS));
        dispatch(_notif.actions.setLoadState(lsItem, LOADING_STATE.LOADED));
      },
      processError(dispatch, _notif, lsItem, () => {
        //Undo the local change if the server request fails
        dispatch({
          type: actionType.REORDER_CATEGORIES,
          newOrder: allIDs,
        });
      }),
    );
  }
);