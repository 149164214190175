import "./bannerCarousel.css";

import React from "react";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

const styles = {
  actionButton: {
    marginTop: 20,
    padding: "10px 30px",
  },
};

const BANNER_INTERVAL = 7000;
const CLICKED_BANNER_INTERVAL = 12000;

const BannerCarousel = ({ banners }) => {

  const [currentBanner, setCurrentBanner] = React.useState(0);
  const bannerUpdateTimeout = React.useRef(null);
  const clickedBanner = React.useRef(false);

  React.useEffect(() => {
    if (banners.length >= 1) {
      bannerUpdateTimeout.current = setTimeout(() => {
        setCurrentBanner((currentBanner + 1) % banners.length);
      }, clickedBanner.current ? CLICKED_BANNER_INTERVAL : BANNER_INTERVAL);
      clickedBanner.current = false;
    }

    return () => {
      clearTimeout(bannerUpdateTimeout.current);
    };
  }, [currentBanner, banners.length]);

  const selectBannerCB = React.useCallback((bannerIndex) => {
    clickedBanner.current = true;
    setCurrentBanner(bannerIndex);
  }, []);


  return (
    <section className={"banners-cont"}>
      <TransitionGroup className={"banners-cont-inner"}>
        <CSSTransition
          key={currentBanner}
          classNames={"fade"}
          timeout={300}
        >
          <React.Fragment>
            {
              banners.map((banner, index) => {
                if (index !== currentBanner) {
                  return null;
                }

                let buttonStyle = styles.actionButton;
                let textStyle = {};
                if (banner.color) {
                  buttonStyle = {
                    ...buttonStyle,
                    backgroundColor: banner.color,
                  };
                  textStyle = {
                    ...textStyle,
                    color: banner.color,
                  };
                }
                return (
                  <div
                    key={banner.id}
                    className={"banner-cont"}
                  >
                    <img
                      src={banner.imageLg}
                      className={"banner-img-lg"}
                    />
                    <img
                      src={banner.imageSm ? banner.imageSm : banner.imageLg}
                      className={"banner-img-sm"}
                    />
                    {
                      (!banner.imageOnly) && (
                        <div className={"banner-text-cont"}>
                          <h2
                            className={"banner-head"}
                            style={textStyle}
                          >
                            {banner.head}
                          </h2>
                          {
                            (banner.subHead) && (
                              <p
                                className={"banner-sub-head"}
                                style={textStyle}
                              >
                                {banner.subHead}
                              </p>
                            )
                          }
                          <Link to={banner.linkUrl}>
                            <Button
                              variant={"contained"}
                              style={buttonStyle}
                              color={"primary"}
                            >
                              {banner.linkLabel}
                            </Button>
                          </Link>
                        </div>
                      )
                    }
                    {
                      (banner.imageOnly) && (
                        <img
                          src={"/images/OG-Logo.png"}
                          className={"banner-sub-logo"}
                        />
                      )
                    }
                  </div>
                );
              })
            }
          </React.Fragment>
        </CSSTransition>
      </TransitionGroup>

      <div className={"banner-select-cont"}>
        {
          banners.map((banner, index) => (
            <div
              key={banner.id}
              className={["banner-select", (index === currentBanner) ? "active" : ""].join(" ")}
              onClick={() => selectBannerCB(index)}
            />
          ))
        }
      </div>
    </section>
  );
};

export default BannerCarousel;