import React from "react";
import { Button, DialogActions, Dialog, DialogContent } from "@mui/material";


/* ======================================== COMPONENT ======================================== */

const WithConfirmDialog = ({ component, onClick, label, outerDivClass=null }) => {
  
  const [isOpen, setIsOpen] = React.useState(false);
  const openDialog = React.useCallback((e) => {
    e.stopPropagation();
    setIsOpen(true);
  }, [setIsOpen]);
  const closeDialog = React.useCallback((e) => {
    e.stopPropagation();
    setIsOpen(false);
  }, [setIsOpen]);

  return (
    <div className={outerDivClass}>
      {React.cloneElement(component, { onClick: openDialog })}
      <Dialog
        open={isOpen}
        onClose={closeDialog}
        disableScrollLock={true}
      >
        <DialogContent>
          {label}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={closeDialog}
            color={"secondary"}
            variant={"contained"}
          >
            Cancel
          </Button>
          <Button
            onClick={(e) => {
              closeDialog(e);
              onClick();
            }}
            color={"primary"}
            variant={"contained"}
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default WithConfirmDialog;