/* General utility functions
*  Can be used anywhere
*/

import { LOADING_STATE } from "./stateTypes";
import { compressImage } from "./imageUtils";
import { updateObject } from "./reducerUtils";

//Reference: https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
export const uniqueID = () => (
  "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    let r = Math.random() * 16 | 0, v = c == "x" ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  })
);

export const uniqueIntID = () => (
  parseInt(Math.random().toString().slice(2, 10))
);

//Adds an element to an array only if the array does not already contain the element
export const addToArrNoDupe = (arr, val) => {
  if (arr.indexOf(val) === -1) {
    return [...arr, val];
  }
  return arr;
};

//Removes the element from the array if it finds it
export const removeFromArr = (arr, val) => {
  let index = arr.indexOf(val);
  if (index !== -1) {
    return [
      ...arr.slice(0, index),
      ...arr.slice(index + 1),
    ];
  }
  return arr;
};

export const months = [
  "January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December",
];
export const weekday = [
  "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday",
];

//Returns a date string in the format: Weekday, DD Month YYYY
export const getFullDate = (date) => {
  return weekday[date.getDay()] + ", " + date.getDate() + " " + months[date.getMonth()] + " " + date.getFullYear();
};

export const getWeekdayFromDate = (date) => {
  return weekday[date.getDay()];
};

//Returns a date string in the format: DD Month YYYY
export const getDateNoWeekday = (date) => {
  return date.getDate() + " " + months[date.getMonth()] + " " + date.getFullYear();
};

//Returns a date string in the format: DD Month YYYY hh:mm
export const getDateAndTime = (date) => {
  let h = date.getHours();
  let m = date.getMinutes();
  if (h < 10) {
    h = "0" + h;
  }
  if (m < 10) {
    m = "0" + m;
  }
  return date.getDate() + " " + months[date.getMonth()] + " " + date.getFullYear() + " " + h + ":" + m;
};

//Transforms number into formatted price (label optional)
export const formatPrice = (num, label = null, currencyLabel="R") => {
  //Remove minus if num is negative
  if (num < 0) {
    num = num * -1;
    currencyLabel = "-" + currencyLabel;
  }

  let x = num.toFixed(2).split(".");
  //x1 is the integer part
  let x1 = x[0];
  let x2 = "";
  
  if (Number(x[1]) !== 0) {
    x2 = "." + x[1]; //Place decimals in x2
  }

  //Add commas for long numbers (every third integer - e.g. R100,000)
  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, "$1" + "," + "$2");
  }
  num = currencyLabel + x1 + x2;
  return label ? [num, label].join(" ") : num;
};

//Will not trigger the callback until it hasn't been called at least 'wait' milliseconds
export const debounce = (callback, wait, debounceStart=null) => {
  let timeoutId = null;
  return (...args) => {
    if ((timeoutId === null) && (debounceStart !== null)) {
      debounceStart();
    }
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      callback.apply(null, args);
      timeoutId = null;
    }, wait);
  };
};

//Groups the load state to return the final load state
export const getGroupedLoadState = (loadStates) => {
  let loadState = LOADING_STATE.LOADED;
  for (let i = 0; i < loadStates.length; i++) {
    if (loadStates[i] === LOADING_STATE.ERROR) {
      loadState = LOADING_STATE.ERROR;
      break;
    }
    else if (loadStates[i] === LOADING_STATE.LOADING) {
      loadState = LOADING_STATE.LOADING;
    }
    else if (loadStates[i] === LOADING_STATE.NOT_LOADING) {
      loadState = LOADING_STATE.LOADING;
    }
  }
  return loadState;
};

//Update prop callback - pre fills some arguments to reduce boilerplate
//and gets the new value from the change event
export const updatePropCB = (callback) => (
  (propToUpdate) => (
    (event) => {
      if (event.target.type === "checkbox") {
        callback(propToUpdate, event.target.checked);
      }
      else if (event.target.type === "number") {
        let val = (event.target.value !== "") ? Math.round(parseFloat(event.target.value) * 100) / 100 : null;
        callback(
          propToUpdate,
          val,
        );
      }
      else {
        callback(propToUpdate, (event.target.value !== "") ? event.target.value : null);
      }
    }
  )
);

export const updateImagePropCB = (callback) => (
  (propToUpdate, maxWidth=1500) => (
    (fileData, fileName, imgProcessSuccessCB) => (
      compressImage(
        fileData,
        maxWidth,
        (compressedImg) => {
          //Convert the blob to base64 so we can send it as json with the rest of the class data
          let reader = new FileReader();
          reader.readAsDataURL(compressedImg);
          reader.onloadend = () => {
            let base64data = reader.result;
            callback(propToUpdate, `[FILE](${fileName})${base64data}`);
            imgProcessSuccessCB();
          };
        },
        () => alert("Something went wrong while processing the image. Please contact a support admin to assist."),
        fileData.type,
      )
    )
  )
);

export const updateFilePropCB = (callback) => (
  (propToUpdate) => (
    (fileData, fileName, fileProcessSuccessCB) => {
      let reader = new FileReader();
      reader.readAsDataURL(fileData);
      reader.onloadend = () => {
        let base64data = reader.result;
        callback(propToUpdate, `[FILE](${fileName})${base64data}`);
        fileProcessSuccessCB();
      };
    }
  )
);

export const updatePropStraightValueCB = (callback) => (
  (propToUpdate) => (
    (newVal) => {
      callback(propToUpdate, (newVal !== "") ? newVal : null);
    }
  )
);

//Checks if a link is a hosted OV file or blob, and returns the file name if it is (or null if it isn't)
export const getBlobFileName = (value, propName, item) => {
  if (!value) {
    return null;
  }
  if (value.indexOf("data:") === 0) {
    return item[`${propName}FileName`];
  }
  if (value.indexOf("/greigfiles/") >= 0) {
    return value.split("/").pop();
  }
  return null;
};


//Update helper
export const editItemPropPotentialFile = (item, propToUpdate, newValue) => {
  if ((newValue !== null) && (newValue.indexOf) && (newValue.indexOf("[FILE]") === 0)) {
    //New file, need to create a fileName prop as well
    let fileName = newValue.match(/\[FILE\]\((.+)\)/)[1];
    let base64 = newValue.match(/\[FILE\]\(.+\)(.+)/)[1];
    return updateObject(item, {
      [propToUpdate]: base64,
      [`${propToUpdate}FileName`]: fileName,
    });
  }

  //Not a file, normal update
  return updateObject(item, {
    [propToUpdate]: newValue,
  });
};