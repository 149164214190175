import "./product.css";

import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

import { homeSelectors, notifSelectors } from "../../logic/rootSelectors";
import _home from "../../logic/home";
import { CATEGORY_TYPE, LS_ITEM, PAGE_ROUTES } from "../../utils/stateTypes";
import MainContent from "../general/MainContent";
import Breadcrumbs from "../sections/Breadcrumbs";

const HEADER_ICON = {
  [CATEGORY_TYPE.TIMEPIECES]: "/images/icon-watch.png",
  [CATEGORY_TYPE.JEWELLERY]: "/images/icon-jewel.png",
};

const styles = {
  enquireButton: {
    width: "100%",
    maxWidth: 300,
    padding: "10px 20px",
    backgroundColor: "#83a677",
    margin: "20px auto",
  },
};


const Product = ({
  product, getLoadState,
  getProduct, setContactFieldsProduct,
}) => {
  const { productUrl } = useParams();
  const navigate = useNavigate();
  const productLS = getLoadState(`${LS_ITEM.PRODUCT_LOAD}${productUrl}`);

  const loadProductCB = React.useCallback(() => {
    const navigateTo404 = () => navigate(PAGE_ROUTES.NOT_FOUND);
    getProduct(productUrl, navigateTo404);
  }, [productUrl, getProduct]);

  React.useEffect(() => {
    loadProductCB();
  }, [productUrl]);

  //Set up the image gallery
  const [activeImg, setActiveImg] = React.useState(0);
  let activeImgSrc = "/images/placeholder-image.png";
  if (product && (product.images.length > 0)) {
    activeImgSrc = product.images[activeImg].image;
  }
  

  return (
    <MainContent loadItems={[{ loadState: productLS, loadFn: () => {} }]}>
      <Helmet>
        <title>Charles Greig | {product ? product.name : "Loading..."}</title>
      </Helmet>

      {
        (product) && (
          <React.Fragment>
            <Breadcrumbs
              crumbs={[
                { text: "Home", link: "/" },
                { text: (product.category.type === CATEGORY_TYPE.TIMEPIECES) ? "Timepieces" : "Jewellery", link: (product.category.type === CATEGORY_TYPE.TIMEPIECES) ? PAGE_ROUTES.TIMEPIECES : PAGE_ROUTES.JEWELLERY },
                { text: product.category.name, link: PAGE_ROUTES.CATEGORY.replace(":categoryUrl", product.category.url) },
                { text: product.name, link: null },
              ]}
            />

            <section className={"product-section"}>
              <div className={"product-header"}>
                <img
                  className={"product-header-icon"}
                  src={HEADER_ICON[product.category.type]}
                />
                <div className={"product-header-divider"} />
                <div className={"product-header-text"}>
                  <h1 className={"product-header-name"}>{product.name}</h1>
                  <h2 className={"product-header-model"}>{product.model}</h2>
                </div>
              </div>

              <div className={"product-content"}>
                <div className={"product-gallery"}>
                  <img
                    className={"product-active-img"}
                    src={activeImgSrc}
                  />
                  {
                    (product.images.length > 1) && (
                      <div className={"product-gallery-thumbnails"}>
                        {
                          product.images.map((img, index) => (
                            <div
                              key={index}
                              className={"product-gallery-thumbnail"}
                            >
                              <img
                                src={img.image}
                                onClick={() => setActiveImg(index)}
                              />
                            </div>
                          ))
                        }
                      </div>
                    )
                  }
                </div>

                <div className={"product-info"}>
                  <div className={"product-info-inner"}>
                    {
                      (product.infoSections.length === 0) && (
                        <p><i>No info</i></p>
                      )
                    }
                    {
                      product.infoSections.map((section) => (
                        <div key={section.id}>
                          <h3>{section.head}</h3>
                          <div dangerouslySetInnerHTML={{ __html: section.body }} />
                        </div>
                      ))
                    }
                  </div>

                  <Link to={PAGE_ROUTES.CONTACT}>
                    <Button
                      style={styles.enquireButton}
                      variant={"contained"}
                      onClick={() => setContactFieldsProduct(product.name)}
                    >
                      Enquire now
                    </Button>
                  </Link>
                </div>
              </div>
            </section>
          </React.Fragment>
        )
      }
    </MainContent>
  );
};

const mapStateToProps = (state) => {
  return {
    product: homeSelectors.getProduct(state),
    getLoadState: notifSelectors.getLoadState(state),
  };
};

const connected = connect(
  mapStateToProps,
  {
    getProduct: _home.actions.getProduct,
    setContactFieldsProduct: _home.actions.setContactFieldsProduct,
  },
)(Product);

export default connected;