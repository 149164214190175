import * as actionType from "./actionTypes";
import { updateObject, createReducer } from "../../utils/reducerUtils";
import { LOADING_STATE } from "../../utils/stateTypes";

/* State shape
{
  notificationQueue: [
    {
      id,
      text: string
    }
  ]
}
*/
const initialState = {
  notificationQueue: [],
  loadStates: {},
};

const queueNotification = (state, action) => (
  updateObject(state, {
    notificationQueue: [
      ...state.notificationQueue,
      action.notification,
    ],
  })
);

const removeNotification = (state) => (
  updateObject(state, {
    notificationQueue: state.notificationQueue.slice(1),
  })
);

const setLoadState = (state, action) => (
  updateObject(state, {
    loadStates: updateObject(state.loadStates, {
      [action.loadName]: action.loadState,
    }),
  })
);

const resetLoadingState = (state, action) => {
  let prevLoadingStates  = { ...state.loadStates };
  let updatedState = updateObject(state, {
    loadStates: {},
  });

  //Restore the load states that in the exceptions list
  for (let lsItem of action.lsExceptions) {
    updatedState = updateObject(updatedState, {
      loadStates: updateObject(updatedState.loadStates, {
        [lsItem]: prevLoadingStates[lsItem],
      }),
    });
  }

  return updatedState;
};

const reducer = createReducer(initialState, {
  [actionType.QUEUE_NOTIFICATION]: queueNotification,
  [actionType.REMOVE_NOTIFICATION]: removeNotification,

  [actionType.SET_LOAD_STATE]: setLoadState,
  [actionType.RESET_LOADING_STATE]: resetLoadingState,
});

export default reducer;

// SELECTORS

export const getLatestNotification = (state) => {
  if (state.notificationQueue.length) {
    return state.notificationQueue[0];
  }
  return null;
};

export const getLoadState = (state) => (
  (loadName) => (
    (state.loadStates[loadName] !== undefined) ? state.loadStates[loadName] : LOADING_STATE.NOT_LOADING
  )
);