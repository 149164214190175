import "./editForm.css";
import "easymde/dist/easymde.min.css";

import React from "react";
import { Switch, TextField, FormGroup, FormControlLabel, Button, FormControl, MenuItem, InputLabel, Select, IconButton } from "@mui/material";
import { Delete, Image, Add, InsertLink, ArrowUpward, ArrowDownward } from "@mui/icons-material";
import SimpleMDE from "react-simplemde-editor";

import { updatePropCB, updateImagePropCB, updateFilePropCB, updatePropStraightValueCB, uniqueIntID, getBlobFileName, editItemPropPotentialFile } from "../../utils/generalUtils";
import { FORM_PROP_TYPE, LOADING_STATE } from "../../utils/stateTypes";
import UploadFileDialog from "./UploadFileDialog";
import SaveButton from "./SaveButton";
import EditFormColorInput from "./EditFormColorInput";


const styles = {
  input: {
    width: "80%",
    margin: "10px auto",
    textAlign: "left",
  },
  imageButton: {
    width: "auto",
    margin: "0 5px 20px 5px",
  },
  subListDelete: {
    position: "absolute",
    top: 5,
    right: 5,
  },
  subListUp: {
    position: "absolute",
    top: 5,
    right: 70,
  },
  subListDown: {
    position: "absolute",
    top: 5,
    right: 35,
  },
};

const mdeOptions = {
  spellChecker: true,
  hideIcons: ["preview", "guide", "side-by-side", "fullscreen", "image"],
  maxHeight: "250px",
  previewImagesInEditor: true,
};


const EditForm = ({ heading=null, item, propsToEdit, updatePropBase, savePropEdit=null, saveButtonLabel, loadingState, replaceFormWithDiv=false, disabled=false }) => {

  const updateProp = updatePropCB(updatePropBase);
  const updatePropStraightValue = updatePropStraightValueCB(updatePropBase);
  const updateImageProp = updateImagePropCB(updatePropBase);
  const updateFileProp = updateFilePropCB(updatePropBase);

  //File dialog state
  let hasFileProp = false;

  const [editingFileProp, setEditingFileProp] = React.useState(null);
  const closeFileDiag = React.useCallback(() => {
    setEditingFileProp(null);
  }, [setEditingFileProp]);

  const [droppedFile, setDroppedFile] = React.useState(null);
  const [dragOver, setDragOver] = React.useState(null);
  
  //Set up the input fields
  let inputs = [];
  propsToEdit.forEach((propToEdit, index) => {
    if (propToEdit.type === FORM_PROP_TYPE.TEXT) {
      /* ----------- TEXT INPUT ----------- */
      inputs.push(
        <TextField
          key={propToEdit.prop}
          type={"text"}
          style={styles.input}
          label={propToEdit.label}
          value={((item[propToEdit.prop] !== null) && (item[propToEdit.prop] !== undefined)) ? item[propToEdit.prop] : ""}
          onChange={updateProp(propToEdit.prop)}
          required={(!propToEdit.isOptional)}
        />,
      );
    }
    else if (propToEdit.type === FORM_PROP_TYPE.TEXTAREA) {
      /* ----------- TEXTAREA INPUT ----------- */
      inputs.push(
        <TextField
          key={propToEdit.prop}
          type={"text"}
          style={styles.input}
          label={propToEdit.label}
          value={((item[propToEdit.prop] !== null) && (item[propToEdit.prop] !== undefined)) ? item[propToEdit.prop] : ""}
          onChange={updateProp(propToEdit.prop)}
          required={(!propToEdit.isOptional)}
          multiline
        />,
      );
    }
    else if (propToEdit.type === FORM_PROP_TYPE.NUMBER) {
      /* ----------- NUMBER INPUT ----------- */
      inputs.push(
        <TextField
          key={propToEdit.prop}
          type={"number"}
          style={styles.input}
          label={propToEdit.label}
          value={((item[propToEdit.prop] !== null) && (item[propToEdit.prop] !== undefined)) ? item[propToEdit.prop] : ""}
          onChange={updateProp(propToEdit.prop)}
          onWheel={(e) => e.target.blur()}
          required={(!propToEdit.isOptional)}
        />,
      );
    }
    else if (propToEdit.type === FORM_PROP_TYPE.CHECKBOX) {
      /* ----------- CHECKBOX INPUT ----------- */
      inputs.push(
        <FormControlLabel
          key={propToEdit.prop}
          style={styles.input}
          control={
            <Switch
              checked={(item[propToEdit.prop] === true)}
              color={"primary"}
              onChange={updateProp(propToEdit.prop)}
            />
          }
          label={propToEdit.label}
        />,
      );
    }
    else if (propToEdit.type === FORM_PROP_TYPE.MARKDOWN) {
      /* ----------- MARKDOWN INPUT ----------- */
      inputs.push(
        <FormControl
          key={propToEdit.prop} 
          style={styles.input}
        >
          <p className={"custom-input-edit-head"}>{propToEdit.label}{(!propToEdit.isOptional) ? " *" : ""}</p>
          <div className={"edit-form-field-input-cont"}>
            <SimpleMDE
              value={((item[propToEdit.prop] !== null) && (item[propToEdit.prop] !== undefined)) ? item[propToEdit.prop] : ""}
              onChange={updatePropStraightValue(propToEdit.prop)}
              options={mdeOptions}
              className={"markdown-editor"}
            />
          </div>
        </FormControl>,
      );
    }
    else if ((propToEdit.type === FORM_PROP_TYPE.IMAGE) || (propToEdit.type === FORM_PROP_TYPE.VIDEO)) {
      /* ----------- IMAGE / VIDEO INPUT ----------- */
      hasFileProp = true;
      const isImg = (propToEdit.type === FORM_PROP_TYPE.IMAGE);
      let propLabel = (isImg) ? "Image" : "Video"; 
      let imgSrc = `/images/admin/preview-placeholder-${propLabel.toLowerCase()}.jpg`;
      let isPlaceholder = true;
      if ((item[propToEdit.prop] !== null) && (item[propToEdit.prop] !== undefined) && (item[propToEdit.prop] !== "")) {
        isPlaceholder = false;
        imgSrc = item[propToEdit.prop];
      }
      inputs.push(
        <div
          key={propToEdit.prop}
          className={"edit-image-cont"}
        >
          <p className={"img-edit-head"}>{propToEdit.label}{(!propToEdit.isOptional) ? " *" : ""}</p>
          <div
            className={["upload-image-preview-cont", (dragOver === propToEdit.prop) ? "drag-over" : ""].join(" ")}
            onDragEnter={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setDragOver(propToEdit.prop);
            }}
            onDragOver={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onDragLeave={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setDragOver(null);
            }}
            onDrop={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setDragOver(null);
              setDroppedFile(e.dataTransfer.files[0]);
              setEditingFileProp(propToEdit);
            }}
          >
            {
              (isImg || isPlaceholder) ? (
                <img
                  className={"standard-box upload-image-preview"}
                  src={imgSrc}
                />
              ) : (
                <video
                  className={"standard-box upload-image-preview"}
                  controls={true}
                >
                  <source src={imgSrc} />
                </video>
              )
            }
            
            <p className={"upload-img-drop-text"}>DROP {propLabel.toUpperCase()}<br />TO UPDATE</p>
          </div>
          <div className={"img-edit-buttons-cont"}>
            <Button
              variant={"contained"}
              color={"secondary"}
              style={styles.imageButton}
              startIcon={<Image />}
              onClick={() => {
                setDroppedFile(null);
                setEditingFileProp(propToEdit);
              }}
            >
              Update {propLabel}
            </Button>
            {
              (propToEdit.isOptional) && (imgSrc === item[propToEdit.prop]) ? (
                <Button
                  variant={"contained"}
                  color={"secondary"}
                  style={styles.imageButton}
                  startIcon={<Delete />}
                  onClick={() => updatePropBase(propToEdit.prop, null)}
                >
                  Remove {propLabel}
                </Button>
              ) : null
            }
          </div>
        </div>,
      );
    }
    else if (propToEdit.type === FORM_PROP_TYPE.LINK_OR_FILE) {
      hasFileProp = true;
      const customFileName = getBlobFileName(item[propToEdit.prop], propToEdit.prop, item);
      let textValue = customFileName ? customFileName : "";
      if (textValue === "") {
        textValue = ((item[propToEdit.prop] !== null) && (item[propToEdit.prop] !== undefined)) ? item[propToEdit.prop] : "";
      }
      /* ----------- LINK OR FILE INPUT ----------- */
      inputs.push(
        <React.Fragment key={propToEdit.prop}>
          <div
            className={["file-drop-cont", (dragOver === propToEdit.prop) ? "drag-over" : ""].join(" ")}
            onDragEnter={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setDragOver(propToEdit.prop);
            }}
            onDragOver={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onDragLeave={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setDragOver(null);
            }}
            onDrop={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setDragOver(null);
              setDroppedFile(e.dataTransfer.files[0]);
              setEditingFileProp(propToEdit);
            }}
          >
            <TextField
              type={"text"}
              style={styles.input}
              label={propToEdit.label}
              value={textValue}
              onChange={updateProp(propToEdit.prop)}
              required={(!propToEdit.isOptional)}
              disabled={(customFileName !== null)}
            />
            <p className={"file-drop-text"}>DROP FILE TO UPDATE</p>
          </div>
          <div className={"link-edit-buttons"}>
            <Button
              variant={"contained"}
              color={"secondary"}
              style={styles.imageButton}
              startIcon={<InsertLink />}
              onClick={() => setEditingFileProp(propToEdit)}
            >
              Upload file
            </Button>
            {
              (customFileName !== null) ? (
                <Button
                  variant={"contained"}
                  color={"secondary"}
                  style={styles.imageButton}
                  startIcon={<Delete />}
                  onClick={() => updatePropBase(propToEdit.prop, null)}
                >
                  Remove file
                </Button>
                
              ) : null
            }
          </div>
        </React.Fragment>,
      );
    }
    else if (propToEdit.type === FORM_PROP_TYPE.COLOR) {
      /* ----------- COLOR INPUT ----------- */
      inputs.push(
        <EditFormColorInput
          key={propToEdit.prop}
          item={item}
          propToEdit={propToEdit}
          updatePropStraightValue={updatePropStraightValue}
        />,
      );
    }
    else if (propToEdit.type === FORM_PROP_TYPE.SELECT) {
      /* ----------- SELECT INPUT ----------- */
      inputs.push(
        <FormControl
          style={styles.input}
          key={propToEdit.prop}
        >
          <InputLabel>{propToEdit.label}</InputLabel>
          <Select
            value={((item[propToEdit.prop] !== null) && (item[propToEdit.prop] !== undefined)) ? item[propToEdit.prop] : ""}
            label={propToEdit.label}
            onChange={updateProp(propToEdit.prop)}
            MenuProps={{ disableScrollLock: true }}
          >
            {
              propToEdit.selectOptions.map((selItem) => {
                let key = selItem;
                let value = selItem;
                let label = selItem;
                if (selItem.value !== undefined) {
                  key = selItem.value;
                  value = selItem.value;
                  label = selItem.label;
                }
                return (
                  <MenuItem
                    key={key}
                    value={value}
                  >{label}</MenuItem>
                );
              })
            }
          </Select>
        </FormControl>,
      );
    }
    else if (propToEdit.type === FORM_PROP_TYPE.SUBLIST) {
      /* ----------- SUB LIST ----------- */
      //For the sub list we need to create a new update functions for add, remove, edit and then create a new EditForm with the new update function
      const addSubItemCB = () => {
        updatePropBase(propToEdit.prop, [
          ...item[propToEdit.prop],
          { id: uniqueIntID() },
        ]);
      };
      const removeSubItemCB = (idToRemove) => {
        updatePropBase(propToEdit.prop, item[propToEdit.prop].filter((subItem) => (idToRemove !== subItem.id)));
      };
      const editSubItemCB = (idToUpdate) => (
        (propToUpdate, newValue) => {
          updatePropBase(propToEdit.prop, item[propToEdit.prop].map((subItem) => {
            if (subItem.id === idToUpdate) {
              return editItemPropPotentialFile(subItem, propToUpdate, newValue);
            }
            return subItem;
          }));
        });

      inputs.push(
        <div
          className={"edit-sub-list-cont"}
          key={`sublist-${index}`}
        >
          <p className={"custom-input-edit-head"}>{propToEdit.label}{(!propToEdit.isOptional) ? " *" : ""}</p>
          {
            item[propToEdit.prop].map((subItem, index) => (
              <div
                className={"edit-sub-list-item relative standard-box"}
                key={subItem.id}
              >
                <EditForm
                  item={subItem}
                  loadingState={LOADING_STATE.NOT_LOADING}
                  propsToEdit={propToEdit.subProps}
                  updatePropBase={editSubItemCB(subItem.id)}
                  replaceFormWithDiv={true}
                />
                <IconButton
                  onClick={() => removeSubItemCB(subItem.id)}
                  style={styles.subListDelete}
                  size={"small"}
                >
                  <Delete />
                </IconButton>
                {
                  (propToEdit.reorderCB) && (
                    <React.Fragment>
                      <IconButton
                        onClick={() => propToEdit.reorderCB(subItem.id, index - 1)}
                        style={styles.subListUp}
                        size={"small"}
                        disabled={(index === 0)}
                      >
                        <ArrowUpward />
                      </IconButton>
                      <IconButton
                        onClick={() => propToEdit.reorderCB(subItem.id, index + 1)}
                        style={styles.subListDown}
                        size={"small"}
                        disabled={(index === (item[propToEdit.prop].length - 1))}
                      >
                        <ArrowDownward />
                      </IconButton>
                    </React.Fragment>
                  )
                }
              </div>
            ))
          }
          <Button
            variant={"contained"}
            color={"secondary"}
            style={styles.imageButton}
            startIcon={<Add />}
            onClick={addSubItemCB}
          >
            Add New
          </Button>
        </div>,
      );
    }
    else if (propToEdit.type === FORM_PROP_TYPE.CUSTOM) {
      /* ----------- CUSTOM ----------- */
      //Handled by custom code
      inputs.push(
        <div
          className={"edit-custom-cont"}
          key={`custom-${index}`}
        >
          <p className={"custom-input-edit-head"}>{propToEdit.label}{(!propToEdit.isOptional) ? " *" : ""}</p>
          {propToEdit.component}
        </div>,
      );
    }
  });

  //Set up the file upload dialog props
  const fileDiagOpen = (editingFileProp !== null);
  let imgDiagAspect = null;
  let fileDiagUpdateFileProp = null;
  let fileAccept = null;
  if (fileDiagOpen) {
    //Selecting an IMAGE for upload
    imgDiagAspect = editingFileProp.aspect;
    fileDiagUpdateFileProp = updateImageProp(editingFileProp.prop, editingFileProp.maxWidth);
    fileAccept = "image/*";
    if (editingFileProp.type === FORM_PROP_TYPE.VIDEO) {
      //Selecting a VIDEO for upload
      fileDiagUpdateFileProp = updateFileProp(editingFileProp.prop);
      fileAccept = "video/*";
    }
    else if (editingFileProp.type === FORM_PROP_TYPE.LINK_OR_FILE) {
      //Selecting a FILE for upload
      fileDiagUpdateFileProp = updateFileProp(editingFileProp.prop);
      fileAccept = "*/*";
    }
  }

  const FormTag = (replaceFormWithDiv) ? "div" : "form";

  return (
    <FormTag
      className={"edit-form"}
      onSubmit={(event) => {
        event.preventDefault();
        savePropEdit();
      }}
    >
      {
        (heading !== null) ? (
          <React.Fragment>
            <h3>{heading}</h3> 
            {
              (item.id) ? (
                <h4>ID: {item.id}</h4>
              ) : null
            }
          </React.Fragment> 
        ) : null
      }

      <FormGroup>
        {inputs}
      </FormGroup>

      {/* Save button */}
      {
        (savePropEdit !== null) ? (
          <SaveButton
            loadingState={loadingState}
            fixedFormPos={true}
            label={saveButtonLabel}
            disabled={disabled}
          />
        ) : null
      }
      

      {/* Image dialog */}
      {
        (hasFileProp) ? (
          <UploadFileDialog
            open={fileDiagOpen}
            closeDiag={closeFileDiag}
            aspect={imgDiagAspect}
            editFileProp={fileDiagUpdateFileProp}
            droppedFile={droppedFile}
            fileAccept={fileAccept}
          />
        ) : null
      }
    </FormTag>
  );
};

export default EditForm;