/* eslint-disable react-hooks/exhaustive-deps */
import "./css/normalize.css"; //CSS reset
import "./css/site.css";

import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import { PAGE_ROUTES } from "../utils/stateTypes";
import Page404 from "./home/404";
import ScrollToTop from "./general/ScrollToTop";
import ConsentBanner from "./consent/ConsentBanner";
import TopNav from "./topNav/TopNav";
import Footer from "./home/Footer";
import Home from "./home/Home";
import Search from "./home/Search";
import TimepiecesHome from "./category/TimepiecesHome";
import JewelleryHome from "./category/JewelleryHome";
import Category from "./category/Category";
import Product from "./product/Product";
import Contact from "./aboutPages/Contact";
import Privacy from "./aboutPages/Privacy";
import Group from "./aboutPages/Group";
import Legacy from "./aboutPages/Legacy";
import Partners from "./aboutPages/Partners";
import Team from "./aboutPages/Team";
import PressHome from "./press/PressHome";

const App = () => {
  let location = useLocation();

  return (
    <main>
      <ScrollToTop />
      <ConsentBanner />
      <TopNav />
      <TransitionGroup>
        <CSSTransition
          key={location.pathname}
          classNames={"fade"}
          timeout={300}
        >
          <Routes location={location}>
            <Route
              exact
              path={PAGE_ROUTES.HOME}
              element={<Home />}
            />
            <Route
              exact
              path={PAGE_ROUTES.SEARCH}
              element={<Search />}
            />
            <Route
              exact
              path={PAGE_ROUTES.TIMEPIECES}
              element={<TimepiecesHome />}
            />
            <Route
              exact
              path={PAGE_ROUTES.JEWELLERY}
              element={<JewelleryHome />}
            />
            <Route
              exact
              path={PAGE_ROUTES.CATEGORY}
              element={<Category />}
            />
            <Route
              exact
              path={PAGE_ROUTES.PRODUCT}
              element={<Product />}
            />
            <Route
              exact
              path={PAGE_ROUTES.CONTACT}
              element={<Contact />}
            />
            <Route
              exact
              path={PAGE_ROUTES.PRIVACY_POLICY}
              element={<Privacy />}
            />
            <Route
              exact
              path={PAGE_ROUTES.GROUP}
              element={<Group />}
            />
            <Route
              exact
              path={PAGE_ROUTES.LEGACY}
              element={<Legacy />}
            />
            <Route
              exact
              path={PAGE_ROUTES.PARTNERS}
              element={<Partners />}
            />
            <Route
              exact
              path={PAGE_ROUTES.TEAM}
              element={<Team />}
            />
            <Route
              exact
              path={PAGE_ROUTES.PRESS}
              element={<PressHome />}
            />

            <Route
              exact
              path={PAGE_ROUTES.NOT_FOUND}
              element={<Page404 />}
            />
            <Route
              path={"*"}
              element={<Page404 />}
            />
          </Routes>
        </CSSTransition>
      </TransitionGroup>
      <Footer />
    </main>
  );
};

export default App;