import "./productBlock.css";

import React from "react";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

import { PAGE_ROUTES } from "../../utils/stateTypes";

const styles = {
  actionButton: {
    marginTop: 10,
    padding: "10px 30px",
  },
};


const ProductBlock = ({ product }) => {
  const videoRef = React.useRef(null);

  return (
    <div
      className={"product-block"}
      onMouseEnter={() => {
        if (videoRef.current) {
          videoRef.current.pause();
        }
      }}
      onMouseLeave={() => {
        if (videoRef.current) {
          videoRef.current.play();
        }
      }}
    >
      {
        (product.previewVideo) ? (
          <video
            src={product.previewVideo}
            className={"product-block-bg"}
            autoPlay
            loop
            muted
            ref={videoRef}
          />
        ) : (
          <img
            src={product.previewImage}
            className={"product-block-bg"}
          />
        )
      }

      <Link to={PAGE_ROUTES.PRODUCT.replace(":productUrl", product.url)}>
        <div className={"product-block-overlay"}>
          <p className={"product-block-name"}>{product.name}</p>
          <p className={"product-block-model"}>{product.model}</p>
          <Button
            variant={"contained"}
            color={"primary"}
            style={styles.actionButton}
          >
            Explore
          </Button>
        </div>
      </Link>
    </div>
  );
};

export default ProductBlock;