import "./team.css";

import React from "react";
import { Helmet } from "react-helmet";

import MainContent from "../general/MainContent";
import BreadCrumbs from "../sections/Breadcrumbs";
import HeadBanner from "../sections/HeadBanner";
import TeamBlock from "./TeamBlock";


const Team = () => {
  return (
    <MainContent>
      <Helmet>
        <title>Charles Greig | Meet the team</title>
      </Helmet>
      
      <HeadBanner
        imageLg={"/images/about/team-banner.jpg"}
        headPreGreen={"Meet"}
        headWhite={"the Team"}
        buttonLabel={"Explore"}
      />

      <BreadCrumbs
        crumbs={[
          { text: "Home", link: "/" },
          { text: "Meet the team", link: "" },
        ]}
      />

      <section className={"about-section standard-width"}>
        <p className={"about-outlined"}>
          For over a century, our family business has been driven by a passion for excellence, innovation and customer service. 
          Our team of talented and dedicated jewellers is joined by our excellent sales and management staff, who are committed
          to providing a memorable experience for every one of our customers.
        </p>
      </section>

      <section className={"team-section standard-width"}>
        <TeamBlock
          firstName={"Christopher"}
          surname={"Greig"}
          position={"Owner"}
          image={"/images/team/Christopher Greig - Owner.jpg"}
        />
        <TeamBlock
          firstName={"Belinda"}
          surname={"Phillips"}
          position={"Manager"}
          image={"/images/team/Belinda Phillips - Manager.jpg"}
        />
        <TeamBlock
          firstName={"Sharon"}
          surname={"Botha"}
          position={"Finance"}
          image={"/images/team/Sharon Botha - Finance.jpg"}
        />
        <TeamBlock
          firstName={"Deborah"}
          surname={"Mitchell-Morel"}
          position={"Back Office"}
          image={"/images/team/Deborah Mitchell-Morel - Back Office.jpg"}
        />
        <TeamBlock
          firstName={"Joel"}
          surname={"Mogotsi"}
          position={"Repairs"}
          image={"/images/team/Joel Mogotsi - Repairs.jpg"}
        />
        <TeamBlock
          firstName={"Brigitte"}
          surname={"Nielson"}
          position={"Geologist"}
          image={"/images/team/Brigitte Nielson - Geologist.jpg"}
        />
        <TeamBlock
          firstName={"Dawid"}
          surname={"Oosthuizen"}
          position={"Watch Sales"}
          image={"/images/team/Dawid Oosthuizen - Watch Sales.jpg"}
        />
        <TeamBlock
          firstName={"Stephen"}
          surname={"Grabe"}
          position={"Watch Sales"}
          image={"/images/team/Stephen Grabe - Watch Sales.jpg"}
        />
        <TeamBlock
          firstName={"Thamba"}
          surname={"Shabangu"}
          position={"Watch Sales"}
          image={"/images/team/Thamba Shabangu - Watch Sales.jpg"}
        />
        <TeamBlock
          firstName={"Gerhardt"}
          surname={"Leuchtenberger"}
          position={"Jewellery Sales"}
          image={"/images/team/Gerhardt Leuchtenberger - Jewellery Sales.jpg"}
        />
        <TeamBlock
          firstName={"Sophia"}
          surname={"Haratsidis"}
          position={"Jewellery Sales"}
          image={"/images/team/Sophia Haratsidis - Jewellery Sales.jpg"}
        />
        <TeamBlock
          firstName={"Vivienne"}
          surname={"Miller"}
          position={"Jewellery Sales"}
          image={"/images/team/Vivienne Miller - Jewellery Sales.jpg"}
        />
        <TeamBlock
          firstName={"Simpson"}
          surname={"Matamela"}
          position={"Security"}
          image={"/images/team/Simpson Matamela - Security.jpg"}
        />
        <TeamBlock
          firstName={"Ronald"}
          position={"Security"}
          image={"/images/team/Ronald - Security.jpg"}
        />
        <TeamBlock
          firstName={"Gripheths"}
          position={"Security"}
          image={"/images/team/Gripheths - Security.jpg"}
        />
      </section>
    </MainContent>
  );
};

export default Team;