import Compressor from "compressorjs";

// Takes an uploaded file and reads it from the file system
export const readFile = (file) => (
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  })
);

const createImage = (url) => (
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.src = url;
  })
);

export const submitCroppedImg = (imageSrc, fileName, pixelCrop, callback, imgProcessSuccessCB) => {
  createImage(imageSrc).then((image) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const maxSize = Math.max(image.width, image.height);
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea;
    canvas.height = safeArea;

    // translate canvas context to a central location on image to allow rotating around the center.
    ctx.translate(safeArea / 2, safeArea / 2);
    ctx.translate(-safeArea / 2, -safeArea / 2);

    // draw rotated image and store data.
    ctx.drawImage(
      image,
      safeArea / 2 - image.width * 0.5,
      safeArea / 2 - image.height * 0.5,
    );
    const data = ctx.getImageData(0, 0, safeArea, safeArea);

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
      data,
      Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
      Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y),
    );

    // As Base64 string
    // return canvas.toDataURL('image/jpeg');

    let fileType = "image/jpeg";
    if (imageSrc.includes("data:image/png")) {
      fileType = "image/png";
    }

    // As a blob
    canvas.toBlob((file) => {
      callback(file, fileName, imgProcessSuccessCB);
    }, fileType);
  });
};

export const submitFileUnprocessed = (fileSrc, fileName, callback, fileProcessSuccessCB) => {
  fetch(fileSrc)
    .then((res) => {
      return res.blob();
    })
    .then((fileBlob) => {
      callback(fileBlob, fileName, fileProcessSuccessCB);
    });
};

//Compress image helper
export const compressImage = (imageFile, maxWidth, success, error, imgType="image/jpeg") => {
  new Compressor(imageFile, {
    quality: 0.8,
    maxWidth,
    mimeType: imgType,
    success,
    error,
  });
};