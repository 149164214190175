
export const setItemEditing = (type) => (
  (id=null) => (
    {
      type,
      id,
    }
  )
);

export const editItemProp = (type) => (
  (propToEdit, newValue) => (
    {
      type,
      propToEdit,
      newValue,
    }
  )
);

export const editItemPropByID = (type) => (
  (id, propToEdit, newValue) => (
    {
      type,
      id,
      propToEdit,
      newValue,
    }
  )
);

export const updateItemLocal = (type, itemName) => (
  (item, isAddingNew) => (
    {
      type,
      [itemName]: item,
      isAddingNew,
    }
  )
);

export const deleteItemLocal = (type) => (
  (id) => (
    {
      type,
      id,
    }
  )
);