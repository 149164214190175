import "./categoryBlock.css";

import React from "react";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

import { PAGE_ROUTES } from "../../utils/stateTypes";

const styles = {
  actionButton: {
    marginTop: 10,
    padding: "10px 30px",
  },
};


const CategoryBlock = ({ category }) => {
  const videoRef = React.useRef(null);

  return (
    <div
      className={"category-block"}
      onMouseEnter={() => {
        if (videoRef.current) {
          videoRef.current.pause();
        }
      }}
      onMouseLeave={() => {
        if (videoRef.current) {
          videoRef.current.play();
        }
      }}
    >
      {
        (category.previewVideo) ? (
          <video
            src={category.previewVideo}
            className={"category-block-bg"}
            autoPlay
            loop
            muted
            ref={videoRef}
          />
        ) : (
          <img
            src={category.previewImage}
            className={"category-block-bg"}
          />
        )
      }

      <Link to={PAGE_ROUTES.CATEGORY.replace(":categoryUrl", category.url)}>
        <div className={"category-block-overlay"}>
          {
            (category.previewLogo) ? (
              <img
                src={category.previewLogo}
                className={"category-block-logo"}
              />
            ) : (
              <p className={"category-block-name"}>{category.name}</p>
            )
          }
        
          <Button
            variant={"contained"}
            color={"primary"}
            style={styles.actionButton}
          >
            Explore
          </Button>
        </div>
      </Link>
    </div>
  );
};

export default CategoryBlock;