/* eslint-disable no-prototype-builtins */
/* Utility functions for reducer composition
*  Mostly used to reduce boilerplate and increase readability
*  Reference: http://redux.js.org/docs/recipes/reducers/RefactoringReducersExample.html
*/

import { removeFromArr } from "./generalUtils";

//Used to update state objects immutably
export const updateObject = (oldObject, newValues) => {
  let getters = [];
  let getter;
  //We take measures to ensure that the getter functions do not get replaced by the value returned by the getter
  for (let objProp in oldObject) {
    if (oldObject.hasOwnProperty(objProp)) {
      getter = Object.getOwnPropertyDescriptor(oldObject, objProp).get;
      //If prop has a getter, save it so we can add it to the updated object
      if (getter) {
        getters.push({
          getter,
          objProp,
        });
      }
    }
  }

  //Update the object
  const retObj = {
    ...oldObject,
    ...newValues,
  };

  //Set the getters in the new object
  getters.forEach((getter) => {
    Object.defineProperty(retObj, getter.objProp, {
      get: getter.getter, 
    });
  });
  return retObj;
};

/* Used a prop of the specified branch which follows a byID structure as follows:
   State -> Branch -> byID -> ID -> prop
*/
export const updateByIDProp = (state, ID, branch, propToUpdate, propVal) => (
  updateObject(state, {
    [branch]: updateObject(state[branch], {
      byID: updateObject(state[branch].byID, {
        [ID]: updateObject(state[branch].byID[ID], {
          [propToUpdate]: propVal,
        }),
      }),
    }),
  })
);

/* Updates an item of the specified branch which follows a byID structure as follows:
   State -> Branch -> byID -> ID
*/
export const updateByIDItem = (state, ID, branch, newItem) => (
  updateObject(state, {
    [branch]: updateObject(state[branch], {
      byID: updateObject(state[branch].byID, {
        [ID]: updateObject(state[branch].byID[ID], newItem),
      }),
    }),
  })
);

//Let's us write reducers as a mapping from action type to handler
export const createReducer = (initialState, handlers) => (
  (state = initialState, action) => {
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action);
    } 
    return state;
  }
);

export const addItemToBranch = (state, item, branch, toStart=false) => {
  let updatedState = state;
  updatedState = updateObject(updatedState, {
    [branch]: updateObject(updatedState[branch], {
      allIDs: toStart ? [item.id, ...updatedState[branch].allIDs] : [...updatedState[branch].allIDs, item.id],
    }),
  });
  updatedState = updateObject(updatedState, {
    [branch]: updateObject(updatedState[branch], {
      byID: updateObject(updatedState[branch].byID, {
        [item.id]: item,
      }),
    }),
  });

  return updatedState;
};

export const removeFromAllIDs = (state, ID, branch) => (
  updateObject(state, {
    [branch]: updateObject(state[branch], {
      allIDs: removeFromArr(state[branch].allIDs, ID),
    }),
  })
);

export const setItemEditing = (itemName, initialProps={}) => (
  (state, action) => {
    let item;
    if (action.id === null) {
      item = {
        id: 0,
        ...initialProps,
      };
    }
    else {
      // eslint-disable-next-line no-undef
      item = structuredClone(state[itemName].byID[action.id]); //Deep clone
    }
    return updateObject(state, {
      [`${itemName}Editing`]: item,
    });
  }
);

export const editItemProp = (itemName) => (
  (state, action) => {
    const newVal = action.newValue;
    if ((newVal !== null) && (newVal.indexOf) && (newVal.indexOf("[FILE]") === 0)) {
      //New file, need to create a fileName prop as well
      let fileName = newVal.match(/\[FILE\]\((.+)\)/)[1];
      let base64 = newVal.match(/\[FILE\]\(.+\)(.+)/)[1];
      return updateObject(state, {
        [`${itemName}Editing`]: updateObject(state[`${itemName}Editing`], {
          [action.propToEdit]: base64,
          [`${action.propToEdit}FileName`]: fileName,
        }),
      });
    }

    //Not a file, normal update
    return updateObject(state, {
      [`${itemName}Editing`]: updateObject(state[`${itemName}Editing`], {
        [action.propToEdit]: action.newValue,
      }),
    });
  }
);

export const editItemPropByID = (itemName) => (
  (state, action) => (
    updateByIDProp(state, action.id, itemName, action.propToEdit, action.newValue)
  )
);

export const updateItem = (itemName) => (
  (state, action) => {
    if (action.isAddingNew) {
      return addItemToBranch(state, action[itemName], itemName);
    }
  
    return updateByIDItem(state, action[itemName].id, itemName, action[itemName]);
  }
);

export const deleteItem = (itemName) => (
  (state, action) => (
    removeFromAllIDs(state, action.id, itemName)
  )
);