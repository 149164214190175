import * as actionType from "./actionTypes";
import { updateObject, createReducer, setItemEditing, updateItem, editItemProp, deleteItem } from "../../utils/reducerUtils";

/* State shape same as initial state
*/
const initialState = {
  banner: {
    byID: {},
    allIDs: [],
  },
  bannerEditing: null,
};

const receiveBanners = (state, action) => (
  updateObject(state, {
    banner: action.banner,
  })
);

const reorderBanners = (state, action) => (
  updateObject(state, {
    banner: updateObject(state.banner, {
      allIDs: action.newOrder,
    }),
  })
);

const reducer = createReducer(initialState, {
  [actionType.RECEIVE_BANNERS]: receiveBanners,

  [actionType.SET_BANNER_EDITING]: setItemEditing("banner"),
  [actionType.EDIT_BANNER_PROP]: editItemProp("banner"),
  [actionType.UPDATE_BANNER]: updateItem("banner"),
  [actionType.DELETE_BANNER]: deleteItem("banner"),
  [actionType.REORDER_BANNERS]: reorderBanners,
});

export default reducer;

// SELECTORS
export const getBanners = (state) => (
  state.banner.allIDs.map((id) => state.banner.byID[id])
);

export const getBannerEditing = (state) => (
  state.bannerEditing
);