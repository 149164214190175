// We have to use direct import from the reducers (instead of using the Index file) to
// avoid circular dependencies with the action creators (who use these selectors)
import * as _notifSelectors from "./notification/reducer";
import * as _homeSelectors from "./home/reducer";
import * as _authSelectors from "./auth/reducer";
import * as _adminBannerSelectors from "./adminBanner/reducer";
import * as _adminCategorySelectors from "./adminCategory/reducer";
import * as _adminProductSelectors from "./adminProduct/reducer";
import * as _adminPressSelectors from "./adminPress/reducer";

// Helper to wrap a redux selector with a predetermined state slice function
// https://gist.github.com/jslatts/1c5d4d46b6e5b0ac0e917fa3b6f7968f
const bindSelectors = (slicer, selectors) => {
  const keys = Object.keys(selectors);
  const boundMethods = {};

  keys.forEach((k) => {
    boundMethods[k] = (fullState) => selectors[k](slicer(fullState));
  });

  return boundMethods;
};


// ----------------- Selectors ------------------
// Partitioned according to state tree shape
export const notifSelectors = { ...bindSelectors((state) => state.notif, _notifSelectors) };
export const homeSelectors = { ...bindSelectors((state) => state.home, _homeSelectors) };

export const authSelectors = { ...bindSelectors((state) => state.auth, _authSelectors) };
export const adminBannerSelectors = { ...bindSelectors((state) => state.adminBanner, _adminBannerSelectors) };
export const adminCategorySelectors = { ...bindSelectors((state) => state.adminCategory, _adminCategorySelectors) };
export const adminProductSelectors = { ...bindSelectors((state) => state.adminProduct, _adminProductSelectors) };
export const adminPressSelectors = { ...bindSelectors((state) => state.adminPress, _adminPressSelectors) };