import "./topNav.css";

import React from "react";
import { connect } from "react-redux";
import { Link, useLocation, matchRoutes } from "react-router-dom";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

import { homeSelectors, notifSelectors } from "../../logic/rootSelectors";
import _home from "../../logic/home";
import { PAGE_ROUTES, LS_ITEM, LOADING_STATE, CATEGORY_TYPE } from "../../utils/stateTypes";
import NavDrawer from "./NavDrawer";
import SearchDrawer from "./SearchDrawer";
import ContactDrawer from "./ContactDrawer";


const TopNav = ({
  timepieceCats, jewelleryCats, categoriesLS, categoryDetails, product,
  getCategories,
}) => {
  const location = useLocation();
  const [{ route }] = matchRoutes(Object.values(PAGE_ROUTES).map((route) => ({ path: route })), location.pathname);

  //Load categories
  React.useEffect(() => {
    if (categoriesLS === LOADING_STATE.NOT_LOADING) {
      getCategories();
    }
  }, []);

  const navBarRef = React.useRef(null);

  //Check if we need to show the rolex clock. Hide it if we're on a category or product page that isn't rolex
  let showRolexClock = true;
  let alternateLogo = null;
  if (route.path === PAGE_ROUTES.CATEGORY) {
    if (categoryDetails && categoryDetails.name && (!categoryDetails.name.toLowerCase().includes("rolex")) && (categoryDetails.type === CATEGORY_TYPE.TIMEPIECES)) {
      showRolexClock = false;
      alternateLogo = categoryDetails.previewLogo;
    }
  }
  else if (route.path === PAGE_ROUTES.PRODUCT) {
    if (product && product.category && (!product.category.name.toLowerCase().includes("rolex")) && (product.category.type === CATEGORY_TYPE.TIMEPIECES)) {
      showRolexClock = false;
      alternateLogo = product.category.previewLogo;
    }
    else if (categoryDetails && categoryDetails.name && (!categoryDetails.name.toLowerCase().includes("rolex")) && (categoryDetails.type === CATEGORY_TYPE.TIMEPIECES)) {
      showRolexClock = false;
      alternateLogo = categoryDetails.previewLogo;
    }
  }


  return (
    <nav
      className={"menu-top"}
      ref={navBarRef}
    >
      <div className={"menu-icon-buttons"}>
        <NavDrawer
          component={(
            <IconButton
              color={"secondary"}
              aria-label={"Open Menu"}
            >
              <img
                src={"/images/icon-menu.png"}
                className={"menu-icon"}
                alt={"Menu Icon"}
              />
            </IconButton>
          )}
          closeComponent={(
            <IconButton
              color={"secondary"}
              aria-label={"Close Menu"}
              alt={"Close Menu Icon"}
            >
              <Close />
            </IconButton>
          )}
          anchorRef={navBarRef}
          timepieceCats={timepieceCats}
          jewelleryCats={jewelleryCats}
          categoriesLS={categoriesLS}
        />

        <SearchDrawer
          component={(
            <IconButton
              color={"secondary"}
              aria-label={"Open Search"}
            >
              <img
                src={"/images/icon-search.png"}
                className={"menu-icon"}
                alt={"Search Icon"}
              />
            </IconButton>
          )}
          closeComponent={(
            <IconButton
              color={"secondary"}
              aria-label={"Close Search"}
              alt={"Close Search Icon"}
            >
              <Close />
            </IconButton>
          )}
          anchorRef={navBarRef}
        />

        <ContactDrawer
          component={(
            <IconButton
              color={"secondary"}
              aria-label={"Open Contact"}
            >
              <img
                src={"/images/icon-phone.png"}
                className={"menu-icon"}
                alt={"Contact Icon"}
              />
            </IconButton>
          )}
          closeComponent={(
            <IconButton
              color={"secondary"}
              aria-label={"Close Contact"}
              alt={"Close Contact Icon"}
            >
              <Close />
            </IconButton>
          )}
          anchorRef={navBarRef}
        />
      </div>

      <div className={"menu-logo-cont"}>
        <div className={"menu-logo-divider"} />
        <Link
          to={"/"}
          aria-label={"Back to Home"}
        >
          <img
            src={"/images/logo-header.png"}
            className={"menu-logo"}
            alt={"Logo"}
          />
        </Link>
        <div className={"menu-logo-divider"} />
      </div>

      
      <Link
        to={showRolexClock ? PAGE_ROUTES.CATEGORY.replace(":categoryUrl", "rolex") : null}
        aria-label={"Rolex Link"}
      >
        <div className={"menu-rolex-cont"}>
          {
            (showRolexClock) && (
              <iframe
                title={"Rolex Official Retailer"}
                src={"https://static.rolex.com/retailers/clock/?colour=gold&apiKey=b27dfdb11608541f9da76e112d181d17&lang=en"}
                className={"menu-rolex-clock"}
                scrolling={"no"}
              />
            )
          }
          {
            ((!showRolexClock) && (alternateLogo !== null)) && (
              <img
                src={alternateLogo}
                className={"menu-category-logo"}
              />
            )
          }
        </div>
      </Link>
    </nav>
  );
};

const mapStateToProps = (state) => {
  return {
    timepieceCats: homeSelectors.getCategoriesTimepieces(state),
    jewelleryCats: homeSelectors.getCategoriesJewellery(state),
    categoriesLS: notifSelectors.getLoadState(state)(LS_ITEM.CATEGORIES_LOAD),
    categoryDetails: homeSelectors.getCategoryDetails(state),
    product: homeSelectors.getProduct(state),
  };
};

const connected = connect(
  mapStateToProps,
  {
    getCategories: _home.actions.getCategories,
  },
)(TopNav);

export default connected;