import "./collectionsCarousel.css";

import React from "react";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import SectionHead from "../general/SectionHead";

const styles = {
  navButton: {
    width: 55,
    minWidth: 55,
    padding: "5px 10px",
  },
  actionButton: {
    marginTop: 20,
    padding: "5px 30px",
  },
};


const CollectionsCarousel = ({ collections }) => {

  const [currentBanner, setCurrentBanner] = React.useState(0);


  return (
    <section className={"collections-cont"}>
      <TransitionGroup className={"collections-cont-inner"}>
        <CSSTransition
          key={currentBanner}
          classNames={"fade"}
          timeout={300}
        >
          <React.Fragment>
            {
              collections.map((collection, index) => {
                if (index !== currentBanner) {
                  return null;
                }

                return (
                  <div
                    key={index}
                    className={"collection-cont"}
                  >
                    <div className={"collection-nav-cont"}>
                      <Button
                        onClick={() => setCurrentBanner((currentBanner - 1 + collections.length) % collections.length)}
                        style={styles.navButton}
                      >
                        <img
                          src={"/images/icon-chevron-left.png"}
                          className={"collection-nav-img"}
                        />
                      </Button>

                      <img
                        src={collection.image}
                        className={"collection-img"}
                      />

                      <Button
                        onClick={() => setCurrentBanner((currentBanner + 1) % collections.length)}
                        style={styles.navButton}
                      >
                        <img
                          src={"/images/icon-chevron-right.png"}
                          className={"collection-nav-img"}
                        />
                      </Button>
                    </div>
                    <div className={"collection-text-cont"}>
                      <SectionHead
                        className={"collection-head"}
                        whiteText={collection.head}
                        postGreenText={"collection"}
                      />
                      {
                        (collection.subHead) && (
                          <p className={"collection-sub-head"}>
                            {collection.subHead}
                          </p>
                        )
                      }
                      <Link to={collection.linkUrl}>
                        <Button
                          variant={"contained"}
                          color={"secondary"}
                          style={styles.actionButton}
                        >
                          Discover the collection
                        </Button>
                      </Link>
                    </div>
                  </div>
                );
              })
            }
          </React.Fragment>
        </CSSTransition>
      </TransitionGroup>
    </section>
  );
};

export default CollectionsCarousel;