import * as actionType from "./actionTypes";
import { updateObject, createReducer, editItemProp } from "../../utils/reducerUtils";

/* State shape same as initial state
*/
const initialState = {
  pressEditing: null,
};

const receivePress = (state, action) => (
  updateObject(state, {
    pressEditing: action.press,
  })
);

const reducer = createReducer(initialState, {
  [actionType.RECEIVE_PRESS]: receivePress,

  [actionType.EDIT_PRESS_PROP]: editItemProp("press"),
});

export default reducer;

// SELECTORS

export const getPressEditing = (state) => (
  state.pressEditing
);