import React from "react";
import { Snackbar, IconButton } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";

const styles = {
  error: {
    backgroundColor: "#f44336",
  },
  success: {
    backgroundColor: "#006700",
  },
};

const CustomSnackbar = ({ message, isError, isSuccess, openOverride=null, onCloseOverride=null }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  let closeCB = React.useCallback(() => setIsOpen(false), []);

  if (onCloseOverride !== null) {
    closeCB = onCloseOverride;
  }

  let snackStyle = {};
  if (isError) {
    snackStyle = styles.error;
  }
  else if (isSuccess) {
    snackStyle = styles.success;
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={(openOverride !== null) ? openOverride : isOpen}
      onClose={closeCB}
      message={message}
      autoHideDuration={6000}
      action={
        <React.Fragment>
          <IconButton
            size={"small"}
            color={"inherit"}
            onClick={closeCB}
          >
            <CloseIcon fontSize={"small"} />
          </IconButton>
        </React.Fragment>
      }
      ContentProps={{ style: snackStyle }}
    />
  );
};

export default CustomSnackbar;