import "./headBanner.css";

import React from "react";
import { Button } from "@mui/material";

import SectionHead from "../general/SectionHead";

const styles = {
  actionButton: {
    padding: "10px 30px",
    width: "100%",
    maxWidth: 250,
  },
};


const HeadBanner = ({ imageLg, imageSm=null, headPreGreen, headWhite, headPostGreen, buttonLabel="Start now", imageClass="" }) => {

  const sectionRef = React.useRef(null);

  return (
    <section
      className={"headbanner-section"}
      ref={sectionRef}
    >
      <img
        src={imageLg}
        className={["banner-img-lg", imageClass].join(" ")}
      />
      <img
        src={imageSm ? imageSm : imageLg}
        className={["banner-img-sm", imageClass].join(" ")}
      />
      <div className={"headbanner-text-cont"}>
        <SectionHead
          className={"section-head-standard with-dark-bg"}
          preGreenText={headPreGreen}
          whiteText={headWhite}
          postGreenText={headPostGreen}
        />
        <Button
          variant={"contained"}
          style={styles.actionButton}
          color={"secondary"}
          onClick={() => {
            //Scroll smoothly to the bottom of the current section
            const rootElement = document.getElementById("root");
            rootElement.scrollTo({
              top: sectionRef.current.offsetTop + sectionRef.current.offsetHeight,
              behavior: "smooth",
            });
          }}
        >
          {buttonLabel}
        </Button>
      </div>
    </section>
  );
};

export default HeadBanner;