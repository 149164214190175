import "./privacy.css";

import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Button } from "@mui/material";

import _home from "../../logic/home";
import MainContent from "../general/MainContent";
import SectionHead from "../general/SectionHead";
import BreadCrumbs from "../sections/Breadcrumbs";


const Contact = ({ updateShowConsent }) => {
  return (
    <MainContent>
      <Helmet>
        <title>Charles Greig | Privacy Policy</title>
      </Helmet>

      <BreadCrumbs
        crumbs={[
          { text: "Home", link: "/" },
          { text: "Privacy Policy", link: "" },
        ]}
      />

      <section className={"privacy-policy standard-width"}>
        <SectionHead
          className={"section-head-standard"}
          preGreenText={"Terms of Use & "}
          whiteText={"Privacy Policy"}
        />

        <h3> Introduction</h3>
        <ul>
          <li>
            Charles Greig Jewellers provides the information contained on this website and any
            of the pages comprising the website to you subject to the terms set out herein and referenced herein. By accessing
            and using this website, you shall be deemed to have agreed to all the terms. Charles Greig Jewellers may at any
            time modify the terms and your continued use of the website will be subject to the terms in force at the time of
            your use. Accordingly you agree to review the terms periodically, and your continued access or use of the website
            shall be deemed to be your acceptance of the amended terms.
          </li>
        </ul>
        

        <h3>Content of the Website</h3>
        <ul>
          <li>
            Whilst every effort is made to update the information provided on this website on
            a regular basis, Charles Greig Jewellers makes no representations or warranties, whether express, implied in law
            or residual, as to the accuracy, completeness and/or reliability of any information, data and/or content contained
            on the website and shall not be bound in any manner by any information. The information made available on the
            website from time to time, should not at any time be construed as advice of whatever nature.
          </li>
          <li>
            Charles Greig Jewellers reserves the right at any time to change or discontinue
            without notice, any aspect or feature of this website. No information contained on the website shall be construed
            as advice and same are offered for information purposes only.
          </li>
        </ul>

        <h3>Copyright and Intellectual Property Rights</h3>
        <ul>
          <li>
            Copyright and all intellectual property rights in all materials, texts, drawings
            and data made available on this website (collectively “materials”) are owned by Charles Greig Jewellers
            alternatively, Charles Greig Jewellers is the lawful user thereof and is protected by both South African
            intellectual property laws. Accordingly, any unauthorised copying, reproduction, retransmission, distribution,
            dissemination, sale, publication, broadcast or other circulation or exploitation of such materials or any
            component thereof will constitute an infringement of such copyright and other intellectual property rights. You
            may, however, use the materials or any component thereof for internal business purposes.
          </li>
          <li>
            The trademarks, names, logos and service marks (collectively “trademarks”)
            displayed on this website are registered and unregistered trademarks of Charles Greig Jewellers. Nothing contained
            on this website should be construed as granting any license or right to use any trademarks without the prior
            written consent of Charles Greig Jewellers.
          </li>
        </ul>

        <h3>External links</h3>
        <ul>
          <li>
            External links may be provided for your convenience, but
            they are beyond the control of Charles Greig Jewellers and no representation is made as to their content. Use or
            reliance on any external links provided is at your own risk. When visiting external links you must refer to that
            external website’s terms and conditions of use.
          </li>
          <li>
            No hypertext links shall be created from any website
            controlled by you or otherwise to this website without the express prior written permission of Charles Greig
            Jewellers.
          </li>
        </ul>

        <h3>Warranties, Disclaimers and Limitation of Liability</h3>
        <ul>
          <li>
            Charles Greig Jewellers makes no warranties,
            representations, statements or guarantees (whether express, implied in law or residual) regarding the website and
            any of the information contained on or referenced by the website. Without limiting the generality of the
            aforegoing, Charles Greig Jewellers gives no warranty of whatever nature regarding the accuracy or appropriateness
            in any jurisdiction of any information provided on or referenced by the website. Accordingly, the website is made
            available and the information is provided to you on an “as is” basis.
          </li>
          <li>
            Charles Greig Jewellers shall not be responsible for and
            disclaims all liability for any loss, liability, damage (whether direct, indirect, or consequential), personal
            injury or expense of any nature whatsoever which may be suffered by you or any third party, as a result of or
            which may be attributable, directly or indirectly, to your access and use of the website and any information
            contained on or accessed via the website. You, hereby indemnify Charles Greig Jewellers and waive any rights you
            may have or obtain against Charles Greig Jewellers in respect of any loss, liability, damage (whether direct or
            consequential) or expense of any nature whatsoever, which may be suffered by such you and/or third party as a
            result of or which may be attributable directly or indirectly to any of the aforesaid. Without limiting the
            generality of the aforegoing Charles Greig Jewellers shall not be responsible for and disclaims all liability for
            any loss, expense or damage of whatever nature incurred or sustained by you or any third party, due to – (i) any
            failure and/or unavailability of the website for any reason whatever and/or the failure/delay by any third party
            service provider to render any services which are necessary to ensure the availability and proper functioning of
            the website; and (ii) your use of or reliance on any information offered on or via the website.
          </li>
        </ul>

        <h3> Privacy Policy</h3>
        <ul>
          <li>
            Charles Greig Jewellers is sensitive to the private
            nature of information you provide to us over the Internet. When you use the website you may be providing Charles
            Greig Jewellers with your personally identifiable information. In addition, our web servers may automatically
            collect web site usage information from you when you visit our website. Website usage information informs us about
            how our visitors and subscribers use and navigate our website, including the number and frequency of users to each
            webpage, their IP address and the length of their stays.
          </li>
          <li>
            We may combine the personally identifiable information
            you provide Charles Greig Jewellers with other information about you that is available to us, including website
            usage information and information from other sources. We may use this information to inter alia enable us to
            improve your experience on the website, to improve and develop new products, features and services, to provide
            marketing with aggregate information about our user base and usage patterns, to allow us to personalise the
            advertising users see based on their personal characteristics or preferences and for other purposes. We may share
            such information with third parties to help us improve our content and for the purposes as aforesaid.
          </li>
          <li>
            In addition, we may disclose any information about you
            to other third parties, as we, in our sole discretion, believe necessary or appropriate, in connection with an
            investigation of fraud, intellectual property infringements, or any other activity that is illegal or may expose
            Charles Greig Jewellers to legal liability, or in connection with merger or consolidation activities of Charles
            Greig Jewellers. We reserve the right to contact you at any time regarding any problems or questions as well as to
            notify you of changes to our Privacy Policy, or to other policies or terms that affect you and your use of the
            website, and to share your information in order to enforce our policies and terms governing the website.
          </li>
          <li>
            The personally identifiable information we collect about you is stored in limited
            access servers. We maintain safeguards to protect the security, integrity, and privacy of these servers and of
            your personally identifiable information.
          </li>
        </ul>

        <h3>Rolex Privacy Policy</h3>
        <ul>
          <li>
            While navigating on the Rolex section of our website, you may interact with an
            embedded website from <a href={"https://www.rolex.com/footer/terms-of-use.html"}>www.rolex.com</a>.
            In such case, <a href={"https://www.rolex.com/footer/terms-of-use.html"}>Terms of Use</a>,
            {" "}
            <a href={"https://www.rolex.com/footer/privacy-notice.html"}>Privacy Notice</a> and
            {" "}
            <a href={"https://www.rolex.com/footer/cookies.html"}>Cookies Policy</a> of
            {" "}
            <a href={"http://www.rolex.com/"}>www.rolex.com</a> are solely applicable.
          </li>
        </ul>

        <h3>Rolex Terms of Use</h3>
        <ul>
          <li>
            While navigating on the Rolex section of our website, you may interact with an
            embedded website from <a href={"https://www.rolex.com/footer/terms-of-use.html"}>www.rolex.com</a>.
            In such case,
            {" "}
            <a href={"https://www.rolex.com/footer/terms-of-use.html"}>
              Terms of use
            </a>,
            {" "}
            <a href={"https://www.rolex.com/footer/privacy-notice.html"}>
              Privacy Notice
            </a> and
            {" "}
            <a href={"https://www.rolex.com/footer/cookies.html"}>
              Cookies Policy
            </a> of
            {" "}
            <a href={"http://www.rolex.com/"}>www.rolex.com</a> are solely applicable.
          </li>
        </ul>

        <h3>Cookies</h3>
        <ul>
          <li>
            We employ the use of cookies, which are small text files that ask your web browser
            for permission to be placed on your computer’s hard drive. Once you agree, the file is added and the cookie helps
            analyse web traffic. Cookies allow us to optimise our online offering, by providing information to indicate our
            audience size and usage patterns, information about your preferences and recognise when you return to our website.
            Generally, cookies help us to provide you with a better online experience by qualifying which pages and/or
            content you find useful or not.
          </li>
          <li>
            A cookie does not permit access to your computer or any of your personal information, other than that which you
            choose to share with us.
          </li>
          <li>
            Any/all de-identified statistical information gathered will be retained and remain the property of Charles Greig
            Jewellers.
          </li>
          <li>
            You may at any time decline cookies in your web browser settings, but this may prevent you from taking full
            advantage of our online offering.
          </li>
        </ul>
        <Button
          variant={"contained"}
          color={"secondary"}
          onClick={() => updateShowConsent(true)}
        >
          Manage cookie preferences
        </Button>

        <h3>General</h3>
        <ul>
          <li>
            These terms constitute the sole record of the agreement
            between you and Charles Greig Jewellers in relation to the subject matter hereof. Neither you nor Charles Greig
            Jewellers shall be bound by any express tacit nor implied representation, warranty, promise or the like not
            recorded herein. These terms supersede and replace all prior commitments, undertakings or representations, whether
            written or oral, between you and Charles Greig Jewellers in respect of the subject matter hereof. No addition to,
            variation or agreed cancellation of any provision of these terms shall be binding upon either you or Charles Greig
            Jewellers unless agreed to by Charles Greig Jewellers. No indulgence or extension of time which either you or
            Charles Greig Jewellers may grant to the other will constitute a waiver of or, whether by estoppel or otherwise,
            limit any of the existing or future rights of the grantor in terms hereof, save in the event or to the extent that
            the grantor has signed a written document expressly waiving or limiting such rights.
          </li>
          <li>
            Charles Greig Jewellers shall be entitled to cede,
            assign and delegate all or any of its rights and obligations in terms of these terms of use to any third
            party.
          </li>
          <li>
            All provisions of these terms are, notwithstanding the
            manner in which they have been grouped together or linked grammatically, severable from each other. Any provision
            of these terms which is or becomes unenforceable in any jurisdiction, whether due to voidness, invalidity,
            illegality, unlawfulness or for any reason whatever, shall, in such jurisdiction only and only to the extent that
            it is so unenforceable, be treated as pro non scripto and the remaining provisions of these terms shall remain in
            full force and effect.
          </li>
          <li>
            Should Charles Greig Jewellers be prevented from
            fulfilling any of its obligations to you as a result of any event of force majeure, then those obligations shall
            be deemed to have been suspended to the extent that and for as long as Charles Greig Jewellers is so prevented
            from fulfilling them and your corresponding obligations shall be suspended to the corresponding extent. In the
            event that force majeure continues for more than thirty days after it has first occurred then Charles Greig
            Jewellers shall be entitled (but not obliged) to terminate all of its rights and obligations in terms of or
            arising out of these terms by giving notice to you. An “event of force majeure” shall mean any event or
            circumstance whatsoever which is not within the reasonable control of the affected party including, without
            limitation, vis major, casus fortuitus, any act of God, strike, theft, riots, explosion, insurrection or other
            similar disorder, war (whether declared or not) or military operations, the downtime of any telecommunications
            line and/or unavailability of any telecommunications facilities, power failure, international restrictions, any
            requirement of any international authority, any requirement of any government or other competent local authority,
            any court order, export control or shortage of transport facilities.
          </li>
          <li>
            These terms shall be governed by and construed in
            accordance with the laws of the Republic of South Africa without giving effect to any principles of conflict of
            law. You hereby consent to exclusive jurisdiction of the High Court of South Africa in respect of any disputes
            arising in connection with these terms or any matter related to or in connection therewith.
          </li>
        </ul>

        <p>All Rights Reserved</p>
      </section>
    </MainContent>
  );
};

const mapStateToProps = () => {
  return {};
};

const connected = connect(
  mapStateToProps,
  {
    updateShowConsent: _home.actions.updateShowConsent,
  },
)(Contact);

export default connected;