import "./home.css";

import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import { homeSelectors, notifSelectors } from "../../logic/rootSelectors";
import _home from "../../logic/home";
import { LS_ITEM, PAGE_ROUTES } from "../../utils/stateTypes";
import MainContent from "../general/MainContent";
import BannerCarousel from "../sections/BannerCarousel";
import CollectionsCarousel from "../sections/CollectionsCarousel";
import InfoSection from "../sections/InfoSection";
import GalleryBlock from "../sections/GalleryBlock";
import Socials from "../sections/Socials";


const Home = ({
  banners, bannersLS,
  getBanners,
}) => {
  return (
    <MainContent loadItems={[{ loadState: bannersLS, loadFn: getBanners }]}>
      <Helmet>
        <title>Charles Greig | Fine Jewellers & Luxury Timepieces</title>
      </Helmet>

      <BannerCarousel banners={banners} />

      <CollectionsCarousel
        collections={[
          {
            image: "/images/collections/Jewellery-3.png",
            head: "Pearly & Silver Cluster",
            subHead: "Adorned with genuine freshwater pearls, delicately clustered to create an elegant and captivating focal point.",
            linkUrl: "/jewellery",
          },
          {
            image: "/images/collections/Jewellery-2.png",
            head: "Gemstone rings",
            subHead: "Adorned with ethically sourced gemstones, add a touch of elegance and individuality to any ensemble.",
            linkUrl: "/jewellery",
          },
          {
            image: "/images/collections/Jewellery-1.png",
            head: "Diamond insects",
            subHead: "Diamond encrusted wings, ruby eyes, and pearl abdomens... evoke timeless elegance and intricate craftsmanship.",
            linkUrl: "/jewellery",
          },
        ]}
      />

      <InfoSection
        info={{
          image: "/images/info/Info-1-Rolex.jpg",
          headPreGreen: "World-class",
          headWhite: "timepieces",
          subHead: "Crafted by the world's most renowned luxury watch brands: epitomize exquisite craftsmanship, timeless elegance, and enduring value.",
          linkUrl: PAGE_ROUTES.TIMEPIECES,
          linkLabel: "Explore timepieces",
        }}
        isVariant={true}
      />

      <InfoSection
        info={{
          image: "/images/info/Info-2-Jewellery.jpg",
          headWhite: "Charles Greig",
          headPostGreen: "Jewellery",
          subHead: "Every jewellery piece created by the artisans at Charles Greig tells a unique and moving story. Discover our exquisite collections today.",
          linkUrl: `${PAGE_ROUTES.JEWELLERY}`,
          linkLabel: "Find out more",
        }}
      />

      <InfoSection
        info={{
          image: "/images/info/Info-3-Design.jpg",
          headWhite: "Bespoke",
          headPostGreen: "Jewellery Design",
          subHead: "Bespoke jewellery design involves creating unique, tailor-made pieces that reflecting your unique style and personality, crafted by skilled artisans.",
          linkUrl: "/category/custom-jewellery",
          linkLabel: "Find out more",
        }}
        isVariant={true}
      />

      <InfoSection
        info={{
          image: "/images/info/Info-4-Brothers.jpg",
          headPreGreen: "5th generation",
          headWhite: "jewellers",
          subHead: "A family without roots will leave little of consequence; in contrast, five generations of the Greig family have not only branched out, but withstood the storms of history as the firm has grown and prospered.",
          linkUrl: PAGE_ROUTES.LEGACY,
          linkLabel: "Find out more",
        }}
      />

      <section className={"gallery-section"}>
        <GalleryBlock
          head={"Over 120 years of"}
          subHead={"sophistication"}
          image1={"/images/gallery/gal-005.jpg"}
        />
        <GalleryBlock
          image1={"/images/gallery/gal-017.jpg"}
          image2={"/images/gallery/gal-013.jpg"}
          image3={"/images/gallery/gal-006.jpg"}
        />
        <GalleryBlock
          image1={"/images/gallery/gal-011.jpg"}
          image2={"/images/gallery/gal-016.jpg"}
          image3={"/images/gallery/gal-009.jpg"}
          isReversed={true}
        />
        <GalleryBlock
          image1={"/images/gallery/gal-012.jpg"}
          image2={"/images/gallery/gal-008.jpg"}
          image3={"/images/gallery/gal-014.jpg"}
        />
      </section>

      <Socials />
    </MainContent>
  );
};

const mapStateToProps = (state) => {
  return {
    banners: homeSelectors.getBanners(state),
    bannersLS: notifSelectors.getLoadState(state)(LS_ITEM.BANNERS_LOAD),
  };
};

const connected = connect(
  mapStateToProps,
  {
    getBanners: _home.actions.getBanners,
  },
)(Home);

export default connected;