/* eslint-disable no-unused-vars */
import React from "react";

import PageRolex from "./PageRolex";
import PageCartier from "./PageCartier";
import PageBreitling from "./PageBreitling";

/* eslint-enable no-unused-vars */

const components = {
  PageRolex,
  PageCartier,
  PageBreitling,
};


const CustomCategory = ({ pageName }) => {
  const CustomPage = components[pageName];
  return (
    <CustomPage />
  );
};

export default CustomCategory;