import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";

import configureStore from "./configureStore";
import MuiTheme from "./muiTheme";
import MuiThemeAdmin from "./muiThemeAdmin";
import App from "./components/App";
import AdminApp from "./adminComponents/AdminApp";


let store = configureStore();

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");
const path = window.location.pathname;
const isAdmin = path.startsWith("/admin");

const root = createRoot(rootElement);
root.render(
  <BrowserRouter basename={baseUrl}>
    <Provider store={store}>
      <ThemeProvider theme={isAdmin ? MuiThemeAdmin : MuiTheme}>
        {isAdmin ? <AdminApp /> : <App />}
      </ThemeProvider>
    </Provider>
  </BrowserRouter>,
);