import * as actionType from "./actionTypes";
import { createReducer, updateObject } from "../../utils/reducerUtils";

/* State shape same as initial state
*/
const initialState = {
  authToken: null,
  hasCheckedAuth: false,
};

const updateAuthToken = (state, action) => (
  updateObject(state, {
    authToken: action.token,
  })
);

const updateHasCheckedAuth = (state) => (
  updateObject(state, {
    hasCheckedAuth: true,
  })
);

const reducer = createReducer(initialState, {
  [actionType.SET_AUTH_TOKEN]: updateAuthToken,
  [actionType.SET_HAS_CHECKED_AUTH]: updateHasCheckedAuth,
});

export default reducer;

// SELECTORS
export const getAuthToken = (state) => (
  state.authToken
);

export const getHasCheckedAuth = (state) => (
  state.hasCheckedAuth
);