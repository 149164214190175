import * as actionType from "./actionTypes";
import { updateObject, createReducer, setItemEditing, updateItem, editItemProp, deleteItem } from "../../utils/reducerUtils";

/* State shape same as initial state
*/
const initialState = {
  product: {
    byID: {},
    allIDs: [],
  },
  loadedCategoryID: null,
  productEditing: null,
};

const receiveProducts = (state, action) => (
  updateObject(state, {
    product: action.product,
    loadedCategoryID: action.loadedCategoryID,
  })
);

const reorderProducts = (state, action) => (
  updateObject(state, {
    product: updateObject(state.product, {
      allIDs: action.newOrder,
    }),
  })
);

const reducer = createReducer(initialState, {
  [actionType.RECEIVE_PRODUCTS]: receiveProducts,

  [actionType.SET_PRODUCT_EDITING]: setItemEditing("product"),
  [actionType.EDIT_PRODUCT_PROP]: editItemProp("product"),
  [actionType.UPDATE_PRODUCT]: updateItem("product"),
  [actionType.DELETE_PRODUCT]: deleteItem("product"),
  [actionType.REORDER_PRODUCTS]: reorderProducts,
});

export default reducer;

// SELECTORS

export const getProducts = (state) => (
  state.product.allIDs.map((id) => state.product.byID[id])
);

export const getProductEditing = (state) => (
  state.productEditing
);

export const getLoadedCategoryID = (state) => (
  state.loadedCategoryID
);