import "./infoSection.css";

import React from "react";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

import SectionHead from "../general/SectionHead";

const styles = {
  actionButton: {
    marginTop: 20,
    padding: "10px 30px",
  },
};

const InfoSection = ({ info, isVariant, containImg=false }) => {

  return (
    <section className={["info-cont", isVariant ? "variant" : ""].join(" ")}>
      <div className={"info-img-cont"}>
        <img
          src={info.image}
          className={"info-img"}
          style={{ objectFit: containImg ? "contain" : "cover" }}
        />
        <div className={"info-img-bg"} />
      </div>
      <div className={"info-text-cont"}>
        <SectionHead
          className={"info-head"}
          preGreenText={info.headPreGreen}
          whiteText={info.headWhite}
          postGreenText={info.headPostGreen}
        />
        {
          (info.subHead) && (
            <p className={"info-sub-head"}>{info.subHead}</p>
          )
        }
        {
          (info.subHeadHtml) && (
            <div
              className={"info-sub-head"}
              dangerouslySetInnerHTML={{ __html: info.subHeadHtml }}
            />
          )
        }
        {
          (info.linkUrl) && (
            <div>
              <Link to={info.linkUrl}>
                <Button
                  variant={"contained"}
                  color={"secondary"}
                  style={styles.actionButton}
                  onClick={() => {
                    if (info.linkOnClick) {
                      info.linkOnClick();
                    }
                  }}
                >
                  {info.linkLabel}
                </Button>
              </Link>
            </div>
          )
        }
      </div>
    </section>
  );
};

export default InfoSection;