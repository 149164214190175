import React from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Button } from "@mui/material";

import { notifSelectors, adminBannerSelectors } from "../../logic/rootSelectors";
import _adminBanner from "../../logic/adminBanner";
import { LOADING_STATE, LS_ITEM_ADMIN, FORM_PROP_TYPE } from "../../utils/stateTypes";
import MainContent from "../adminGeneral/MainContent";
import EditForm from "../adminGeneral/EditForm";

const styles = {
  previewButton: {
    maxWidth: 120,
    // height: 20,
    fontSize: "0.5em",
    marginTop: 5,
    padding: "5px 7px",
  },
};


const BannerEdit = ({
  bannerEditing, bannersLS, bannerEditLS,
  setBannerEditing, editBannerProp, saveBanner, loadBanners,
}) => {
  let { bannerID } = useParams();
  bannerID = parseInt(bannerID);

  //Set tool editing when loaded
  React.useEffect(() => {
    if (bannersLS === LOADING_STATE.LOADED) {
      setBannerEditing(bannerID);
    }
  }, [bannerID, bannersLS]);


  return (
    <MainContent loadItems={[{ loadState: bannersLS, loadFn: loadBanners }]}>
      <h1 className={"page-head"}>EDIT BANNER</h1>
      <div className={"admin-divider"} />

      {/* ====================================== FORM ====================================== */}
      {
        (bannerEditing) ? (
          <div className={"standard-box admin-standard-width edit-box"}>
            <div className={"edit-box-half with-border"}>
              <div className={"edit-box-half-inner space-for-button"}>
                <EditForm
                  heading={"Edit Banner"}
                  item={bannerEditing}
                  loadingState={bannerEditLS}
                  propsToEdit={[
                    { label: "Is Active", prop: "isActive", type: FORM_PROP_TYPE.CHECKBOX },
                    { label: "Image (Desktop)", prop: "imageLg", type: FORM_PROP_TYPE.IMAGE, maxWidth: 2500 },
                    { label: "Image (Mobile)", prop: "imageSm", type: FORM_PROP_TYPE.IMAGE, maxWidth: 1000, isOptional: true },
                    { label: "Is Image Only", prop: "imageOnly", type: FORM_PROP_TYPE.CHECKBOX },
                    (!bannerEditing.imageOnly) ? { label: "Heading", prop: "head", type: FORM_PROP_TYPE.TEXT } : { prop: "head", type: FORM_PROP_TYPE.SKIP },
                    (!bannerEditing.imageOnly) ? { label: "Sub-heading", prop: "subHead", type: FORM_PROP_TYPE.TEXT } : { prop: "subHead", type: FORM_PROP_TYPE.SKIP },
                    (!bannerEditing.imageOnly) ? { label: "Link URL", prop: "linkUrl", type: FORM_PROP_TYPE.TEXT } : { prop: "linkUrl", type: FORM_PROP_TYPE.SKIP },
                    (!bannerEditing.imageOnly) ? { label: "Link Label", prop: "linkLabel", type: FORM_PROP_TYPE.TEXT } : { prop: "linkLabel", type: FORM_PROP_TYPE.SKIP },
                    (!bannerEditing.imageOnly) ? { label: "Color", prop: "color", type: FORM_PROP_TYPE.COLOR } : { prop: "color", type: FORM_PROP_TYPE.SKIP },
                  ]}
                  updatePropBase={editBannerProp}
                  savePropEdit={saveBanner}
                />
              </div>
            </div>

            {/* ====================================== PREVIEW ====================================== */}
            <div className={"edit-box-half with-border"}>
              <div className={"edit-box-half-inner centered"}>
                <h3 className={"with-margin-bottom"}>Preview</h3>
                <div className={"banner-preview standard-box"}>
                  <div className={"banner-preview-nav"}>
                    <img src={"/images/logo-header.png"} />
                  </div>
                  <div className={"banner-preview-cont"}>
                    <img src={bannerEditing.imageLg ? bannerEditing.imageLg : "/images/admin/placeholder-image.png"} />
                    {
                      (!bannerEditing.imageOnly) && (
                        <div className={"banner-preview-text"}>
                          <p style={{ color: bannerEditing.color }}>{bannerEditing.head}</p>
                          {(bannerEditing.subHead) && <span style={{ color: bannerEditing.color }}>{bannerEditing.subHead}</span>}
                          <Button
                            variant={"contained"}
                            color={"primary"}
                            style={{ ...styles.previewButton, backgroundColor: bannerEditing.color }}
                          >
                            {bannerEditing.linkLabel}
                          </Button>
                        </div>
                      )
                    }
                  </div>
                  <div className={"banner-preview-next-section"}>
                    <p><i>Next section</i></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null
      }
    </MainContent>
  );
};

const mapStateToProps = (state) => {
  return {
    bannerEditing: adminBannerSelectors.getBannerEditing(state),
    bannersLS: notifSelectors.getLoadState(state)(LS_ITEM_ADMIN.BANNERS_LOAD),
    bannerEditLS: notifSelectors.getLoadState(state)(LS_ITEM_ADMIN.BANNER_SAVE),
  };
};

const connected = connect(
  mapStateToProps,
  {
    setBannerEditing: _adminBanner.actions.setBannerEditing,
    editBannerProp: _adminBanner.actions.editBannerProp,
    saveBanner: _adminBanner.actions.saveBanner,
    loadBanners: _adminBanner.actions.loadBanners,
  },
)(BannerEdit);

export default connected;

