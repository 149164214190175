import "./banner.css";

import React from "react";
import { connect } from "react-redux";
import { Button, CircularProgress } from "@mui/material";
import { Add } from "@mui/icons-material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { adminBannerSelectors, notifSelectors } from "../../logic/rootSelectors";
import _adminBanner from "../../logic/adminBanner";
import { ADMIN_PAGE_ROUTES, LS_ITEM_ADMIN, LOADING_STATE } from "../../utils/stateTypes";
import MainContent from "../adminGeneral/MainContent";
import RowItem from "../adminGeneral/RowItem";

const styles = {
  addButton: {
    margin: "20px 0 10px 0",
    width: "100%",
    maxWidth: 200,
    height: 40,
  },
};


const BannersHome = ({
  banners, bannersLS, addBannerLS, getLoadState,
  loadBanners, addBanner, deleteBanner, reorderBanners,
}) => {
  
  const onDragEnd = React.useCallback((result) => {
    if (result.destination !== null) {
      if (result.source.index !== result.destination.index) {
        reorderBanners(result.source.index, result.destination.index);
      }
    }
  }, []);


  return (
    <MainContent loadItems={[{ loadState: bannersLS, loadFn: loadBanners }]}>
      <h1 className={"admin-page-head"}>BANNERS</h1>
      <div className={"admin-divider"} />
    
      <DragDropContext onDragEnd={onDragEnd}>
        <div className={"admin-standard-width accordian-cont"}>
          <Droppable
            droppableId={"itemDrop"}
            direction={"vertical"}
          >
            {(dropProps) => (
              <div
                ref={dropProps.innerRef}
                {...dropProps.droppableProps}
                className={"draggable-list-cont"}
              >
                <div className={"admin-standard-box"}>
                  {
                    banners.map((banner, bannerIndex) => (
                      <Draggable
                        key={banner.id}
                        draggableId={`${banner.id}`}
                        index={bannerIndex}
                      >
                        {(dragProps) => (
                          <div
                            ref={dragProps.innerRef}
                            {...dragProps.draggableProps}
                            className={"draggable-item-cont"}
                          >
                            <RowItem
                              item={banner}
                              deleteLS={getLoadState(`${LS_ITEM_ADMIN.BANNER_DELETE}${banner.id}`)}
                              deleteCB={() => deleteBanner(banner.id)}
                              editLink={ADMIN_PAGE_ROUTES.BANNER_EDIT.replace(":bannerID", banner.id)}
                              isSubRow={true}
                              moveLS={getLoadState(`${LS_ITEM_ADMIN.BANNER_REORDER}${banner.id}`)}
                              dragHandleProps={dragProps.dragHandleProps}
                            >
                              <div className={["admin-banner-row", banner.isActive ? "" : "not-active"].join(" ")}>
                                <img src={banner.imageLg ? banner.imageLg : "/images/admin/placeholder-image.png"} />
                                <div>
                                  {
                                    (banner.isActive) ? <span>ACTIVE</span> : <span>INACTIVE</span>
                                  }
                                  <h3>{banner.head ? banner.head : <i>No heading</i>}</h3>
                                  <p>{banner.subHead ? banner.subHead : <i>No sub-heading</i>}</p>
                                </div>
                              </div>
                            </RowItem>
                          </div>
                        )}
                      </Draggable>
                    ))
                  }
                  {dropProps.placeholder}
                </div>
              </div>
            )}
          </Droppable>

          <Button
            variant={"contained"}
            color={"primary"}
            style={styles.addButton}
            startIcon={(addBannerLS !== LOADING_STATE.LOADING) ? <Add /> : <CircularProgress size={20} />}
            disabled={(addBannerLS === LOADING_STATE.LOADING)}
            onClick={addBanner}
          >
            Add Banner
          </Button>
        </div>
      </DragDropContext>
    </MainContent>
  );
};

const mapStateToProps = (state) => {
  return {
    banners: adminBannerSelectors.getBanners(state),
    bannersLS: notifSelectors.getLoadState(state)(LS_ITEM_ADMIN.BANNERS_LOAD),
    addBannerLS: notifSelectors.getLoadState(state)(LS_ITEM_ADMIN.BANNER_ADD),
    getLoadState: notifSelectors.getLoadState(state),
  };
};

const connected = connect(
  mapStateToProps,
  {
    loadBanners: _adminBanner.actions.loadBanners,
    addBanner: _adminBanner.actions.addBanner,
    deleteBanner: _adminBanner.actions.deleteBanner,
    reorderBanners: _adminBanner.actions.reorderBanners,
  },
)(BannersHome);

export default connected;

