import React from "react";
import { connect } from "react-redux";

import _notif from "../../logic/notification";
import { notifSelectors } from "../../logic/rootSelectors";
import { NOTIF_TYPE } from "../../utils/stateTypes";
import Snackbar from "./Snackbar";


class Notification extends React.Component {
  constructor(props) {
    super(props);
    this._handleRequestClose = this._handleRequestClose.bind(this);
  }

  _handleRequestClose() {
    this.props.removeCallback();
  }

  render() {
    const { notification } = this.props;
    let isSuccess = false;
    let isError = false;
    if (notification !== null) {
      if (notification.type === NOTIF_TYPE.SUCCESS) {
        isSuccess = true;
      }
      else if (notification.type === NOTIF_TYPE.ERROR) {
        isError = true;
      }
    }
    return (
      <div>
        <Snackbar
          openOverride={notification !== null}
          message={notification ? notification.text : ""}
          onCloseOverride={this._handleRequestClose}
          isSuccess={isSuccess}
          isError={isError}
        />
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    notification: notifSelectors.getLatestNotification(state),
  };
};

const connectedNotification = connect(
  mapStateToProps,
  //Shorthand mapDispatchToProps
  { removeCallback: _notif.actions.removeNotification },
)(Notification);

export { Notification };

export default connectedNotification;
