import "./category.css";

import React from "react";
import { connect } from "react-redux";
import { Button, CircularProgress, ButtonGroup } from "@mui/material";
import { Add } from "@mui/icons-material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { adminCategorySelectors, notifSelectors } from "../../logic/rootSelectors";
import _adminCategory from "../../logic/adminCategory";
import { ADMIN_PAGE_ROUTES, LS_ITEM_ADMIN, LOADING_STATE, CATEGORY_TYPE } from "../../utils/stateTypes";
import MainContent from "../adminGeneral/MainContent";
import RowItem from "../adminGeneral/RowItem";

const styles = {
  addButton: {
    margin: "20px 0 10px 0",
    width: "100%",
    maxWidth: 200,
    height: 40,
  },
  selected: {
    backgroundColor: "#1a2b16",
    color: "#fff",
  },
};


const CategoryHome = ({
  activeType, categories, categoriesLS, addCategoryLS, getLoadState,
  setActiveType, loadCategories, addCategory, deleteCategory, reorderCategories,
}) => {
  
  const onDragEnd = React.useCallback((result) => {
    if (result.destination !== null) {
      if (result.source.index !== result.destination.index) {
        reorderCategories(result.source.index, result.destination.index, activeType);
      }
    }
  }, [activeType]);
  
  const filteredCategories = React.useMemo(() => (
    categories.filter((cat) => cat.type === activeType)
  ), [categories, activeType]);


  return (
    <MainContent loadItems={[{ loadState: categoriesLS, loadFn: loadCategories }]}>
      <h1 className={"admin-page-head"}>CATEGORIES</h1>
      <div className={"admin-divider"} />

      <div className={"admin-button-group"}>
        <ButtonGroup color={"primary"}>
          <Button
            onClick={() => setActiveType(CATEGORY_TYPE.TIMEPIECES)}
            style={(activeType === CATEGORY_TYPE.TIMEPIECES) ? styles.selected : {}}
          >
            TIMEPIECES
          </Button>
          <Button
            onClick={() => setActiveType(CATEGORY_TYPE.JEWELLERY)}
            style={(activeType === CATEGORY_TYPE.JEWELLERY) ? styles.selected : {}}
          >
            JEWELLERY
          </Button>
        </ButtonGroup>
      </div>
    
      <DragDropContext onDragEnd={onDragEnd}>
        <div className={"admin-standard-width accordian-cont"}>
          <Droppable
            droppableId={"itemDrop"}
            direction={"vertical"}
          >
            {(dropProps) => (
              <div
                ref={dropProps.innerRef}
                {...dropProps.droppableProps}
                className={"draggable-list-cont"}
              >
                <div className={"admin-standard-box"}>
                  {
                    filteredCategories.map((cat, catIndex) => (
                      <Draggable
                        key={cat.id}
                        draggableId={`${cat.id}`}
                        index={catIndex}
                      >
                        {(dragProps) => (
                          <div
                            ref={dragProps.innerRef}
                            {...dragProps.draggableProps}
                            className={"draggable-item-cont"}
                          >
                            <RowItem
                              item={cat}
                              deleteLS={getLoadState(`${LS_ITEM_ADMIN.CATEGORY_DELETE}${cat.id}`)}
                              deleteCB={() => deleteCategory(cat.id)}
                              editLink={ADMIN_PAGE_ROUTES.CATEGORY_EDIT.replace(":categoryID", cat.id)}
                              isSubRow={true}
                              moveLS={getLoadState(`${LS_ITEM_ADMIN.CATEGORY_REORDER}${cat.id}`)}
                              dragHandleProps={dragProps.dragHandleProps}
                            >
                              <div className={["admin-cat-row", cat.isActive ? "" : "not-active"].join(" ")}>
                                <img src={cat.headerImage ? cat.headerImage : "/images/admin/placeholder-image.png"} />
                                <div>
                                  {
                                    (cat.isActive) ? <span>ACTIVE</span> : <span>INACTIVE</span>
                                  }
                                  <h3>{cat.name ? cat.name : <i>No name</i>}</h3>
                                  <p>{cat.url ? cat.url : <i>No URL</i>}</p>
                                </div>
                              </div>
                            </RowItem>
                          </div>
                        )}
                      </Draggable>
                    ))
                  }
                  {dropProps.placeholder}
                </div>
              </div>
            )}
          </Droppable>

          <Button
            variant={"contained"}
            color={"primary"}
            style={styles.addButton}
            startIcon={(addCategoryLS !== LOADING_STATE.LOADING) ? <Add /> : <CircularProgress size={20} />}
            disabled={(addCategoryLS === LOADING_STATE.LOADING)}
            onClick={() => addCategory(activeType)}
          >
            Add Category
          </Button>
        </div>
      </DragDropContext>
    </MainContent>
  );
};

const mapStateToProps = (state) => {
  return {
    activeType: adminCategorySelectors.getActiveType(state),
    categories: adminCategorySelectors.getCategories(state),
    categoriesLS: notifSelectors.getLoadState(state)(LS_ITEM_ADMIN.CATEGORIES_LOAD),
    addCategoryLS: notifSelectors.getLoadState(state)(LS_ITEM_ADMIN.CATEGORY_ADD),
    getLoadState: notifSelectors.getLoadState(state),
  };
};

const connected = connect(
  mapStateToProps,
  {
    setActiveType: _adminCategory.actions.setActiveType,
    loadCategories: _adminCategory.actions.loadCategories,
    addCategory: _adminCategory.actions.addCategory,
    deleteCategory: _adminCategory.actions.deleteCategory,
    reorderCategories: _adminCategory.actions.reorderCategories,
  },
)(CategoryHome);

export default connected;

