import "./jewelleryHome.css";

import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import { homeSelectors, notifSelectors } from "../../logic/rootSelectors";
import _home from "../../logic/home";
import { LS_ITEM, PAGE_ROUTES } from "../../utils/stateTypes";
import MainContent from "../general/MainContent";
import HeadBanner from "../sections/HeadBanner";
import Breadcrumbs from "../sections/Breadcrumbs";
import InfoSection from "../sections/InfoSection";
import CategoryBlock from "./CategoryBlock";

const JewelleryHome = ({
  jewelleryCats, categoriesLS,
  setContactFieldsService,
}) => {

  return (
    <MainContent loadItems={[{ loadState: categoriesLS, loadFn: () => {} }]}>
      <Helmet>
        <title>Charles Greig | Jewellery</title>
      </Helmet>

      <HeadBanner
        imageLg={"/images/Jewellery-header.jpg"}
        headPreGreen={"Explore"}
        headWhite={"jewellery"}
      />

      <Breadcrumbs
        crumbs={[
          { text: "Home", link: "/" },
          { text: "Jewellery", link: "" },
        ]}
      />

      <section className={"categories-section"}>
        {
          jewelleryCats.map((category) => {
            return (
              <CategoryBlock
                key={category.id}
                category={category}
              />
            );
          })
        }
      </section>
    </MainContent>
  );
};

const mapStateToProps = (state) => {
  return {
    jewelleryCats: homeSelectors.getCategoriesJewellery(state),
    categoriesLS: notifSelectors.getLoadState(state)(LS_ITEM.CATEGORIES_LOAD),
  };
};

const connected = connect(
  mapStateToProps,
  {
    setContactFieldsService: _home.actions.setContactFieldsService,
  },
)(JewelleryHome);

export default connected;