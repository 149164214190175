import React from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Button } from "@mui/material";

import { notifSelectors, adminProductSelectors } from "../../logic/rootSelectors";
import _adminProduct from "../../logic/adminProduct";
import { LOADING_STATE, LS_ITEM_ADMIN, FORM_PROP_TYPE } from "../../utils/stateTypes";
import MainContent from "../adminGeneral/MainContent";
import EditForm from "../adminGeneral/EditForm";

const styles = {
  actionButton: {
    marginTop: 8,
    padding: "5px 15px",
    fontSize: "0.7em",
  },
};


const ProductEdit = ({
  productEditing, productsLS, productEditLS,
  setProductEditing, editProductProp, saveProduct, loadProducts,
}) => {
  let { productID, categoryID } = useParams();
  categoryID = parseInt(categoryID);
  productID = parseInt(productID);

  //Set item editing when loaded
  React.useEffect(() => {
    if (productsLS === LOADING_STATE.LOADED) {
      setProductEditing(productID);
    }
  }, [productID, productsLS]);
  const loadCB = React.useCallback(() => {
    loadProducts(categoryID);
  }, [categoryID]);


  return (
    <MainContent loadItems={[{ loadState: productsLS, loadFn: loadCB }]}>
      <h1 className={"page-head"}>EDIT PRODUCT</h1>
      <div className={"admin-divider"} />

      {/* ====================================== FORM ====================================== */}
      {
        (productEditing) && (
          <div className={"standard-box admin-standard-width edit-box"}>
            <div className={"edit-box-half with-border"}>
              <div className={"edit-box-half-inner space-for-button"}>
                <EditForm
                  heading={"Edit Product"}
                  item={productEditing}
                  loadingState={productEditLS}
                  propsToEdit={[
                    { label: "Is Active", prop: "isActive", type: FORM_PROP_TYPE.CHECKBOX },
                    { label: "Name", prop: "name", type: FORM_PROP_TYPE.TEXT },
                    { label: "Model", prop: "model", type: FORM_PROP_TYPE.TEXT },
                    { label: "URL", prop: "url", type: FORM_PROP_TYPE.TEXT },
                    { label: "Preview Image", prop: "previewImage", type: FORM_PROP_TYPE.IMAGE, maxWidth: 1200, isOptional: true },
                    { label: "Preview Video", prop: "previewVideo", type: FORM_PROP_TYPE.VIDEO, isOptional: true },
                    { label: "Product Info", prop: "infoSections", type: FORM_PROP_TYPE.SUBLIST, isOptional: true, subProps: [
                      { label: "Head", prop: "head", type: FORM_PROP_TYPE.TEXT },
                      { label: "Body", prop: "body", type: FORM_PROP_TYPE.MARKDOWN },
                    ] },
                    { label: "Gallery Images", prop: "images", type: FORM_PROP_TYPE.SUBLIST, isOptional: true, subProps: [
                      { label: "Image", prop: "image", type: FORM_PROP_TYPE.IMAGE, maxWidth: 1200 },
                    ] },
                  ]}
                  updatePropBase={editProductProp}
                  savePropEdit={saveProduct}
                />
              </div>
            </div>

            {/* ====================================== PREVIEW ====================================== */}
            <div className={"edit-box-half with-border"}>
              <div className={"edit-box-half-inner centered"}>
                <h3 className={"with-margin-bottom"}>Preview</h3>
                <div className={"product-block-preview standard-box"}>
                  {
                    (productEditing.previewVideo) ? (
                      <video
                        src={productEditing.previewVideo}
                        className={"product-block-preview-bg"}
                        autoPlay
                        loop
                        muted
                      />
                    ) : (
                      <img
                        src={productEditing.previewImage ? productEditing.previewImage : "/images/admin/placeholder-image.png"}
                        className={"product-block-preview-bg"}
                      />
                    )
                  }
                  <div className={"product-block-preview-overlay"}>
                    <p className={"product-block-preview-name"}>{productEditing.name}</p>
                    <p className={"product-block-preview-name-sub"}>{productEditing.model}</p>
                    <Button
                      variant={"contained"}
                      color={"primary"}
                      style={styles.actionButton}
                    >
                        Explore
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
    </MainContent>
  );
};

const mapStateToProps = (state) => {
  return {
    productEditing: adminProductSelectors.getProductEditing(state),
    productsLS: notifSelectors.getLoadState(state)(LS_ITEM_ADMIN.PRODUCTS_LOAD),
    productEditLS: notifSelectors.getLoadState(state)(LS_ITEM_ADMIN.PRODUCT_SAVE),
  };
};

const connected = connect(
  mapStateToProps,
  {
    setProductEditing: _adminProduct.actions.setProductEditing,
    editProductProp: _adminProduct.actions.editProductProp,
    saveProduct: _adminProduct.actions.saveProduct,
    loadProducts: _adminProduct.actions.loadProducts,
  },
)(ProductEdit);

export default connected;

