import "./contact.css";

import React from "react";
import { Helmet } from "react-helmet";

import MainContent from "../general/MainContent";
import BreadCrumbs from "../sections/Breadcrumbs";
import ContactForm from "./ContactForm";


const Contact = () => {
  return (
    <MainContent>
      <Helmet>
        <title>Charles Greig | Contact Us</title>
      </Helmet>

      <BreadCrumbs
        crumbs={[
          { text: "Home", link: "/" },
          { text: "Contact", link: "" },
        ]}
      />

      <section className={"contact-store"}>
        <ContactForm />
      </section>

      <section className={"contact-store"}>
        <div className={"store-header"}>
          <img
            className={"store-header-icon"}
            src={"/images/icon-location.png"}
          />
          <div className={"store-header-divider"} />
          <div className={"store-header-text"}>
            <h1 className={"store-header-name"}>Charles Greig at Hyde Park</h1>
            <h2 className={"store-header-model"}>Johannesburg</h2>
          </div>
        </div>
        
        <div className={"store-content"}>
          <img
            className={"store-img"}
            src={"/images/about/store-hyde-park.jpg"}
          />
          <div className={"store-info"}>
            <h3>Get in touch</h3>
            <div className={"store-info-item"}>
              <img src={"/images/icon-phone2.png"} />
              <p>+27 11 325 4477</p>
            </div>
            <div className={"store-info-item"}>
              <img src={"/images/icon-email.png"} />
              <p>hydepark@charlesgreig.co.za</p>
            </div>

            <h3>Opening hours</h3>
            <p>09:00 - 18:00 Monday to Saturday</p>
            <p>10:00 - 15:00 Sunday</p>
          </div>
        </div>

        <iframe
          src={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3582.199156092812!2d28.03047627541254!3d-26.12504867712544!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e950dfc82286ce9%3A0x32a827a3e3b05c97!2sCharles%20Greig!5e0!3m2!1sen!2snl!4v1698932896807!5m2!1sen!2snl"}
          allowFullScreen={""}
          loading={"lazy"}
          referrerPolicy={"no-referrer-when-downgrade"}
          className={"store-map"}
        />
      </section>

      <section className={"contact-store"}>
        <div className={"store-header"}>
          <img
            className={"store-header-icon"}
            src={"/images/icon-location.png"}
          />
          <div className={"store-header-divider"} />
          <div className={"store-header-text"}>
            <h1 className={"store-header-name"}>Charles Greig at V&A Waterfront</h1>
            <h2 className={"store-header-model"}>Cape Town</h2>
          </div>
        </div>
        
        <div className={"store-content"}>
          <img
            className={"store-img"}
            src={"/images/about/store-va-waterfront.jpg"}
          />
          <div className={"store-info"}>
            <h3>Get in touch</h3>
            <div className={"store-info-item"}>
              <img src={"/images/icon-phone2.png"} />
              <p>+27 21 418 4515</p>
            </div>
            <div className={"store-info-item"}>
              <img src={"/images/icon-email.png"} />
              <p>capetown@charlesgreig.co.za</p>
            </div>

            <h3>Opening hours</h3>
            <p>09:00 - 21:00 Monday to Sunday</p>
          </div>
        </div>

        <iframe
          src={"https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13245.963389414126!2d18.4213157!3d-33.9027645!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1dcc675161001cb5%3A0xf4bbe16c0fc9532a!2sCharles%20Greig!5e0!3m2!1sen!2snl!4v1698933178768!5m2!1sen!2snl"}
          allowFullScreen={""}
          loading={"lazy"}
          referrerPolicy={"no-referrer-when-downgrade"}
          className={"store-map"}
        />
      </section>
    </MainContent>
  );
};

export default Contact;

