import "./home.css";

import React from "react";

import { ADMIN_PAGE_ROUTES } from "../../utils/stateTypes";
import MainContent from "../adminGeneral/MainContent";
import HomeNavBlock from "./HomeNavBlock";


const HomeNav = () => {
  return (
    <MainContent>
      <div className={"home-nav-text"}>
        <h1 className={"admin-page-head"}>WELCOME BACK</h1>
        <div className={"admin-divider"} />
        <p>What would you like to do?</p>
      </div>

      <div className={"home-nav-blocks"}>
        <HomeNavBlock
          imageSrc={"/images/admin/home-nav-1.jpg"}
          subText={"View & Edit"}
          text={"BANNERS"}
          link={ADMIN_PAGE_ROUTES.BANNERS}
        />
        <HomeNavBlock
          imageSrc={"/images/admin/home-nav-2.jpg"}
          subText={"View & Edit"}
          text={"CATEGORIES & PRODUCTS"}
          link={ADMIN_PAGE_ROUTES.CATEGORIES}
        />
        <HomeNavBlock
          imageSrc={"/images/admin/home-nav-3.jpg"}
          subText={"View & Edit"}
          text={"MEDIA PORTAL"}
          link={ADMIN_PAGE_ROUTES.PRESS_EDIT}
        />
      </div>
    </MainContent>
  );
};

export default HomeNav;

