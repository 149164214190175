import "./login.css";

import React from "react";
import { connect } from "react-redux";
import { TextField } from "@mui/material";

import { notifSelectors } from "../../logic/rootSelectors";
import _auth from "../../logic/auth";
import { LS_ITEM } from "../../utils/stateTypes";
import SaveButton from "../adminGeneral/SaveButton";

const styles = {
  input: {
    width: "100%",
    marginBottom: "20px",
    backgroundColor: "#fff",
    borderRadius: "2px",
  },
  signInButton: {
    width: "100%",
    height: 50,
  },
};


const Login = ({ loginLS, login }) => {

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const loginCB = React.useCallback(() => {
    login(email, password);
  }, [email, login, password]);

  return (
    <div className={"login-main"}>
      <div className={"inner-cont"}>
        <img
          src={"/images/logo-dark.png"}
          className={"login-logo"}
        />
        <h1>ADMIN SIGN IN</h1>
      
        <TextField
          label={"Email"}
          variant={"filled"}
          color={"primary"}
          type={"email"}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={styles.input}
        />
        <TextField
          label={"Password"}
          variant={"filled"}
          color={"primary"}
          type={"password"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={styles.input}
        />
        <SaveButton
          label={"SIGN IN"}
          loadingState={loginLS}
          onClick={loginCB}
          size={"large"}
          style={styles.signInButton}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loginLS: notifSelectors.getLoadState(state)(LS_ITEM.LOGIN),
  };
};

const connected = connect(
  mapStateToProps,
  {
    login: _auth.actions.login,
  },
)(Login);

export default connected;

