
import React from "react";
import { Popover } from "@mui/material";
import { Link } from "react-router-dom";

import { PAGE_ROUTES } from "../../utils/stateTypes";


const styles = {
  popover: {
    width: "100%",
    maxWidth: "100%",
    backgroundColor: "#121e0f",
    borderRadius: 0,
  },
};


const ContactDrawer = ({ component, closeComponent, anchorRef }) => {

  //Popover setup
  const [open, setOpen] = React.useState(false);
  const closeCB = React.useCallback(() => {
    setOpen(false);
  }, []);
  const openCB = React.useCallback(() => {
    setOpen(true);
  }, []);


  return (
    <React.Fragment>
      {
        (open) ? (
          React.cloneElement(closeComponent, { onClick: closeCB })
        ) : (
          React.cloneElement(component, { onClick: openCB })
        )
      }
      <Popover
        open={open}
        anchorEl={anchorRef.current}
        onClose={closeCB}
        slotProps={{
          paper: {
            style: styles.popover,
          },
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        marginThreshold={0}
      >
        <div className={"contact-drawer"}>
          <div className={"contact-drawer-section"}>
            <h2>JOHANNESBURG</h2>
            <div className={"contact-drawer-section-inner"}>
              <div className={"contact-drawer-item"}>
                <img src={"/images/icon-phone2.png"} />
                <p>+27 11 325 4477</p>
              </div>
              <div className={"contact-drawer-item"}>
                <img src={"/images/icon-email.png"} />
                <p>hydepark@charlesgreig.co.za</p>
              </div>
              <Link
                to={PAGE_ROUTES.CONTACT}
                onClick={closeCB}
              >
                <div className={"contact-drawer-item"}>
                  <img src={"/images/icon-message.png"} />
                  <p>Message us</p>
                </div>
              </Link>
            </div>
            <div className={"contact-drawer-section-inner"}>
              <div className={"contact-drawer-item"}>
                <img src={"/images/icon-location.png"} />
                <p>Hyde Park Corner</p>
              </div>
              <iframe
                src={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3582.199156092812!2d28.03047627541254!3d-26.12504867712544!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e950dfc82286ce9%3A0x32a827a3e3b05c97!2sCharles%20Greig!5e0!3m2!1sen!2snl!4v1698932896807!5m2!1sen!2snl"}
                allowFullScreen={""}
                loading={"lazy"}
                referrerPolicy={"no-referrer-when-downgrade"}
                className={"contact-drawer-map"}
              />
            </div>
          </div>
          <div className={"contact-drawer-section"}>
            <h2>CAPE TOWN</h2>
            <div className={"contact-drawer-section-inner"}>
              <div className={"contact-drawer-item"}>
                <img src={"/images/icon-phone2.png"} />
                <p>+27 21 418 4515</p>
              </div>
              <div className={"contact-drawer-item"}>
                <img src={"/images/icon-email.png"} />
                <p>capetown@charlesgreig.co.za</p>
              </div>
              <Link
                to={PAGE_ROUTES.CONTACT}
                onClick={closeCB}
              >
                <div className={"contact-drawer-item"}>
                  <img src={"/images/icon-message.png"} />
                  <p>Message us</p>
                </div>
              </Link>
            </div>
            <div className={"contact-drawer-section-inner"}>
              <div className={"contact-drawer-item"}>
                <img src={"/images/icon-location.png"} />
                <p>V&A Waterfront</p>
              </div>
              <iframe
                src={"https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13245.963389414126!2d18.4213157!3d-33.9027645!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1dcc675161001cb5%3A0xf4bbe16c0fc9532a!2sCharles%20Greig!5e0!3m2!1sen!2snl!4v1698933178768!5m2!1sen!2snl"}
                allowFullScreen={""}
                loading={"lazy"}
                referrerPolicy={"no-referrer-when-downgrade"}
                className={"contact-drawer-map"}
              />
            </div>
          </div>
        </div>
      </Popover>

    </React.Fragment>
  );
};

export default ContactDrawer;

