import "./sectionHead.css";

import React from "react";


const SectionHead = ({ preGreenText="", whiteText="", postGreenText="", className="", hasBreaks=false, ...props }) => {
  return (
    <h2
      className={["section-head", className].join(" ")}
      {...props}
    >
      {
        (preGreenText !== "") && (
          <React.Fragment>
            <span className={"pre-green"}>{preGreenText}</span>
            {
              (hasBreaks) ? <br /> : " "
            }
          </React.Fragment>
        )
      }
      
      <span className={"white"}>{whiteText}</span>
      
      {
        (postGreenText !== "") && (
          <React.Fragment>
            {
              (hasBreaks) ? <br /> : " "
            }
            <span className={"post-green"}>{postGreenText}</span>
          </React.Fragment>
        )
      }
    </h2>
  );
};

export default SectionHead;