import "./pageRolex.css";

import React from "react";

window.rlxCornerCallback = (Corner) => {
  const corner = new Corner({ as: "ecorner",lang: "en",consent: true,legal: "https://charlesgreig.azurewebsites.net/privacy-policy" });
  corner.mount("#rlxCorner");
};

const PageRolex = () => {

  //Need to check if the page transition is active (otherwise the corner will be loaded multiple times)
  const hasLoadedScript = React.useRef(false);
  const pageTransitionIsActive = (document.querySelector(".fade-enter") || document.querySelector(".fade-exit"));
  React.useEffect(() => {
    if (pageTransitionIsActive || hasLoadedScript.current) {
      return;
    }

    const script = document.createElement("script");
    script.src = "https://cornersv7.rolex.com/retailer.js?apikey=b27dfdb11608541f9da76e112d181d17&callback=rlxCornerCallback";
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
    hasLoadedScript.current = true;
  }, [pageTransitionIsActive]);
 

  return (
    <React.Fragment>
      <div
        id={"rlxCorner"}
        title={"Official Rolex Retailer"}
      />
      <section className={"rolex-seo-section standard-width"}>
        <h1 className={"rolex-seo-head"}>
          WELCOME TO CHARLES GREIG JEWELLERS
          <br />
          <span>OFFICIAL ROLEX RETAILER</span>
        </h1>
        <p className={"about-outlined"}>
          Charles Greig Jewellers of Johannesburg & Cape Town is proud to be part of the worldwide network of Official Rolex
          Retailers, allowed to sell and maintain Rolex watches. We certify the authenticity of your new Rolex and back its
          five-year international guarantee with the necessary skills, technical know-how and special equipment. Browse the
          Rolex collection above, or simply contact us to book an appointment with our dedicated staff, who can help you make
          the choice that will last a lifetime.
        </p>
      </section>
    </React.Fragment>
  );
};

export default PageRolex;