import "./galleryBlock.css";

import React from "react";

import SectionHead from "../general/SectionHead";


const GalleryBlock = ({ head=null, subHead=null, image1, image2=null, image3=null, isReversed=false }) => {

  return (
    <div className={["gallery-block", isReversed ? "reversed" : ""].join(" ")}>
      {
        (head !== null) && (
          <div className={"gallery-head-cont"}>
            <SectionHead
              className={"gallery-head"}
              preGreenText={head}
              whiteText={subHead}
              hasBreaks={true}
            />
          </div>
        )
      }

      {
        (!isReversed) && (
          <img
            src={image1}
            className={"gallery-img-lg"}
          />
        )
      }
      

      <div>
        {
          (image2) && (
            <img
              src={image2}
              className={"gallery-img-sm"}
            />
          )
        }

        {
          (image3) && (
            <img
              src={image3}
              className={"gallery-img-sm"}
            />
          )
        }

        {
          (isReversed) && (
            <img
              src={image1}
              className={"gallery-img-lg"}
            />
          )
        }
      </div>
    </div>
  );
};

export default GalleryBlock;