
import React from "react";
import { Link } from "react-router-dom";
import { Button, Popover, Skeleton } from "@mui/material";

import { PAGE_ROUTES, LOADING_STATE } from "../../utils/stateTypes";

const styles = {
  popover: {
    width: "100%",
    maxWidth: "100%",
    backgroundColor: "#121e0f",
    borderRadius: 0,
  },
  linkButton: {
    width: "100%",
  },
  linkButtonSkeleton: {
    width: "100%",
    maxWidth: 250,
    height: 25,
    margin: "0 auto 5px auto",
    backgroundColor: "rgba(255, 255, 255, 0.1)",
  },
};


const NavDrawer = ({ component, closeComponent, anchorRef, timepieceCats, jewelleryCats, categoriesLS }) => {

  //Popover setup
  const [open, setOpen] = React.useState(false);
  const closeCB = React.useCallback(() => {
    setOpen(false);
  }, []);
  const openCB = React.useCallback(() => {
    setOpen(true);
  }, []);


  return (
    <React.Fragment>
      {
        (open) ? (
          React.cloneElement(closeComponent, { onClick: closeCB })
        ) : (
          React.cloneElement(component, { onClick: openCB })
        )
      }
      <Popover
        open={open}
        anchorEl={anchorRef.current}
        onClose={closeCB}
        slotProps={{
          paper: {
            style: styles.popover,
          },
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        marginThreshold={0}
      >
        <div className={"nav-drawer"}>
          <img
            className={"nav-drawer-logo"}
            src={"/images/logo-header.png"}
          />
          <div className={"nav-drawer-section"}>
            <Link
              to={PAGE_ROUTES.TIMEPIECES}
              onClick={closeCB}
            >
              <h2>TIMEPIECES</h2>
            </Link>
            <div className={"nav-drawer-links"}>
              {
                (categoriesLS === LOADING_STATE.LOADED) ? (
                  timepieceCats.map((cat) => (
                    <Link
                      to={PAGE_ROUTES.CATEGORY.replace(":categoryUrl", cat.url)}
                      key={cat.id}
                    >
                      <Button
                        onClick={closeCB}
                        style={styles.linkButton}
                        color={"secondary"}
                        size={"small"}
                      >
                        {cat.name}
                      </Button>
                    </Link>
                  ))
                ) : (
                  [0, 1, 2, 3, 4, 5].map((i) => (
                    <Skeleton
                      key={i}
                      variant={"rectangular"}
                      style={styles.linkButtonSkeleton}
                    />
                  ))
                )
              }
            </div>
          </div>
          <div className={"nav-drawer-section"}>
            <Link
              to={PAGE_ROUTES.JEWELLERY}
              onClick={closeCB}
            >
              <h2>JEWELLERY</h2>
            </Link>
            <div className={"nav-drawer-links"}>
              {
                (categoriesLS === LOADING_STATE.LOADED) ? (
                  jewelleryCats.map((cat) => (
                    <Link
                      to={PAGE_ROUTES.CATEGORY.replace(":categoryUrl", cat.url)}
                      key={cat.id}
                    >
                      <Button
                        onClick={closeCB}
                        style={styles.linkButton}
                        color={"secondary"}
                        size={"small"}
                      >
                        {cat.name}
                      </Button>
                    </Link>
                  ))
                ) : (
                  [0, 1, 2, 3, 4, 5].map((i) => (
                    <Skeleton
                      key={i}
                      variant={"rectangular"}
                      style={styles.linkButtonSkeleton}
                    />
                  ))
                )
              }
            </div>
          </div>
          <div className={"nav-drawer-section"}>
            <h2>ABOUT US</h2>
            <div className={"nav-drawer-links"}>
              <Link to={PAGE_ROUTES.LEGACY}>
                <Button
                  onClick={closeCB}
                  style={styles.linkButton}
                  color={"secondary"}
                  size={"small"}
                >
                  Our Legacy
                </Button>
              </Link>
              <Link to={PAGE_ROUTES.TEAM}>
                <Button
                  onClick={closeCB}
                  style={styles.linkButton}
                  color={"secondary"}
                  size={"small"}
                >
                  Meet our team
                </Button>
              </Link>
              <Link to={PAGE_ROUTES.PARTNERS}>
                <Button
                  onClick={closeCB}
                  style={styles.linkButton}
                  color={"secondary"}
                  size={"small"}
                >
                  The Charles Greig Gallery
                </Button>
              </Link>
              {/* <Link to={PAGE_ROUTES.GROUP}>
                <Button
                  onClick={closeCB}
                  style={styles.linkButton}
                  color={"secondary"}
                  size={"small"}
                >
                  Our Initiatives
                </Button>
              </Link> */}
              <Link to={PAGE_ROUTES.PRESS}>
                <Button
                  onClick={closeCB}
                  style={styles.linkButton}
                  color={"secondary"}
                  size={"small"}
                >
                  Media Portal
                </Button>
              </Link>
              <Link to={PAGE_ROUTES.CONTACT}>
                <Button
                  onClick={closeCB}
                  style={styles.linkButton}
                  color={"secondary"}
                  size={"small"}
                >
                  Contact us
                </Button>
              </Link>
              <Link to={PAGE_ROUTES.PRIVACY_POLICY}>
                <Button
                  onClick={closeCB}
                  style={styles.linkButton}
                  color={"secondary"}
                  size={"small"}
                >
                  Privacy Policies
                </Button>
              </Link>
            </div>
          </div>
          <span className={"nav-drawer-clock"}>
            <Link to={PAGE_ROUTES.CATEGORY.replace(":categoryUrl", "rolex")}>
              <iframe
                title={"Rolex Official Retailer"}
                src={"https://static.rolex.com/retailers/clock/?colour=gold&apiKey=b27dfdb11608541f9da76e112d181d17&lang=en"}
                className={"menu-rolex-clock"}
                scrolling={"no"}
              />
            </Link>
          </span>
        </div>
      </Popover>

    </React.Fragment>
  );
};

export default NavDrawer;

