import "./pageBreitling.css";

import React from "react";

const PageBreitling = () => {
  const startBreitlingCorner = () => {
    let firstScriptTag = document.getElementsByTagName("script")[0];

    let scriptTag1 = document.createElement("script");
    scriptTag1.type = "application/json";
    scriptTag1.id = "_breitling_tracking";
    scriptTag1.innerHTML = "{ \"name\": \"Homepage\", \"type\": \"index\" }";

    let scriptTag2 = document.createElement("script");
    scriptTag2.src = "https://retailers.breitling.com/integrator/register/?r=415badff74ecb419c3bf6f9fab8bc831&t=script";
    scriptTag2.async = true;

    firstScriptTag.parentNode.insertBefore(scriptTag1, firstScriptTag);
    firstScriptTag.parentNode.insertBefore(scriptTag2, firstScriptTag);
  };

  React.useEffect(() => {
    startBreitlingCorner();
  }, []);

  return (
    <React.Fragment>
      <div className={"w-embed w-iframe w-script"}>
        <div
          id={"_breitling_integrator"}
          style={{
            position: "relative",
          }}
        >
        </div>
      </div>

      <section className={"breitling-seo-section standard-width"}>
        <h1 className={"breitling-seo-head"}>
          CHARLES GREIG JEWELLERS
          <br />
          <span>OFFICIAL BREITLING RETAILER</span>
        </h1>
        <p className={"about-outlined"}>
          Visit Charles Greig Jewellers at either our Johannesburg or Cape Town store to view
          the newest collections from Breitling. Discover a brand that is obsessed with quality,
          resulting in beautifully crafted and precise watches.
        </p>
      </section>
    </React.Fragment>
  );
};

export default PageBreitling;