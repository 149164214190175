import React from "react";

import SectionHead from "../general/SectionHead";


const TeamBlock = ({ image, firstName, surname, position }) => {
  return (
    <div className={"team-block"}>
      <img
        src={image}
        alt={`${firstName} ${surname}`}
      />
      <SectionHead
        className={"team-name"}
        whiteText={firstName}
        postGreenText={surname}
      />
      <p className={"team-position"}>{position}</p>
    </div>
  );
};

export default TeamBlock;