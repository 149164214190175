import * as actionType from "./actionTypes";
import { getRequestAuthed, postRequestAuthed, processError, prepPostData } from "../../utils/apiHandlers";
import { LOADING_STATE, LS_ITEM_ADMIN, NOTIF_TYPE } from "../../utils/stateTypes";
import { deleteItemLocal, editItemProp, setItemEditing, updateItemLocal } from "../../utils/actionUtils";
import { adminBannerSelectors } from "../rootSelectors";
import _notif from "../notification";


export const loadBanners = () => (
  (dispatch) => {
    const lsItem = LS_ITEM_ADMIN.BANNERS_LOAD;
    dispatch(_notif.actions.setLoadState(lsItem, LOADING_STATE.LOADING));
    getRequestAuthed("/AdminBanner/GetBanners",
      (banners) => {
        const formattedBanners = {
          byID: {},
          allIDs: [],
        };
        
        for (let banner of banners) {
          formattedBanners.byID[banner.id] = banner;
          formattedBanners.allIDs.push(banner.id);
        }

        dispatch({
          type: actionType.RECEIVE_BANNERS,
          banner: formattedBanners,
        });
        dispatch(_notif.actions.setLoadState(lsItem, LOADING_STATE.LOADED));
      },
      processError(dispatch, _notif, lsItem),
    );
  }
);

export const setBannerEditing = setItemEditing(actionType.SET_BANNER_EDITING);
export const editBannerProp = editItemProp(actionType.EDIT_BANNER_PROP);
const updateBannerLocal = updateItemLocal(actionType.UPDATE_BANNER, "banner");
const deleteBannerLocal = deleteItemLocal(actionType.DELETE_BANNER);

export const addBanner = () => (
  (dispatch) => {
    let lsItem = LS_ITEM_ADMIN.BANNER_ADD;
    dispatch(_notif.actions.setLoadState(lsItem, LOADING_STATE.LOADING));
    getRequestAuthed("/AdminBanner/AddBanner",
      (banner) => {
        dispatch(updateBannerLocal(banner, true));
        dispatch(_notif.actions.queueNotification("Banner added", NOTIF_TYPE.SUCCESS));
        dispatch(_notif.actions.setLoadState(lsItem, LOADING_STATE.LOADED));
      },
      processError(dispatch, _notif, lsItem),
    );
  }
);

export const saveBanner = () => (
  (dispatch, getState) => {
    const banner = adminBannerSelectors.getBannerEditing(getState());

    let postData = prepPostData(banner, ["imageLg", "imageSm"]);

    let lsItem = LS_ITEM_ADMIN.BANNER_SAVE;
    dispatch(_notif.actions.setLoadState(lsItem, LOADING_STATE.LOADING));
    postRequestAuthed("/AdminBanner/SaveBanner",
      postData,
      (banner) => {
        dispatch(updateBannerLocal(banner));
        dispatch(setBannerEditing(banner.id));

        dispatch(_notif.actions.queueNotification("Banner updated", NOTIF_TYPE.SUCCESS));
        dispatch(_notif.actions.setLoadState(lsItem, LOADING_STATE.LOADED));
      },
      processError(dispatch, _notif, lsItem),
    );
  }
);

export const deleteBanner = (id, onSuccess=null) => (
  (dispatch) => {
    let lsItem = `${LS_ITEM_ADMIN.BANNER_DELETE}${id}`;
    dispatch(_notif.actions.setLoadState(lsItem, LOADING_STATE.LOADING));
    postRequestAuthed("/AdminBanner/DeleteBanner",
      id,
      () => {
        dispatch(deleteBannerLocal(id));

        dispatch(_notif.actions.queueNotification("Banner deleted", NOTIF_TYPE.SUCCESS));
        dispatch(_notif.actions.setLoadState(lsItem, LOADING_STATE.LOADED));
        if (onSuccess) {
          onSuccess();
        }
      },
      processError(dispatch, _notif, lsItem),
    );
  }
);

export const reorderBanners = (startIndex, endIndex) => (
  (dispatch, getState) => {
    //Get the currently sorted items
    const sortedIDs = adminBannerSelectors.getBanners(getState()).map((item) => item.id);
    let newOrder = [...sortedIDs];
    //Move from the start index to the end index (and get the moved itemID in the process)
    const [itemID] = newOrder.splice(startIndex, 1);
    newOrder.splice(endIndex, 0, itemID);

    //Save the order locally
    dispatch({
      type: actionType.REORDER_BANNERS,
      newOrder,
    });

    const lsItem = `${LS_ITEM_ADMIN.BANNER_REORDER}${itemID}`;
    dispatch(_notif.actions.setLoadState(lsItem, LOADING_STATE.LOADING));
    postRequestAuthed("/AdminBanner/ReorderBanners",
      {
        startIndex,
        endIndex,
      },
      () => {
        dispatch(_notif.actions.queueNotification("Re-ordered successfully", NOTIF_TYPE.SUCCESS));
        dispatch(_notif.actions.setLoadState(lsItem, LOADING_STATE.LOADED));
      },
      processError(dispatch, _notif, lsItem, () => {
        //Undo the local change if the server request fails
        dispatch({
          type: actionType.REORDER_BANNERS,
          newOrder: sortedIDs,
        });
      }),
    );
  }
);