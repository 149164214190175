import * as actionType from "./actionTypes";
import { getRequestAuthed, postRequest, processError, setGetState, setAuthTokenDispatch } from "../../utils/apiHandlers";
import { LOADING_STATE, LS_ITEM, NOTIF_TYPE } from "../../utils/stateTypes";
import _notif from "../notification";
import { storeTokens, clearTokens, getToken } from "../localStorage";

/* =========================== AUTH =========================== */

export const setAuthToken = (token) => (
  {
    type: actionType.SET_AUTH_TOKEN,
    token,
  }
);

export const setHasCheckedAuth = () => (
  {
    type: actionType.SET_HAS_CHECKED_AUTH,
  }
);

export const initAuth = () => (
  (dispatch, getState) => {
    // Send store methods to api class so that auth token can be managed
    setGetState(getState);
    setAuthTokenDispatch((authToken) => dispatch(setAuthToken(authToken)));

    const checkAuthed = async () => {
      //Check if the current JWT is valid (will automatically refresh if needed)
      const authToken = await getToken();
      if (!authToken) {
        //No auth token saved, set that we've checked the auth token, app will show login screen
        dispatch(setHasCheckedAuth());
      }
      else {
        //Make an authorized call to the server to see if its valid (or refresh it)
        //Both success & error callbacks can hide the loading screen since the auth token will have been saved / discarded by the token middleware and the correct screen will display
        getRequestAuthed("/Account/CheckToken",
          async () => {
            const latestAuthToken = await getToken();
            dispatch(setAuthToken(latestAuthToken));  //Take the latest token from the store and put it into state (for incase we didn't need to refresh the token, which would have handled this step)
            dispatch(setHasCheckedAuth());
          },  
          () => dispatch(setHasCheckedAuth()),
          () => {
            //Timeout handler - Clear the tokens and notify the user that the app requires internet
            dispatch(setAuthToken(null));
            clearTokens().then(() => {
              dispatch(setHasCheckedAuth());
              dispatch(_notif.actions.queueNotification("Unable to connect", NOTIF_TYPE.ERROR));
            });
          },
          authToken,
        );
      }
    };
    checkAuthed();
  }
);

/* =========================== LOGIN =========================== */

export const login = (email, password) => (
  (dispatch) => {
    const lsItem = LS_ITEM.LOGIN;
    dispatch(_notif.actions.setLoadState(lsItem, LOADING_STATE.LOADING));
    
    postRequest("/Account/AdminLogin",
      {
        email,
        password,
      },
      (tokens) => {
        //Login success
        storeTokens(tokens.token, tokens.refreshToken);
        dispatch(setAuthToken(tokens.token));
        dispatch(_notif.actions.setLoadState(lsItem, LOADING_STATE.NOT_LOADING));
      },
      processError(dispatch, _notif, lsItem),
    );
  }
);

/* =========================== LOGOUT =========================== */

export const logout = () => (
  (dispatch) => {
    postRequest("/Account/Logout",
      {},
      () => {/* Do nothing */},
      () => {/* Do nothing */},
    );
    dispatch(setAuthToken(null));
    clearTokens();
    dispatch({
      type: actionType.LOGOUT,
    });
    setTimeout(() => {
      dispatch(setHasCheckedAuth());
    }, 100);
  }
);