
/*
*  Utilities used to make API calls
*/

import { NOTIF_TYPE, LOADING_STATE } from "./stateTypes";
import { authSelectors } from "../logic/rootSelectors";
import { getRefreshToken, storeTokens, clearTokens } from "../logic/localStorage";

// This gets set at app start up (we need this so that auth tokens can be managed by the api methods)
let getState;
let authTokenDispatch;

export const setGetState = (storeGetState) => {
  getState = storeGetState;
};

export const setAuthTokenDispatch = (storeAuthTokenDispatch) => {
  authTokenDispatch = storeAuthTokenDispatch;
};

/* Used to check the HTTP return status for error codes
*  If it find an error code it will throw the error
*  Otherwise it will propogate the response
*/
const checkStatus = (response) => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const error = new Error(response.statusText);
  error.response = response;
  throw error;
};

/* Performs a get request with the provided url. Returns the JSON response
*  Returns a promise that will resolve in one of the following:
*  - the success handler if the api call succeeds
*  - the error handler if the api call fails
*/
export const getRequestAuthed = (url, successHandler, errorHandler, timeoutHandler=null, authTokenOverride=null) => {
  //Note that this function is not pure, it gets the auth token from the store and the refresh token from storage

  const authToken = (authTokenOverride !== null) ? authTokenOverride : authSelectors.getAuthToken(getState());

  let headers = {};
  headers["Authorization"] = "Bearer " + authToken;

  //Set abort timeout
  // eslint-disable-next-line no-undef
  const controller = new AbortController();
  const signal = controller.signal;
  setTimeout(() => { 
    controller.abort();
  }, 15000);

  return fetch(`/api${url}`, {
    method: "GET",
    headers,
    signal,
  })
    .then(checkStatus)
    .then(
      //Success
      (response) => {
        return response.json().then(
          (jsonData) => () => successHandler(jsonData), //Could parse json
          () => successHandler,  //Could not parse json
        );
      },
      //Failure
      (error) => {
        if ((timeoutHandler !== null) && signal.aborted) {
          //Request timed out, handle with the timeout handler
          return timeoutHandler;
        }
        if ((error.response) && (error.response.status === 401) && (error.response.headers.has("Token-Expired"))) {
          //The token has expired, try to refresh it and then repeat the original request
          refreshJWT(authToken)
            .then(() => getRequest(url, successHandler, errorHandler))
            .catch(errorHandler);
          return () => {/* Do nothing here since the refreshJWT promise has taken over} */};
        }
        return errorHandler;
      },
    )
    .then((callback) => callback());
};

/* Performs a post request with the provided url and data. Returns the JSON response
*  Returns a promise that will resolve in one of the following:
*  - the success handler if the api call succeeds
*  - the error handler if the api call fails
*/
export const postRequestAuthed = (url, data, successHandler, errorHandler, timeoutHandler=null) => {
  const authToken = authSelectors.getAuthToken(getState());

  let headers = {
    "Content-Type": "application/json",
    "Authorization": "Bearer " + authToken,
  };

  //Set abort timeout
  // eslint-disable-next-line no-undef
  const controller = new AbortController();
  const signal = controller.signal;
  setTimeout(() => { 
    controller.abort();
  }, 15000);

  return fetch(`/api${url}`, {
    method: "POST",
    body: JSON.stringify(data),
    headers,
    signal,
  })
    .then(checkStatus)
    .then(
      //Success
      (response) => {
        return response.json().then(
          (jsonData) => () => successHandler(jsonData), //Could parse json
          () => successHandler,  //Could not parse json
        );
      },
      //Failure
      (error) => {
        if ((timeoutHandler !== null) && signal.aborted) {
          //Request timed out, handle with the timeout handler
          return timeoutHandler;
        }
        if ((error.response.status === 401) && (error.response.headers.has("Token-Expired"))) {
          //The token has expired, try to refresh it and then repeat the original request
          refreshJWT(authToken)
            .then(() => postRequest(url, data, successHandler, errorHandler))
            .catch(errorHandler);
          return () => {/* Do nothing here since the refreshJWT promise has taken over} */};
        }

        return error.response.json().then(
          (jsonData) => () => errorHandler(jsonData), //Could parse json
          () => errorHandler,  //Could not parse json
        );
      },
    )
    .then((callback) => callback());
};

export const getRequest = (url, successHandler, errorHandler, timeoutHandler=null) => {
  let headers = {};

  //Set abort timeout
  // eslint-disable-next-line no-undef
  const controller = new AbortController();
  const signal = controller.signal;
  setTimeout(() => { 
    controller.abort();
  }, 15000);

  return fetch(`/api${url}`, {
    method: "GET",
    headers,
    signal,
  })
    .then(checkStatus)
    .then(
      //Success
      (response) => {
        return response.json().then(
          (jsonData) => () => successHandler(jsonData), //Could parse json
          () => successHandler,  //Could not parse json
        );
      },
      //Failure
      (error) => {
        if ((timeoutHandler !== null) && signal.aborted) {
          //Request timed out, handle with the timeout handler
          return timeoutHandler;
        }
        if (!error.response) {
          return errorHandler;
        }
        return error.response.json().then(
          (jsonData) => () => errorHandler(jsonData), //Could parse json
          () => errorHandler,  //Could not parse json
        );
      },
    )
    .then((callback) => callback());
};

//Does a post request without an auth token
export const postRequest = (url, data, successHandler, errorHandler, timeoutHandler=null) => {
  let headers = {
    "Content-Type": "application/json",
  };

  //Set abort timeout
  // eslint-disable-next-line no-undef
  const controller = new AbortController();
  const signal = controller.signal;
  setTimeout(() => { 
    controller.abort();
  }, 15000);

  return fetch(`/api${url}`, {
    method: "POST",
    body: JSON.stringify(data),
    headers,
    signal,
  })
    .then(checkStatus)
    .then(
      //Success
      (response) => {
        return response.json().then(
          (jsonData) => () => successHandler(jsonData), //Could parse json
          () => successHandler,  //Could not parse json
        );
      },
      //Failure
      (error) => {
        if ((timeoutHandler !== null) && signal.aborted) {
          //Request timed out, handle with the timeout handler
          return timeoutHandler;
        }
        if (!error.response) {
          return errorHandler;
        }
        return error.response.json().then(
          (jsonData) => () => errorHandler(jsonData), //Could parse json
          () => errorHandler,  //Could not parse json
        );
      },
    )
    .then((callback) => callback());
};

//Attempt to refresh the token
const refreshJWT = async (oldToken) => {
  const refreshToken = await getRefreshToken();

  if (!refreshToken) {
    await clearTokens();
    authTokenDispatch(null);
    throw new Error("No refresh token");
  }

  const headers = {
    "Content-Type": "application/json",
  };
  const response = await fetch("/Account/Refresh", {
    method: "POST",
    body: JSON.stringify({
      token: oldToken,
      refreshToken,
    }),
    headers,
  });

  if (response.ok) {
    const tokens = await response.json();
    await storeTokens(tokens.token, tokens.refreshToken);
    authTokenDispatch(tokens.token);
    return;
  }

  await clearTokens();
  authTokenDispatch(null);
  throw new Error("Could not successfully refresh the token");
};


export const processError = (dispatch, _notif, lsItem=null, callback=null, skipErrorNotif=false) => (
  (errorResponse) => {
    //Check if there is an error response
    if (errorResponse && errorResponse.errors) {
      //Model validation error
      for (let inputName in errorResponse.errors) {
        dispatch(_notif.actions.queueNotification(errorResponse.errors[inputName][0], NOTIF_TYPE.ERROR));
      }
    }
    else if (errorResponse && errorResponse.errorText) {
      //Notify the error text,
      dispatch(_notif.actions.queueNotification(errorResponse.errorText, NOTIF_TYPE.ERROR));
    }
    else {
      //Something broke, show generic error text
      if (!skipErrorNotif) {
        dispatch(_notif.actions.queueNotification("Could not complete action.", NOTIF_TYPE.ERROR));
      }
    }
    if (lsItem !== null) {
      dispatch(_notif.actions.setLoadState(lsItem, LOADING_STATE.ERROR));
    }
    if (callback !== null) {
      callback();
    }
  }
);

//This function preps the data to be sent via post in the standard format
export const prepPostData = (item, fileProps=[], arrPropsToClear=[], moveToObjID=[]) => {
  let formattedItem = {
    ...item,
  };

  //Clear out the array props
  for (let curProp of arrPropsToClear) {
    formattedItem[curProp] = null;
  }

  //Format the new files in a separate array
  let newFiles = [];
  for (let curProp of fileProps) {
    if ((formattedItem[curProp] !== null) && (formattedItem[curProp].indexOf("data:") === 0)) {
      newFiles.push({
        fileBase64: formattedItem[curProp].split(",")[1],  //'split' removes the data type from before the base64 string
        fileType: formattedItem[curProp].split(",")[0].split(";")[0].split(":")[1],
        fileName: formattedItem[`${curProp}FileName`],
        propName: capitalize(curProp),  //Capitalize to match the server prop name
      });
      formattedItem[curProp] = "new";
    }
  }

  //Move the prop ID into an object with an ID prop
  for (let moveToObjProp of moveToObjID) {
    formattedItem[moveToObjProp] = {
      id: formattedItem[moveToObjProp],
    };
  }

  return {
    item: formattedItem,
    newFiles,
  };
};

const capitalize = (s) => (
  s[0].toUpperCase() + s.slice(1)
);