import * as actionType from "./actionTypes";
import { uniqueID } from "../../utils/generalUtils";
import { NOTIF_TYPE } from "../../utils/stateTypes";

// Dispatched when there is a a new notification to queue
export const queueNotification = (notifText, type=NOTIF_TYPE.STANDARD) => {
  return {
    type: actionType.QUEUE_NOTIFICATION,
    notification: {
      text: notifText,
      id: uniqueID(),
      type,
    },
  };
};

// Dispatched when a notification is dismissed (removes the first notification in the queue)
export const removeNotification = () => {
  return {
    type: actionType.REMOVE_NOTIFICATION,
  };
};

export const setLoadState = (loadName, loadState) => (
  {
    type: actionType.SET_LOAD_STATE,
    loadName,
    loadState,
  }
);

export const resetLoadingState = (lsExceptions=[]) => (
  {
    type: actionType.RESET_LOADING_STATE,
    lsExceptions,
  }
);