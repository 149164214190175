import "./noResults.css";

import React from "react";

/* ======================================== COMPONENT ======================================== */

const NoResults = ({ label="No results found" }) => {
  return (
    <div className={"no-results-cont"}>
      <p className={"no-results-label"}>{label}</p>
      <img
        src={"/images/no-results-placeholder.png"}
        className={"no-results-img"}
      />
    </div>
  );
};

export default NoResults;