import "./socials.css";

import React from "react";
import { IconButton } from "@mui/material";
import { Facebook, Instagram } from "@mui/icons-material";

import SectionHead from "../general/SectionHead";

const styles = {
  socialButton: {
    width: "45%",
    height: "100%",
  },
  socialIcon: {
    width: "100%",
    height: "100%",
  },
};

const Socials = () => {

  return (
    <section className={"socials-section"}>
      <SectionHead
        className={"section-head-standard"}
        preGreenText={"Stay connected"}
        whiteText={"with us"}
      />
      <div className={"socials-cont"}>
        <IconButton
          color={"secondary"}
          style={styles.socialButton}
          href={"https://www.facebook.com/Charles-Greig-Jewellers-626104687528655/"}
          target={"_blank"}
        >
          <Facebook style={styles.socialIcon} />
        </IconButton>
        <IconButton
          color={"secondary"}
          style={styles.socialButton}
          href={"https://www.instagram.com/charles_greig_jewellers/"}
          target={"_blank"}
        >
          <Instagram style={styles.socialIcon} />
        </IconButton>
      </div>
    </section>
  );
};

export default Socials;