import React from "react";
import { Button, CircularProgress } from "@mui/material";

import { LOADING_STATE } from "../../utils/stateTypes";

const styles = {
  saveButton: {
    maxWidth: 300,
    width: "80%",
    margin: "20px auto 0 auto",
    height: 40,
  },
  fixedFormButton: {
    position: "absolute",
    width: "100%",
    maxWidth: "100%",
    bottom: 0,
    left: 0,
  },
};

/* ======================================== COMPONENT ======================================== */

const SaveButton = ({ label="Save Changes", loadingState=LOADING_STATE.NOT_LOADING, disabled=false, fixedFormPos=false, onClick=null, contStyle={}, ...props }) => {
  let style = styles.saveButton;
  if (fixedFormPos) {
    style = {
      ...style,
      ...styles.fixedFormButton,
    };
  }
  return (
    <div style={contStyle}>
      <Button
        variant={"contained"}
        color={"primary"}
        style={style}
        type={"submit"}
        disabled={disabled || (loadingState === LOADING_STATE.LOADING)}
        onClick={onClick}
        {...props}
      >
        {(loadingState === LOADING_STATE.LOADING) ? (
          <CircularProgress
            size={25}
            color={"secondary"}
          />
        ) : label}
      </Button>
    </div>
  );
};

export default SaveButton;