import "./group.css";

import React from "react";
import { Helmet } from "react-helmet";

import MainContent from "../general/MainContent";
import BreadCrumbs from "../sections/Breadcrumbs";
import HeadBanner from "../sections/HeadBanner";
import InfoSection from "../sections/InfoSection";
import SectionHead from "../general/SectionHead";


const Group = () => {
  return (
    <MainContent>
      <Helmet>
        <title>Charles Greig | Our Initiatives</title>
      </Helmet>
      
      <HeadBanner
        imageLg={"/images/about/temp.jpg"}
        headPreGreen={"Our"}
        headWhite={"Initiatives"}
        buttonLabel={"Explore"}
        imageClass={"about-banner-img"}
      />

      <BreadCrumbs
        crumbs={[
          { text: "Home", link: "/" },
          { text: "Our Initiatives", link: "" },
        ]}
      />

      <section className={"about-section standard-width"}>
        <SectionHead
          className={"section-head-standard"}
          whiteText={"Heading"}
        />
        <p className={"about-text"}>
          Text 1.
        </p>
        <p className={"about-outlined"}>
          Text 2.
        </p>
      </section>

      <InfoSection
        info={{
          image: "/images/no-results-placeholder.png",
          headPreGreen: "Initiative",
          headWhite: "One",
          subHead: `
            Text.
          `,
        }}
      />

      <InfoSection
        info={{
          image: "/images/no-results-placeholder.png",
          headPreGreen: "Initiative",
          headWhite: "Two",
          subHead: `
            Text.
          `,
        }}
        isVariant={true}
      />

      <InfoSection
        info={{
          image: "/images/no-results-placeholder.png",
          headPreGreen: "Initiative",
          headWhite: "Three",
          subHead: `
            Text.
          `,
        }}
      />
    </MainContent>
  );
};

export default Group;