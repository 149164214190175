import React from "react";
import { Link } from "react-router-dom";

/* ======================================== COMPONENT ======================================== */

const HomeNavBlock = ({ imageSrc, subText, text, link }) => {
  return (
    <Link to={link}>
      <div className={"home-nav-block admin-standard-box"}>
        <img src={imageSrc} />
        <div className={"home-nav-block-text"}>
          <p>{subText}</p>
          <h3>{text}</h3>
        </div>
      </div>
    </Link>
  );
};

export default HomeNavBlock;