import React from "react";
import { Switch, FormControlLabel, FormControl } from "@mui/material";
import { ChromePicker } from "react-color";


const styles = {
  input: {
    width: "80%",
    margin: "10px auto",
  },
  innerCheckboxForm: {
    width: "100%",
  },
  colorPicker: {
    width: "60%",
  },
};

const EditFormColorInput = ({ item, propToEdit, updatePropStraightValue }) => {
  //Set up the onChange callbacks (one for the color picker, one for the 'custom color' toggle switch)
  const updateColorCB = updatePropStraightValue(propToEdit.prop);
  const updateColorVal = React.useCallback((newColorObj) => {
    if (propToEdit.allowAlpha) {
      updateColorCB(`rgba(${newColorObj.rgb.r},${newColorObj.rgb.g},${newColorObj.rgb.b},${newColorObj.rgb.a})`);
      return;
    }
    updateColorCB(newColorObj.hex);
  }, [updateColorCB, propToEdit.allowAlpha]);
  const toggleCustomColor = React.useCallback((e) => {
    if (e.target.checked) {
      updateColorCB("#000000");
      return;
    }
    updateColorCB(null);
  }, [updateColorCB]);

  return (
    <FormControl style={styles.input}>
      <p className={"custom-input-edit-head"}>{propToEdit.label}{(!propToEdit.isOptional) ? " *" : ""}</p>
      <div className={"edit-form-field-input-cont"}>
        {
          (propToEdit.isOptional) ? (
            <FormControlLabel
              style={styles.innerCheckboxForm}
              control={
                <Switch
                  checked={(item[propToEdit.prop] !== null)}
                  color={"primary"}
                  onChange={toggleCustomColor}
                />
              }
              label={"Custom value"}
            />
          ) : null
        }
        {
          (item[propToEdit.prop] !== null) ? (
            <ChromePicker
              color={item[propToEdit.prop]}
              onChangeComplete={updateColorVal}
              disableAlpha={(!propToEdit.allowAlpha)}
              styles={{
                default: {
                  picker: styles.colorPicker,
                },
              }}
            />
          ) : null
        }
        
      </div>
    </FormControl>
  );
};

export default EditFormColorInput;