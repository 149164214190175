import "./timepiecesHome.css";

import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import { homeSelectors, notifSelectors } from "../../logic/rootSelectors";
import _home from "../../logic/home";
import { LS_ITEM, PAGE_ROUTES } from "../../utils/stateTypes";
import MainContent from "../general/MainContent";
import HeadBanner from "../sections/HeadBanner";
import Breadcrumbs from "../sections/Breadcrumbs";
import InfoSection from "../sections/InfoSection";
import CategoryBlock from "./CategoryBlock";


const TimepiecesHome = ({
  timepieceCats, categoriesLS,
  setContactFieldsService,
}) => {

  return (
    <MainContent loadItems={[{ loadState: categoriesLS, loadFn: () => {} }]}>
      <Helmet>
        <title>Charles Greig | Luxury Timepieces</title>
      </Helmet>

      <HeadBanner
        imageLg={"/images/Timepieces-header.jpg"}
        headPreGreen={"Shop"}
        headWhite={"timepieces"}
      />

      <Breadcrumbs
        crumbs={[
          { text: "Home", link: "/" },
          { text: "Timepieces", link: "" },
        ]}
      />

      <section className={"categories-section"}>
        {
          timepieceCats.map((category) => {
            return (
              <CategoryBlock
                key={category.id}
                category={category}
              />
            );
          })
        }
      </section>

      <InfoSection
        info={{
          image: "/images/info/Timepieces-Info-1.jpg",
          headPreGreen: "Restoration",
          headWhite: "& maintenance",
          subHead: "In need of meticulous repair, cleaning or refurbishment of a timepiece? Esnsure they regain their former glory and functionality with us.",
          linkUrl: PAGE_ROUTES.CONTACT,
          linkLabel: "Book a service",
          linkOnClick: setContactFieldsService,
        }}
        isVariant={true}
      />
    </MainContent>
  );
};

const mapStateToProps = (state) => {
  return {
    timepieceCats: homeSelectors.getCategoriesTimepieces(state),
    categoriesLS: notifSelectors.getLoadState(state)(LS_ITEM.CATEGORIES_LOAD),
  };
};

const connected = connect(
  mapStateToProps,
  {
    setContactFieldsService: _home.actions.setContactFieldsService,
  },
)(TimepiecesHome);

export default connected;