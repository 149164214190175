
import React from "react";
import { useNavigate } from "react-router-dom";
import { Popover, IconButton, TextField } from "@mui/material";

import { PAGE_ROUTES } from "../../utils/stateTypes";

const styles = {
  popover: {
    width: "100%",
    maxWidth: "100%",
    backgroundColor: "#121e0f",
    borderRadius: 0,
  },
  iconButton: {
    width: 50,
    height: 50,
  },
  textField: {
    flexGrow: 1,
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    marginLeft: 10,
  },
};


const SearchDrawer = ({ component, closeComponent, anchorRef }) => {
  const navigate = useNavigate();

  //Popover setup
  const [open, setOpen] = React.useState(false);
  const closeCB = React.useCallback(() => {
    setOpen(false);
  }, []);
  const openCB = React.useCallback(() => {
    setOpen(true);
  }, []);


  //Search setup
  const [searchText, setSearchText] = React.useState("");
  const searchCB = React.useCallback((event) => {
    //Check if enter was pressed, or if search button was clicked
    if ((event.key === "Enter") || (event.type === "click")) {
      if (searchText !== "") {
        navigate(PAGE_ROUTES.SEARCH.replace(":searchQuery", searchText));
        setSearchText("");
      }
      closeCB();
      event.preventDefault();
    }
  }, [closeCB, searchText, navigate]);


  return (
    <React.Fragment>
      {
        (open) ? (
          React.cloneElement(closeComponent, { onClick: closeCB })
        ) : (
          React.cloneElement(component, { onClick: openCB })
        )
      }
      <Popover
        open={open}
        anchorEl={anchorRef.current}
        onClose={closeCB}
        slotProps={{
          paper: {
            style: styles.popover,
          },
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        marginThreshold={0}
      >
        <div className={"search-drawer"}>
          <IconButton
            onClick={searchCB}
            color={"secondary"}
            style={styles.iconButton}
          >
            <img
              src={"/images/icon-search.png"}
              className={"menu-icon"}
            />
          </IconButton>
          <TextField
            label={"Search our site"}
            variant={"filled"}
            color={"secondary"}
            value={searchText}
            onChange={(event) => setSearchText(event.target.value)}
            onKeyDown={searchCB}
            style={styles.textField}
            autoFocus={true}
          />
        </div>
      </Popover>

    </React.Fragment>
  );
};

export default SearchDrawer;

