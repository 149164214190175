import * as actionType from "./actionTypes";
import { getRequestAuthed, postRequestAuthed, processError, prepPostData } from "../../utils/apiHandlers";
import { LOADING_STATE, LS_ITEM_ADMIN, NOTIF_TYPE } from "../../utils/stateTypes";
import { deleteItemLocal, editItemProp, setItemEditing, updateItemLocal } from "../../utils/actionUtils";
import { adminProductSelectors } from "../rootSelectors";
import _notif from "../notification";


export const loadProducts = (categoryID) => (
  (dispatch) => {
    const lsItem = LS_ITEM_ADMIN.PRODUCTS_LOAD;
    dispatch(_notif.actions.setLoadState(lsItem, LOADING_STATE.LOADING));
    getRequestAuthed(`/AdminProduct/GetProducts/${categoryID}`,
      (products) => {
        const formattedProduct = {
          byID: {},
          allIDs: [],
        };
        
        for (let product of products) {
          formattedProduct.byID[product.id] = product;
          formattedProduct.allIDs.push(product.id);
        }

        dispatch({
          type: actionType.RECEIVE_PRODUCTS,
          product: formattedProduct,
          loadedCategoryID: categoryID,
        });
        dispatch(_notif.actions.setLoadState(lsItem, LOADING_STATE.LOADED));
      },
      processError(dispatch, _notif, lsItem),
    );
  }
);

export const setProductEditing = setItemEditing(actionType.SET_PRODUCT_EDITING);
export const editProductProp = editItemProp(actionType.EDIT_PRODUCT_PROP);
const updateProductLocal = updateItemLocal(actionType.UPDATE_PRODUCT, "product");
const deleteProductLocal = deleteItemLocal(actionType.DELETE_PRODUCT);

export const addProduct = (categoryID) => (
  (dispatch) => {
    let lsItem = LS_ITEM_ADMIN.PRODUCT_ADD;
    dispatch(_notif.actions.setLoadState(lsItem, LOADING_STATE.LOADING));
    postRequestAuthed("/AdminProduct/AddProduct",
      categoryID,
      (product) => {
        dispatch(updateProductLocal(product, true));
        dispatch(_notif.actions.queueNotification("Product added", NOTIF_TYPE.SUCCESS));
        dispatch(_notif.actions.setLoadState(lsItem, LOADING_STATE.LOADED));
      },
      processError(dispatch, _notif, lsItem),
    );
  }
);

export const saveProduct = () => (
  (dispatch, getState) => {
    const product = adminProductSelectors.getProductEditing(getState());

    let postData = prepPostData(product, ["previewImage", "previewVideo"]);

    //Separate the info section files
    let extraFiles = [];
    if (product.images) {
      let newImages = [];
      for (let item of product.images) {
        if (!item.image) {
          continue;
        }
        const processedItem = prepPostData(item, ["image"]);
        newImages.push(processedItem.item);
        if (processedItem.newFiles.length > 0) {
          processedItem.newFiles[0].propName = item.id.toString();
        }
        extraFiles = [
          ...extraFiles,
          ...processedItem.newFiles,
        ];
      }
      postData.item.images = newImages;
    }

    //Give an order prop to the InfoSections
    if (postData.item.infoSections) {
      postData.item.infoSections.forEach((item, index) => {
        item.order = index;
      });
    }

    let lsItem = LS_ITEM_ADMIN.PRODUCT_SAVE;
    dispatch(_notif.actions.setLoadState(lsItem, LOADING_STATE.LOADING));
    postRequestAuthed("/AdminProduct/SaveProduct",
      {
        item: postData.item,
        newFiles: postData.newFiles,
        extraFiles,
      },
      (product) => {
        dispatch(updateProductLocal(product));
        dispatch(setProductEditing(product.id));

        dispatch(_notif.actions.queueNotification("Product updated", NOTIF_TYPE.SUCCESS));
        dispatch(_notif.actions.setLoadState(lsItem, LOADING_STATE.LOADED));
      },
      processError(dispatch, _notif, lsItem),
    );
  }
);

export const deleteProduct = (id, onSuccess=null) => (
  (dispatch) => {
    let lsItem = `${LS_ITEM_ADMIN.PRODUCT_DELETE}${id}`;
    dispatch(_notif.actions.setLoadState(lsItem, LOADING_STATE.LOADING));
    postRequestAuthed("/AdminProduct/DeleteProduct",
      id,
      () => {
        dispatch(deleteProductLocal(id));

        dispatch(_notif.actions.queueNotification("Product deleted", NOTIF_TYPE.SUCCESS));
        dispatch(_notif.actions.setLoadState(lsItem, LOADING_STATE.LOADED));
        if (onSuccess) {
          onSuccess();
        }
      },
      processError(dispatch, _notif, lsItem),
    );
  }
);

export const reorderProducts = (startIndex, endIndex, categoryID) => (
  (dispatch, getState) => {
    //Get the currently sorted items
    const sortedIDs = adminProductSelectors.getProducts(getState()).map((item) => item.id);
    let newOrder = [...sortedIDs];
    //Move from the start index to the end index (and get the moved itemID in the process)
    const [itemID] = newOrder.splice(startIndex, 1);
    newOrder.splice(endIndex, 0, itemID);

    //Save the order locally
    dispatch({
      type: actionType.REORDER_PRODUCTS,
      newOrder,
    });

    const lsItem = `${LS_ITEM_ADMIN.PRODUCT_REORDER}${itemID}`;
    dispatch(_notif.actions.setLoadState(lsItem, LOADING_STATE.LOADING));
    postRequestAuthed("/AdminProduct/ReorderProducts",
      {
        startIndex,
        endIndex,
        parentID: categoryID,
      },
      () => {
        dispatch(_notif.actions.queueNotification("Re-ordered successfully", NOTIF_TYPE.SUCCESS));
        dispatch(_notif.actions.setLoadState(lsItem, LOADING_STATE.LOADED));
      },
      processError(dispatch, _notif, lsItem, () => {
        //Undo the local change if the server request fails
        dispatch({
          type: actionType.REORDER_PRODUCTS,
          newOrder: sortedIDs,
        });
      }),
    );
  }
);